import React from "react";
import { Show, Tab, TabbedShowLayout, TextField } from "react-admin";

export function ShowView(props) {
	const PostTitle = ({ record }) => {
		return <span>Просмотр {record ? `"${record.title_full}"` : ""}</span>;
	}

	return (
		<Show title={<PostTitle />} {...props}>
			<TabbedShowLayout>
				<Tab label="Основное" {...props}>
					<TextField
						source="title_full"
						label={"Наименование"}
					/>
					<TextField
						source={"slug"}
						label={"Слаг"}
						style={{ width: "100%" }}
					/>
				</Tab>
			</TabbedShowLayout>
		</Show>
	)
};
