import React from "react";
import Grid from "@material-ui/core/Grid";
import {
    TextInput,
    SelectInput,
    FormTab,
    ArrayInput,
    SimpleFormIterator,
    BooleanInput,
    FormDataConsumer,
} from "react-admin";
import { get } from "lodash";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import InputS3UploaderMulti from "../../../../Form/InputS3UploaderMulti";
import InputS3FileUploader from "../../../../Form/InputS3FileUploader";
import RichText from "../../../../Form/RichText";
import VkPostinput from "../../../../Form/VkPostInput";
import VkArticleinput from "../../../../Form/VkArticleInput";
import TwitterPostInput from "../../../../Form/TwitterPostInput";
import FaceBookInput from "../../../../Form/FaceBookInput";

import "./style.scss";
import InstagramInput from "../../../../Form/InstagramInput";

export function Content(props) {
    //TODO: display Content tab depended on role

    const { edit: { content } = { content: true }, edit } = get(
        props,
        "permissions",
        {}
    );
    const showContent = (typeof edit === "boolean" && edit) || content;

    return showContent ? (
        <FormTab label="Контент" {...props}>
            <div style={{ width: "100%" }}>
                <Grid container spacing={3}>
                    <Grid
                        item
                        xs={12}
                        style={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                        }}
                    >
                        {props.before}
                    </Grid>
                    <Grid item xs={12}>
                        <ArrayInput
                            source="content_blocks"
                            label="Блоки на странице"
                            style={{ width: "100%" }}
                        >
                            <SimpleFormIterator>
                                <FormDataConsumer>
                                    {({ scopedFormData, getSource }) => (
                                        <Accordion TransitionProps={{ unmountOnExit: true }}>
                                            <AccordionSummary
                                                id={"panel1a-header"}
                                                aria-controls={"panel1a-content"}
                                                expandIcon={<ExpandMoreIcon />}
                                            >
                                                {get(scopedFormData, "title", "") || (get(scopedFormData, "body", "") ?? "").slice(0, 150)}
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div style={{ width: "100%" }}>
                                                    <SelectInput
                                                        source={getSource("type")}
                                                        label={"Тип блока"}
                                                        defaultValue={"default"}
                                                        style={{ width: "48%", float: "right" }}
                                                        choices={[
                                                            {
                                                                id: "default",
                                                                name: "По умолчанию",
                                                            },
                                                            {
                                                                id: "Landscape",
                                                                name: "Альбомная",
                                                            },
                                                            {
                                                                id: "Portrait",
                                                                name: "Книжная",
                                                            },
                                                            {
                                                                id: "Square",
                                                                name: "Квадратная",
                                                            },
                                                        ]}
                                                    />
                                                    <SelectInput
                                                        source={getSource("media.type")}
                                                        label={"Тип контента"}
                                                        defaultValue={"default"}
                                                        style={{ width: "48%" }}
                                                        choices={[
                                                            {
                                                                id: "default",
                                                                name: "По умолчанию",
                                                            },
                                                            {
                                                                id: "SlideNews",
                                                                name: "Новость",
                                                            },
                                                            {
                                                                id: "Gallery",
                                                                name: "Галерея",
                                                            },
                                                            {
                                                                id: "SlideLink",
                                                                name: "Ссылка на ресурс",
                                                            },
                                                            {
                                                                id: "Video",
                                                                name: "Видео",
                                                            },
                                                            {
                                                                id: "WideImage",
                                                                name: "Изображение на всю ширину",
                                                            },
                                                            {
                                                                id: "ImportantToKnow",
                                                                name: "Важно знать",
                                                            },
                                                            {
                                                                id: "VkPost",
                                                                name: "Вставка поста из ВК",
                                                            },
                                                            {
                                                                id: "VkArticle",
                                                                name: "Вставка статьи из ВК",
                                                            },
                                                            {
                                                                id: "InstaPost",
                                                                name: "Вставка поста из Instagram",
                                                            },
                                                            {
                                                                id: "FbPost",
                                                                name: "Вставка поста из Facebook",
                                                            },
                                                            {
                                                                id: "TwitterPost",
                                                                name: "Вставка поста из Twitter",
                                                            },
                                                        ]}
                                                    />
                                                    <TextInput
                                                        source={getSource("title")}
                                                        label="Заголовок блока"
                                                        style={{ width: "100%" }}
                                                    />
                                                    <RichText
                                                        source={getSource("body")}
                                                        label={"Содержимое блока"}
                                                        style={{ width: "100%" }}
                                                    />
                                                    <BooleanInput
                                                        label="No index"
                                                        source={getSource("no_index")}
                                                        style={{ width: "48%" }}
                                                    />
                                                    <BooleanInput
                                                        label="Добавить галерею в медиатеку"
                                                        source={getSource("photoalbum")}
                                                        style={{ width: "48%" }}
                                                    />
                                                    <TextInput
                                                        source={getSource("media.video_src")}
                                                        label="Cсылка на видео"
                                                        style={{ width: "48%" }}
                                                    />
                                                    <SourceLink getSource={getSource} scopedFormData={scopedFormData} />
                                                    <InputS3UploaderMulti
                                                        source={getSource("media.items")}
                                                        style={{ width: "100%" }}
                                                    />
                                                    <ArrayInput
                                                        source={getSource("media.files")}
                                                        label="Прикрепить файл"
                                                    >
                                                        <SimpleFormIterator>
                                                            <InputS3FileUploader
                                                                style={{ width: "100%" }}
                                                            />{" "}
                                                            <TextInput
                                                                source={getSource("title")}
                                                                label={"Подпись к файлу"}
                                                            />
                                                        </SimpleFormIterator>
                                                    </ArrayInput>
                                                    <SelectInput
                                                        source={getSource("style.padding.top")}
                                                        label={"Отступ сверху"}
                                                        defaultValue={"default"}
                                                        style={{ width: "48%" }}
                                                        choices={[
                                                            {
                                                                id: "default",
                                                                name: "",
                                                            },
                                                            {
                                                                id: "0",
                                                                name: "0",
                                                            },
                                                            {
                                                                id: "1",
                                                                name: "1",
                                                            },
                                                            {
                                                                id: "2",
                                                                name: "2",
                                                            },
                                                            {
                                                                id: "3",
                                                                name: "3",
                                                            },
                                                        ]}
                                                    />
                                                    <SelectInput
                                                        source={getSource("style.padding.bottom")}
                                                        label={"Отступ снизу"}
                                                        defaultValue={"default"}
                                                        style={{ width: "48%", float: "right" }}
                                                        choices={[
                                                            {
                                                                id: "default",
                                                                name: "",
                                                            },
                                                            {
                                                                id: "0",
                                                                name: "0",
                                                            },
                                                            {
                                                                id: "1",
                                                                name: "1",
                                                            },
                                                            {
                                                                id: "2",
                                                                name: "2",
                                                            },
                                                            {
                                                                id: "3",
                                                                name: "3",
                                                            },
                                                        ]}
                                                    />
                                                    <Buttons getSource={getSource} scopedFormData={scopedFormData} />
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    )}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Grid>
                    <Grid item xs={12}>
                        {props.children}
                    </Grid>
                </Grid>
            </div>
        </FormTab>
    ) : null;
}

function SourceLink({ scopedFormData, getSource }) {
    return (
        <>
            {
                scopedFormData && scopedFormData.media ? (
                    <>
                        {scopedFormData.media.type === "InstaPost" &&
                            <InstagramInput
                                source={getSource("media.insta.src")}
                                label="Ссылка на пост из Instagram"
                                style={{ width: "48%" }}
                            />
                        }
                        {scopedFormData.media.type === "FbPost" &&
                            <FaceBookInput
                                source={getSource("media.fb.src")}
                                label="Ссылка на пост из Facebook"
                                style={{ width: "48%" }}
                            />
                        }
                        {scopedFormData.media.type === "TwitterPost" &&
                            <TwitterPostInput
                                source={getSource("media.twitter.src")}
                                label="Ссылка на пост из Twitter"
                                style={{ width: "48%" }}
                            />
                        }
                        {scopedFormData.media.type === "VkPost" &&
                            <VkPostinput
                                source={getSource("media.vk.post")}
                                label="Код для вставки поста из ВК"
                                style={{ width: "48%" }}
                            />
                        }
                        {scopedFormData.media.type === "VkArticle" &&
                            <VkArticleinput
                                source={getSource("media.vk.article")}
                                label="Код для вставки статьи из ВК"
                                style={{ width: "48%" }}
                            />
                        }
                    </>
                ) : null
            }
        </>
    )
}

function Buttons({ scopedFormData, getSource }) {
    const disableAdd = get(scopedFormData, "buttons.length", 0) > 2;

    return (
        <ArrayInput
            source={getSource("buttons")}
            label="Кнопки"
            record={scopedFormData}
        >
            <SimpleFormIterator disableAdd={disableAdd}>
                <TextInput
                    source={getSource("title")}
                    label={"Надпись на кнопке"}
                    style={{ width: "48%" }}
                />
                <TextInput
                    source={"link"}
                    label={"Ссылка"}
                    style={{
                        width: "48%",
                        float: "right",
                    }}
                />
            </SimpleFormIterator>
        </ArrayInput>
    )
}