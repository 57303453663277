import React from "react";
import { Tab } from "react-admin";

import FieldMap from "../../../../Show/FieldMap";

export function Location(props) {
    return (
        <Tab label={"Расположение"} {...props}>
            <FieldMap source={"location_object"} label={"Контур"} />
            {props.children}
        </Tab>
    );
}
