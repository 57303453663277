import React, { Component } from "react";
import ReactMde, { getDefaultCommandMap, getDefaultToolbarCommands } from "react-mde";
import { addField } from "ra-core";
import * as Showdown from "showdown";
import { get } from "lodash";

import "react-mde/lib/styles/css/react-mde-all.css";

const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true
});

const highlightCommand = {
    name: "md-highlight",
    icon: () => (
        <span role="img" aria-label="nice">
            highlight
        </span>
    ),
    execute: opts => {
        const textApi = get(opts, "textApi", {
            getState() {
                return {};
            },
            replaceSelection() {
                return {};
            }
        })

        textApi.getState().selectedText &&
        textApi.replaceSelection(`fhighlight>${textApi.getState().selectedText}<bhighlight`);
    }
};
const noindexCommand = {
    name: "noindex",
    icon: () => (
        <span role="img" aria-label="nice">
            noindex
        </span>
    ),
    execute: opts => {
        const textApi = get(opts, "textApi", {
            getState() {
                return {};
            },
            replaceSelection() {
                return {};
            }
        })

        textApi.getState().selectedText &&
        textApi.replaceSelection(`fnoindex>${textApi.getState().selectedText}<bnoindex`);
    }
};

class RichText extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: "",
            tab: "write"
        };

        this.handleToggleTab = this.handleToggleTab.bind(this);
    }

    handleToggleTab() {
        this.setState({
            tab: /write/.test(this.state.tab) ? "preview" : "write"
        });
    }

    render() {
        const {
            input: { onChange, value }
        } = this.props; // onBlur, , onDragStart, onDrop, onFocus

        const commands = [["md-highlight", "noindex"]]

        getDefaultToolbarCommands().map(item => commands.push(item))

        return (
            <>
                <span
                    style={{
                        marginBottom: 10,
                        display: "block"
                    }}
                >
                    {get(this.props, "label", "")}
                </span>
                <ReactMde
                    commands={Object.assign(
                        getDefaultCommandMap(),
                        {"md-highlight": highlightCommand },
                        { "noindex": noindexCommand },
                    )}
                    toolbarCommands={commands}
                    value={value}
                    onChange={onChange}
                    selectedTab={this.state.tab}
                    onTabChange={this.handleToggleTab}
                    generateMarkdownPreview={markdown =>
                        Promise.resolve(converter.makeHtml(markdown))
                    }
                />
            </>
        );
    }
}

export default addField(RichText);
