import React from "react";
import {
    TabbedForm,
    Create,
    TextInput,
    FormTab,
    SimpleFormIterator,
    ArrayInput,
} from "react-admin";
import Grid from "@material-ui/core/Grid";

export function CreateView(props) {
    return (
        <Create {...props}>
            <TabbedForm>
                <FormTab label={"Основное"} {...props}>
                    <div style={{ width: "100%" }}>
                        <Grid container>
                            <Grid
                                item
                                xs={8}
                                style={{
                                    paddingRight: 24,
                                    borderRight:
                                        "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                            >
                                <ArrayInput
                                    source="items"
                                    label="Православные имена"
                                >
                                    <SimpleFormIterator>
                                        <TextInput
                                            source={"name"}
                                            label={"Имя"}
                                        />
                                        <TextInput
                                            source={"replace"}
                                            label={"Замена"}
                                        />
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Grid>
                        </Grid>
                    </div>
                </FormTab>
            </TabbedForm>
        </Create>
    );
}
