import React from "react";
import {
    TabbedForm,
    Edit,
    TextInput,
    NumberInput,
    required,
    FormTab,
    Datagrid,
    DateField,
    EditButton,
    DeleteButton,
    TextField,
    Button,
    usePermissions,
    ReferenceManyField,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import { Add } from "@material-ui/icons";
import { Link } from "react-router-dom";

import { SEO, Content, Location } from "../../../Layout/Tabs/Edit";
import InputS3Uploader from "../../../Form/InputS3Uploader";
import withPermissions from "../../../../hoc/CheckPermissions";
import { tabListByRole, transformDataByRole } from "../../../../utils";
import { BottomToolbar } from "../../../Layout/Actions";
import RichText from "../../../Form/RichText";

import "./Edit.scss";

function UnmappedEditView(props) {
    const { loaded, permissions: role } = usePermissions();

    const PostTitle = ({ record }) => {
        return (
            <span>Редактируется {record ? `"${record.title_full}"` : ""}</span>
        );
    };

    const AddButton = ({ redirect_to, record, table, label }) => (
        <Button
            variant="raised"
            component={Link}
            to={{
                pathname: `/${table}/create`,
                state: { record: { broadcast_id: record.id }, redirect_to },
            }}
            label={label}
            title={label}
        >
            <Add />
        </Button>
    );
    const AddReferenceButton = AddButton;

    const record_id = props.id;

    const main = (
        <FormTab label={"Основное"} {...props}>
            <div style={{ width: "100%" }}>
                <Grid container>
                    <Grid
                        item
                        xs={8}
                        style={{
                            paddingRight: 24,
                            borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                        }}
                    >
                        <TextInput
                            source={"title_full"}
                            label={"Наименование"}
                            style={{ width: "100%" }}
                            validate={[required()]}
                            resettable
                        />
                        <TextInput
                            source={"title_short"}
                            label={"Краткое наименование"}
                            style={{ width: "100%" }}
                            resettable
                        />
                        <TextInput
                            source={"page_title_full"}
                            label={"Наименование страницы"}
                            style={{ width: "100%" }}
                            validate={[required()]}
                            resettable
                        />
                        <TextInput
                            source={"page_title_short"}
                            label={"Краткое наименование страницы"}
                            style={{ width: "100%" }}
                            resettable
                        />
                        <TextInput
                            source={"src"}
                            label={"Источник"}
                            style={{ width: "100%" }}
                            validate={[required()]}
                            resettable
                        />
                        <TextInput
                            source={"settings.mainPageDescription"}
                            label={"Заголовок для главной"}
                            style={{ width: "100%" }}
                            resettable
                        />
                        <RichText
                            source={"settings.srcDescription"}
                            label={"Описание источника"}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        style={{
                            paddingLeft: 24,
                            marginBottom: 24,
                        }}
                    >
                        <TextInput
                            source={"slug"}
                            label={"Слаг"}
                            style={{ width: "100%" }}
                            validate={[required()]}
                            resettable
                        />
                        <NumberInput
                            source={"sort_order"}
                            label={"Сортировка"}
                            style={{ width: "100%" }}
                            resettable
                        />
                        <InputS3Uploader
                            source={"main_image"}
                            label={"Главное изображение"}
                            validate={[required()]}
                        />
                        <InputS3Uploader
                            source={"main_image_mobile"}
                            label={"Главное изображение для мобильной версии"}
                        />
                        <InputS3Uploader
                            source={"main_image_preview"}
                            label={"Превью главного изображения"}
                        />
                        <InputS3Uploader
                            source={"preview"}
                            label={"Превью изображение"}
                        />
                        <InputS3Uploader
                            source={"preview_mobile"}
                            label={"Превью изображение для мобильной версии"}
                        />
                    </Grid>
                </Grid>
            </div>
        </FormTab>
    );
    const location = <Location />;
    const seo = <SEO />;
    const content = <Content />;

    const schedules = (
        <FormTab label="Расписание трансляций">
            <ReferenceManyField
                reference="broadcast_schedules"
                target="broadcast_id"
                label="Трансляции"
            >
                <Datagrid>
                    <TextField source="id" />
                    <DateField
                        source="begins_at"
                        showTime
                        label={"Дата и время начала"}
                    />
                    <TextField
                        source="data.title"
                        label="Заголовок службы"
                    />
                    <TextField
                        source="data.holiday"
                        label="Название праздника"
                    />
                    <EditButton basePath={"/broadcast_schedules"} />
                    <DeleteButton basePath={`/broadcasts/${record_id}/4`} resource={"broadcast_schedules"} />
                </Datagrid>
            </ReferenceManyField>
            <AddReferenceButton
                redirect_to={props.location.pathname}
                table={"broadcast_schedules"}
                label={"Добавить службу"}
            />
        </FormTab>
    );

    return (
        loaded && (
            <Edit
                title={<PostTitle />}
                transform={(data) => transformDataByRole(role, data)}
                {...props}
            >
                <TabbedForm toolbar={<BottomToolbar role={role} {...props} />}>
                    {tabListByRole({
                        seo,
                        admin: [main, location, seo, content, schedules],
                    }).get(role)}
                </TabbedForm>
            </Edit>
        )
    );
}

const EditView = withPermissions(UnmappedEditView, "broadcasts");

export { EditView };
