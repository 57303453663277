import React from "react";
import { Show, TabbedShowLayout, Tab, TextField, ImageField } from "react-admin";

import { SEO, Content } from "../../../Layout/Tabs/Show";

export function ShowView(props) {
	const PostTitle = ({ record }) => {
		return <span>Просмотр {record ? `"${record.title_full}"` : ""}</span>;
	}

	return (
		<Show title={<PostTitle />} {...props}>
			<TabbedShowLayout>
				<Tab label="Основное" {...props}>
					<TextField
						source="title_full"
						label={"Наименование"}
					/>
					<TextField
						source="description"
						label={"Краткое описание"}
					/>
					<TextField
						source="source"
						label={"Источник"}
					/>
					<TextField
						source={"slug"}
						label={"Слаг"}
						style={{ width: "100%" }}
					/>
					<TextField
						source={"sort_order"}
						label={"Сортировка"}
						style={{ width: "100%" }}
					/>
					<ImageField source="main_image.src" label="Главное изображение" />
					{props.children}
				</Tab>
				<SEO />
				<Content />
			</TabbedShowLayout>
		</Show>
	)
};
