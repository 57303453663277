import React from "react";
import {
    TabbedForm,
    Edit,
    FormTab,
    TextInput,
    required,
    ArrayInput,
    SimpleFormIterator,
    SelectInput,
    CheckboxGroupInput,
    BooleanInput,
    NumberInput,
    FormDataConsumer,
    usePermissions,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
//TODO: need to fix: ColorInput styles replaces material default styles
// https://github.com/vascofg/react-admin-color-input/pull/5
// import { ColorInput } from "react-admin-color-input";

import { SEO } from "../../../Layout/Tabs/Edit";
import InputS3Uploader from "../../../Form/InputS3Uploader";
import withPermissions from "../../../../hoc/CheckPermissions";
import { tabListByRole, transformDataByRole } from "../../../../utils";
import { BottomToolbar } from "../../../Layout/Actions";

function UnmappedEditView(props) {
    const { loaded, permissions: role } = usePermissions();

    const PostTitle = ({ record }) => {
        return (
            <span>
                Редактируется {record ? `"${record.page_title_full}"` : ""}
            </span>
        );
    };

    const main = (
        <FormTab label={"Основное"} {...props}>
            <div style={{ width: "100%" }}>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        style={{
                            paddingRight: 24,
                            borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                        }}
                    >
                        <TextInput
                            source={"page_title_full"}
                            label={"Наименование страницы"}
                            style={{
                                width: "100%",
                            }}
                            validate={[required()]}
                            resettable
                        />
                        <BooleanInput
                            label={"Включить автовоспроизведение баннера"}
                            source={"settings.banners.enabled"}
                        />
                        <TextInput
                            source={"settings.banners.arrowColor"}
                            label={"Цвет стрелок"}
                            style={{
                                display: "block",
                            }}
                        />
                        <TextInput
                            source={"settings.banners.interval"}
                            label={"Интервал"}
                        />
                        <TextInput
                            source={"settings.socials.facebookApId"}
                            label={"facebookApId"}
                            style={{
                                display: "block",
                            }}
                        />
                        <TextInput
                            source={"settings.socials.instagramAppId"}
                            label={"instagramAppId"}
                            style={{
                                display: "block",
                            }}
                        />
                    </Grid>
                </Grid>
            </div>
        </FormTab>
    );

    const seo = <SEO />;
    const banners = (
        <FormTab xs={12} label={"Баннеры"} {...props}>
            <div style={{ width: "100%" }}>
                <Grid container>
                    <Grid
                        item
                        style={{
                            paddingLeft: 24,
                            marginBottom: 24,
                        }}
                    >
                        <ArrayInput
                            source="banner"
                            label="Баннеры"
                            style={{ width: "100%" }}
                        >
                            <SimpleFormIterator>
                                <SelectInput
                                    source={"type"}
                                    label={"Тип баннера"}
                                    defaultValue={"BannerImg"}
                                    style={{ width: "100%" }}
                                    choices={[
                                        {
                                            id: "BannerImg",
                                            name: "По умолчанию",
                                        },
                                        {
                                            id: "BannerText",
                                            name: "Текстовый баннер",
                                        },
                                        {
                                            id: "CombinedBanner",
                                            name: "Составной баннер",
                                        },
                                    ]}
                                />
                                <CheckboxGroupInput
                                    source={"targeting_keys"}
                                    label={"Типы таргетингов"}
                                    choices={[
                                        {
                                            id: "default",
                                            name: "По умолчанию",
                                        },
                                        {
                                            id: "tourist",
                                            name: "Турист",
                                        },
                                        {
                                            id: "palomnik",
                                            name: "Паломник",
                                        },
                                        {
                                            id: "trudnik",
                                            name: "Трудник",
                                        },
                                    ]}
                                />
                                <TextInput
                                    source="slug"
                                    label="Слаг"
                                    style={{ width: "100%" }}
                                />
                                <TextInput
                                    source="text"
                                    label="Текст баннера"
                                    style={{ width: "100%" }}
                                    multiline
                                />
                                <InputS3Uploader
                                    source={"image"}
                                    label={"Баннер"}
                                />
                                <InputS3Uploader
                                    source={"mobile_image"}
                                    label={"Баннер для мобильной версии"}
                                />
                                <ArrayInput
                                    source="items"
                                    label="Элемент баннера"
                                >
                                    <SimpleFormIterator>
                                        <InputS3Uploader />
                                        <TextInput
                                            source={"item-slug"}
                                            label={"Слаг"}
                                        />
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Grid>
                </Grid>
            </div>
        </FormTab>
    );
    const media = (
        <FormTab xs={12} label={"Медиатека"} {...props}>
            <div style={{ width: "100%" }}>
                <Grid container>
                    <Grid
                        item
                        style={{
                            paddingLeft: 24,
                            marginBottom: 24,
                        }}
                    >
                        <ArrayInput
                            source="settings.mediaPageBlocksSettings"
                            label="Блоки на странице медиатеки"
                            style={{ width: "100%" }}
                        >
                            <SimpleFormIterator
                                disableRemove
                                style={{ width: "100%" }}
                            >
                                <FormDataConsumer>
                                    {({ getSource, scopedFormData }) => {
                                        return (
                                            <div
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <TextInput
                                                    record={scopedFormData}
                                                    source={getSource("name")}
                                                    label="Название блока"
                                                    style={{
                                                        width: "40%",
                                                    }}
                                                />
                                                <TextInput
                                                    record={scopedFormData}
                                                    source={getSource("slug")}
                                                    label="Слаг"
                                                    style={{
                                                        display: "none",
                                                    }}
                                                />
                                                <NumberInput
                                                    record={scopedFormData}
                                                    source={getSource(
                                                        "sort_order"
                                                    )}
                                                    label={"Сортировка"}
                                                    style={{
                                                        width: "30%",
                                                    }}
                                                    validate={[required()]}
                                                    resettable
                                                />
                                                <BooleanInput
                                                    record={scopedFormData}
                                                    label="Отображать"
                                                    source={getSource(
                                                        "enabled"
                                                    )}
                                                    style={{
                                                        width: "30%",
                                                    }}
                                                />
                                            </div>
                                        );
                                    }}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Grid>
                </Grid>
            </div>
        </FormTab>
    );

    return (
        loaded && (
            <Edit
                title={<PostTitle />}
                transform={(data) => transformDataByRole(role, data)}
                {...props}
            >
                <TabbedForm toolbar={<BottomToolbar role={role} {...props} />}>
                    {tabListByRole({
                        seo,
                        admin: [main, seo, banners, media],
                    }).get(role)}
                </TabbedForm>
            </Edit>
        )
    );
}

const EditView = withPermissions(UnmappedEditView, "main_page");

export { EditView };
