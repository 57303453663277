import React from "react";
import {
    TextField,
    ReferenceField,
    Create,
    ReferenceInput,
    SelectInput,
    TabbedShowLayout,
    Tab,
    Show,
    Edit,
    SimpleForm,
    required,
} from "react-admin";

import { parse } from "query-string";
import { get } from "lodash";

import AutocompleteField from "../../../Form/Autocomplete";

export const CreateView = (props) => {
    const { media_article_id } = parse(props.location.search);
    const redirect = get(props, "location.state.redirect_to", "") || false;

    return (
        <Create {...props}>
            <SimpleForm initialValues={{ media_article_id }} redirect={redirect}>
                <ReferenceInput
                    label={"Текстовая страница"}
                    source="text_page_id"
                    reference="text_pages"
                    validate={required()}
                    perPage={10000}
                >
                    <AutocompleteField title="Текстовая страница" />
                </ReferenceInput>
                <ReferenceInput
                    source="media_article_id"
                    reference="media_articles"
                    validate={required()}
                    style={{ display: "none" }}
                    perPage={10000}
                >
                    <SelectInput optionText="id" />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};

export const EditView = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <ReferenceInput
                label={"Текстовая страница"}
                source="text_page_id"
                reference="text_pages"
                validate={required()}
                perPage={10000}
            >
                <AutocompleteField title="Текстовая страница" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

export const ShowView = (props) => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label={"Основное"} {...props}>
                <ReferenceField
                    label="Текстовая страница"
                    source="text_page_id"
                    reference="text_pages"
                    perPage={10000}
                >
                    <TextField source="title_full" />
                </ReferenceField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
