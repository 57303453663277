import React from "react";
import {
    TabbedForm,
    TextInput,
    SelectInput,
    BooleanInput,
    Create,
    FormTab,
    ArrayInput,
    SimpleFormIterator,
    required, ReferenceInput,
} from "react-admin";
import Grid from "@material-ui/core/Grid";

import { SEO, Main } from "../../../Layout/Tabs/Edit";

import InputS3UploaderMulti from "../../../Form/InputS3UploaderMulti";
import InputS3FileUploader from "../../../Form/InputS3FileUploader";
import RichText from "../../../Form/RichText";
import InputS3Uploader from "../../../Form/InputS3Uploader";

export function CreateView(props) {
    return (
        <Create {...props}>
            <TabbedForm>
                <Main>
                    <TextInput
                        source="type"
                        defaultValue={"audio_guides"}
                        label="Тип аудио"
                        style={{ display: "none" }}
                    />
                    <TextInput
                        multiline
                        source={"teaser"}
                        label={"Тизер"}
                        style={{ width: "100%" }}
                        resettable
                    />
                    <ReferenceInput
                        source={"audio_category_id"}
                        reference={"audio_categories"}
                        label={"Категория"}
                        style={{
                            width: "100%",
                        }}
                    >
                        <SelectInput
                            optionText="title"
                            emptyValue={null}
                            validate={[required()]}
                        />
                    </ReferenceInput>
                    <InputS3Uploader
                        source={"audio"}
                        label={"Аудио"}
                        validate={[required()]}
                        file
                    />
                </Main>
                <SEO />
                <FormTab label="Контент" {...props}>
                    <div style={{ width: "100%" }}>
                        <Grid container spacing={3}>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    borderBottom:
                                        "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                            >
                                {props.before}
                            </Grid>
                            <Grid item xs={12}>
                                <ArrayInput
                                    source="content_blocks"
                                    label="Блоки на странице"
                                    style={{ width: "100%" }}
                                >
                                    <SimpleFormIterator>
                                        <SelectInput
                                            source={"type"}
                                            label={"Тип блока"}
                                            defaultValue={"default"}
                                            style={{ width: "100%" }}
                                            choices={[
                                                {
                                                    id: "default",
                                                    name: "По умолчанию",
                                                },
                                                {
                                                    id: "Landscape",
                                                    name: "Альбомная",
                                                },
                                                {
                                                    id: "Portrait",
                                                    name: "Книжная",
                                                },
                                                {
                                                    id: "Square",
                                                    name: "Квадратная",
                                                },
                                            ]}
                                        />
                                        <TextInput
                                            source="title"
                                            label="Заголовок блока"
                                            style={{ width: "100%" }}
                                        />
                                        <RichText
                                            source={"body"}
                                            label={"Содержимое блока"}
                                            style={{ width: "100%" }}
                                        />
                                        <BooleanInput
                                            label="No index"
                                            source="no_index"
                                        />
                                        <SelectInput
                                            source={"media.type"}
                                            label={"Тип контента"}
                                            defaultValue={"default"}
                                            choices={[
                                                {
                                                    id: "default",
                                                    name: "По умолчанию",
                                                },
                                                {
                                                    id: "SlideNews",
                                                    name: "Новость",
                                                },
                                                {
                                                    id: "Gallery",
                                                    name: "Галерея",
                                                },
                                                {
                                                    id: "SlideLink",
                                                    name: "Ссылка на ресурс",
                                                },
                                                {
                                                    id: "Video",
                                                    name: "Видео",
                                                },
                                                {
                                                    id: "WideImage",
                                                    name:
                                                        "Изображение на всю ширину",
                                                },
                                                {
                                                    id: "ImportantToKnow",
                                                    name: "Важно знать",
                                                },
                                            ]}
                                        />
                                        <InputS3UploaderMulti
                                            source="media.items"
                                            style={{ width: "100%" }}
                                        />
                                        <ArrayInput
                                            source="media.files"
                                            label="Прикрепить аудиогид"
                                        >
                                            <SimpleFormIterator>
                                                <InputS3FileUploader
                                                    style={{ width: "100%" }}
                                                />{" "}
                                                <TextInput
                                                    source={"title"}
                                                    label={"Подпись к файлу"}
                                                />
                                            </SimpleFormIterator>
                                        </ArrayInput>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Grid>
                            <Grid item xs={12}>
                                {props.children}
                            </Grid>
                        </Grid>
                    </div>
                </FormTab>
            </TabbedForm>
        </Create>
    );
}
