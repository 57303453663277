import React from "react";

import List from "../../../Layout/List";
import CheckPermissions from "../../../../hoc/CheckPermissions";

function UnmappedListView(props) {
    return <List showSortOrder={false} {...props} />;
}

const ListView = CheckPermissions(UnmappedListView, "city");

export { ListView };
