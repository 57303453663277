import React from "react";
import { TextField, Tab } from "react-admin";

export function SEO(props) {
	return (
		<Tab label="SEO" {...props}>
			<TextField source='seo.html.title' label='html_title'/>
			<TextField source='seo.html.description' label='html_description'/>
			<TextField source='seo.html.keywords' label='html_keywords'/>
			<TextField source='seo.og.title' label='og_title'/>
			<TextField source='seo.og.site_name' label='og_site_name'/>
			<TextField source='seo.og.description' label='og_description'/>
			<TextField source='seo.og.image' label='og_image'/>
		</Tab>
	);
}
