import React from "react";
import { TextField, ReferenceField } from "react-admin";
import {
    Create,
    ReferenceInput,
    SelectInput,
    TabbedShowLayout,
    Tab,
} from "react-admin";
import { Show, Edit } from "react-admin";

import { SimpleForm, required } from "react-admin";

import { parse } from "query-string";
import { get } from "lodash";

import AutocompleteField from "../../../../Form/Autocomplete";

export const CreateView = (props) => {
    const { tour_id } = parse(props.location.search);
    const redirect = get(props, "location.state.redirect_to", "") || false;

    return (
        <Create {...props}>
            <SimpleForm initialValues={{ tour_id }} redirect={redirect}>
                <ReferenceInput
                    label={"Город"}
                    source="city_id"
                    reference="v_portal_admin_cities"
                    validate={required()}
                >
                    <AutocompleteField title="Город" />
                </ReferenceInput>
                <ReferenceInput
                    source="tour_id"
                    reference="tours"
                    validate={required()}
                    style={{ display: "none" }}
                >
                    <SelectInput optionText="id" />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};

export const EditView = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <ReferenceInput
                label={"Город"}
                source="city_id"
                reference="v_portal_admin_cities"
                validate={required()}
            >
                <AutocompleteField title="Город" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

export const ShowView = (props) => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label={"Основное"} {...props}>
                <ReferenceField
                    label="Город"
                    source="city_id"
                    reference="v_portal_admin_cities"
                >
                    <TextField source="title_full" />
                </ReferenceField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
