import React, { useState, useEffect } from "react";
import {
    Show,
    TabbedShowLayout,
    DateField,
    Button,
    ReferenceManyField,
    SingleFieldList,
    ReferenceField,
    ChipField,
    BooleanField,
    useDataProvider,
} from "react-admin";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";

import {
    SEO,
    Main,
    Location,
    Draft,
    Connections,
} from "../../../Layout/Tabs/Show";
import withPermissions from "../../../../hoc/CheckPermissions";
import PostShowActions from "../../../Form/Preview";

import "./Show.scss";

function UnmappedShowView(props) {
    const { permissions } = props;

    const PostTitle = ({ record }) => {
        return (
            <span>Просмотр {record ? `"${record.page_title_full}"` : ""}</span>
        );
    };

    const Rating = ({ source, record }) => {
        const dataProvider = useDataProvider();

        const [average, setAverage] = useState(null);
        const [count, setCount] = useState(null);

        useEffect(() => {
            dataProvider
                .getManyReference("news_ratings", {
                    target: "news_id",
                    id: record.id,
                    pagination: { perPage: Infinity },
                    sort: { field: "id", order: "desc" },
                })
                .then(({ data }) => {
                    setAverage(
                        data.length
                            ? data
                                  .map((item) => item.rating)
                                  .reduce((a, b) => a + b, 0) /
                                  (data.length || 1)
                            : null
                    );
                    setCount(data.length);
                })
                .catch((ratingError) =>
                    console.log("Rating load error", { ratingError })
                );
        }, [dataProvider, record.id]);

        return (
            average && (
                <div className={"MuiFormLabel-root-309"}>
                    <span>Рейтинг новости</span>

                    <div className={"RaLabeled-value-300"}>{average}</div>

                    <span>Количество проголосовавших</span>
                    <div className={"RaLabeled-value-300"}>{count}</div>
                </div>
            )
        );
    };

    const AddButton = ({ redirect_to, record, table, label }) => (
        <Button
            variant="raised"
            component={Link}
            to={{
                pathname: `/${table}/create`,
                state: { record: { news_id: record.id }, redirect_to },
            }}
            label={label}
            title={label}
        >
            <AddIcon />
        </Button>
    );
    const AddReferenceButton = AddButton;

    return (
        <Show title={<PostTitle />} actions={<PostShowActions />} {...props}>
            <TabbedShowLayout>
                <Main permissions={permissions}>
                    <DateField label={"Дата новости"} source="issued_at" />
                    <DateField
                        label={"Показывать после"}
                        source="published_at"
                    />
                    <BooleanField
                        label="Публикация в vk"
                        source="data.social_posting.vk"
                    />
                    <BooleanField
                        label="Публикация в facebook"
                        source="data.social_posting.fb"
                    />
                    <Rating />
                </Main>
                <Connections>
                    <ReferenceManyField
                        label="Таргетинг"
                        reference="news_targetings"
                        target="news_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="targeting_id"
                                reference="targetings"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"news_targetings"}
                        label={"Прикрепить к таргету"}
                    />

                    <ReferenceManyField
                        label="Города"
                        reference="cities_news"
                        target="news_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="city_id"
                                reference="v_portal_admin_cities"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"cities_news"}
                        label={"Прикрепить к городу"}
                    />

                    <ReferenceManyField
                        label="Рубрика"
                        reference="categories_news"
                        target="news_id"
                        sort={{ field: "news_id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="category_id"
                                reference="categories"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"categories_news"}
                        label={"Рубрики"}
                    />

                    <ReferenceManyField
                        label="Новости связанные вперед"
                        reference="linked_news"
                        target="news_id"
                        sort={{ field: "news_id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="linked_news_id"
                                reference="news"
                                link={false}
                            >
                                <ChipField source="page_title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"linked_news"}
                        label={"Прикрепить новости"}
                    />

                    <ReferenceManyField
                        label="Новости связанные назад"
                        reference="linked_news"
                        target="linked_news_id"
                        sort={{ field: "news_id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="news_id"
                                reference="news"
                                link={false}
                            >
                                <ChipField source="page_title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"linked_news"}
                        label={"Прикрепить новости"}
                    />

                    <ReferenceManyField
                        label="Монастыри"
                        reference="monasteries_news"
                        target="news_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="monastery_id"
                                reference="monasteries"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"monasteries_news"}
                        label={"Прикрепить к монастырю"}
                    />

                    <ReferenceManyField
                        label="Церкви"
                        reference="churches_news"
                        target="news_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="church_id"
                                reference="churches"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"churches_news"}
                        label={"Прикрепить к церкви"}
                    />

                    <ReferenceManyField
                        label="Соборы"
                        reference="cathedrals_news"
                        target="news_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="cathedral_id"
                                reference="cathedrals"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"cathedrals_news"}
                        label={"Прикрепить к собору"}
                    />

                    <ReferenceManyField
                        label="Храмы"
                        reference="news_temples"
                        target="news_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="temple_id"
                                reference="temples"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"news_temples"}
                        label={"Прикрепить к храму"}
                    />

                    <ReferenceManyField
                        label="Колокольни"
                        reference="belltowers_news"
                        target="news_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="belltower_id"
                                reference="belltowers"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"belltowers_news"}
                        label={"Прикрепить к колокольне"}
                    />

                    <ReferenceManyField
                        label="Часовни"
                        reference="chapels_news"
                        target="news_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="chapel_id"
                                reference="chapels"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>

                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"chapels_news"}
                        label={"Прикрепить к  часовне"}
                    />
                    <ReferenceManyField
                        label="Скиты"
                        reference="hermitages_news"
                        target="news_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="hermitage_id"
                                reference="hermitages"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"hermitages_news"}
                        label={"Прикрепить к скиту"}
                    />

                    <ReferenceManyField
                        label="Источники"
                        reference="news_wells"
                        target="news_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="well_id"
                                reference="wells"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"news_wells"}
                        label={"Прикрепить к источнику"}
                    />

                    <ReferenceManyField
                        label="Музеи"
                        reference="museums_news"
                        target="news_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="museum_id"
                                reference="museums"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"museums_news"}
                        label={"Прикрепить к музею"}
                    />

                    <ReferenceManyField
                        label="Городские объекты"
                        reference="city_objects_news"
                        target="news_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="city_object_id"
                                reference="city_objects"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"city_objects_news"}
                        label={"Прикрепить к городскому объекту"}
                    />

                    <ReferenceManyField
                        label="Серафимовские проекты"
                        reference="news_serafim_projects"
                        target="news_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="serafim_project_id"
                                reference="serafim_projects"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"news_serafim_projects"}
                        label={"Прикрепить к серафимовскому проекту"}
                    />

                    <ReferenceManyField
                        label="Текстовые страницы"
                        reference="news_text_pages"
                        target="news_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="text_page_id"
                                reference="text_pages"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"news_text_pages"}
                        label={"Прикрепить к текстовой странице"}
                    />

                    <ReferenceManyField
                        label="Статьи"
                        reference="v_news_media_articles"
                        target="news_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="media_article_id"
                                reference="media_articles"
                                link={false}
                            >
                                <ChipField source="page_title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"v_news_media_articles"}
                        label={"Прикрепить к статью"}
                    />
                </Connections>
                <Location />
                <SEO />
                <Draft />
            </TabbedShowLayout>
        </Show>
    );
}

const ShowView = withPermissions(UnmappedShowView, "news");

export { ShowView };
