import React from "react";
import {TabbedForm, Create, TextInput, FormTab, SelectInput, required} from "react-admin";
import Grid from "@material-ui/core/Grid";

export function CreateView(props) {
    return (
        <Create {...props}>
            <TabbedForm>
                <FormTab label={"Основное"} {...props}>
                    <div style={{width: "100%"}}>
                        <Grid container>
                            <Grid item xs={8} style={{paddingRight: 24, borderRight: "1px solid rgba(0, 0, 0, 0.12)"}}>
                                <TextInput
                                    source={"subject"}
                                    label={"Тема письма"}
                                    style={{width: "100%"}}
                                    validate={[required()]}
                                    resettable
                                />
                                <TextInput
                                    source={"body_text"}
                                    label={"Текст"}
                                    style={{width: "100%"}}
                                    validate={[required()]}
                                    resettable
                                    multiline
                                />
                                <TextInput
                                    source={"body_html"}
                                    label={"HTML"}
                                    style={{width: "100%"}}
                                    validate={[required()]}
                                    resettable
                                    multiline
                                />
                                <SelectInput
									source={"key"}
									label={"Тип проекта"}
									defaultValue={"donate"}
									choices={[
										{
											id: "donate", 
											name: "Пожертвование",
										},
										{
											id: "treb", 
											name: "Требы",
                                        },
                                        {
                                            id: "crowdfunding", 
											name: "Crowdfunding",
                                        }
									]} 
								/>
                            </Grid>
                        </Grid>
                    </div>
                </FormTab>
            </TabbedForm>
        </Create>
    );
}
