/* eslint-disable */

import React, { useState, useEffect } from "react";
import {
    Datagrid,
    DateField,
    Pagination,
    TextField,
    List,
    usePermissions,
    useMutation
} from "react-admin";
import { Button, Select, MenuItem } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';

import { ModalLogs } from "../ModalLogs";
import { ListActions } from "../ListActions";
import CheckPermissions from "../../../../hoc/CheckPermissions";

const useStyles = makeStyles(() => ({
    formControl: {
        opacity: "0",
        position: "absolute",
        top: 0,
        right: 0
    },
    button: {
        display: 'block',
    },
}));

function UnmappedListView(props) {
    const { loaded, permissions: role } = usePermissions();

    return (
        loaded && (
            <List
                sort={{ field: "id", order: "DESC" }}
                pagination={<PostPagination />}
                actions={<ListActions />}
                bulkActionButtons={<PostBulkActionButtons />}
                {...props}
            >
                <Datagrid isRowSelectable={() => role === "admin"}>
                    <TextField source="id" label={"Id"} />
                    <TextField source="state" label={"Статус"} />
                    <ModalLogs />
                    <TextField
                        source="user"
                        label="Пользователь"
                        showTime={true}
                    />
                    <DateField
                        source="started_at"
                        label="Дата/время начала сборки"
                        showTime={true}
                    />
                    <DateField
                        source="updated_at"
                        label="Дата изменения"
                        showTime={true}
                    />
                    <TextField
                        source="build_params"
                        label="Параметры сборки"
                        showTime={true}
                    />
                </Datagrid>
            </List>
        )
    );
}

function PostPagination(props) {
    return (
        <Pagination
            rowsPerPageOptions={[
                10,
                25,
                50,
                100,
                300,
                500,
                700,
                900,
                1100,
                1300,
                1500,
                1700,
            ]}
            {...props}
        />
    )
}

function PostBulkActionButtons({selectedIds}) {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [state, setState] = useState("");

    const [changeStatus, { loading, loaded, error }] = useMutation({
        type: 'update',
        resource: 'front_build_requests',
        payload: { id: selectedIds[0], data: { state } }
    });

    useEffect(() => {
        if(state) {
            changeStatus();
        }
    }, [state]);

    useEffect(() => {
        if(!loading && !loaded && error) {
            console.error(error);
        }

        if(loaded) {
            document.location.reload();
        }
    }, [loading])

    function handleOpen() {
        setOpen(true);
    }

    function handleChange(event) {
        setState(event.target.value);
    }

    return (
        <div style={{
                display: "flex",
                alignItems: "center",
                position: "relative"
            }}
        >
            <Button 
                className={classes.button} 
                onClick={handleOpen}
                variant="contained"
            >
                Изменить статус
            </Button>

            <FormControl className={classes.formControl}>
                <Select
                    open={open}
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    value={state}
                    onChange={handleChange}
                >
                    <MenuItem value={"created"}>created</MenuItem>
                    <MenuItem value={"cancelled"}>cancelled</MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}

const ListView = CheckPermissions(UnmappedListView, "city");

export { ListView };
