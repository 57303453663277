import React from "react";

import CheckPermissions from "../../../../hoc/CheckPermissions";
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    ImageField,
    usePermissions,
} from "react-admin";

import ApproveButton from "../../../Form/Button/Approve";

function UnmappedListView(props) {
    const { loaded, permissions: role } = usePermissions();

    const { basePath, data, isAdmin } = props;

    return (
        loaded && (
            <List sort={{ field: "id", order: "DESC" }} {...props}>
                <Datagrid
                    rowClick="show"
                    isRowSelectable={() => role === "admin"}
                >
                    {props.children}
                    <TextField source="id" label={"Id"} />
                    <TextField source="title_full" label={"Название"} />
                    <TextField source="source" label={"Источник"} />
                    <TextField source="slug" label={"Слаг"} />
                    <TextField source={"sort_order"} label={"Сортировка"} />
                    <ImageField
                        source="main_image.src"
                        label={"Главное изображение"}
                    />
                    {isAdmin && (
                        <ApproveButton basePath={basePath} record={data} />
                    )}{" "}
                    {/* TODO: Need to apply permissions */}
                    <EditButton />
                </Datagrid>
            </List>
        )
    );
}

const ListView = CheckPermissions(UnmappedListView, "city");

export { ListView };
