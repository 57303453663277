import React from "react";
import { TabbedForm, Edit, TextInput, usePermissions } from "react-admin";

import { Main, SEO, Content } from "../../../Layout/Tabs/Edit";
import withPermissions from "../../../../hoc/CheckPermissions";
import { tabListByRole } from "../../../../utils";
import { BottomToolbar } from "../../../Layout/Actions";

function UnmappedEditView(props) {
    const { loaded, permissions: role } = usePermissions();

    const PostTitle = ({ record }) => {
        return (
            <span>Редактируется {record ? `"${record.title_full}"` : ""}</span>
        );
    };

    const main = (
        <Main showSortOrder={false}>
            <TextInput
                source={"appearance_date"}
                label={"Дата появления"}
                style={{ width: "100%" }}
                resettable
            />
        </Main>
    );
    const seo = <SEO />;
    const content = <Content />;

    return (
        loaded && (
            <Edit
                title={<PostTitle />}
                {...props}
            >
                <TabbedForm toolbar={<BottomToolbar role={role} {...props} />}>
                    {tabListByRole({
                        seo: [main, seo, content],
                        admin: [main, seo, content],
                    }).get(role)}
                </TabbedForm>
            </Edit>
        )
    );
}

const EditView = withPermissions(UnmappedEditView, "ikons");

export { EditView };
