import React from "react";
import { Show, Tab, TabbedShowLayout, TextField } from "react-admin";

export function ShowView(props) {
    const PostTitle = ({ record }) => {
        return <span>Просмотр {record ? `"${record.name}"` : ""}</span>;
    };

    return (
        <Show title={<PostTitle />} {...props}>
            <TabbedShowLayout>
                <Tab label="Основное" {...props}>
                    <TextField source="name" label={"Имя"} />
                    <TextField
                        source={"slug"}
                        label={"Слаг"}
                        style={{ width: "100%" }}
                    />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
}
