import React from "react";
import { Create, TextInput, required, SimpleForm } from "react-admin";
import Grid from "@material-ui/core/Grid";

export function CreateView(props) {
    return (
        <Create {...props}>
            <SimpleForm>
                <div style={{ width: "100%" }}>
                    <Grid container>
                        <Grid
                            item
                            xs={8}
                            style={{
                                paddingRight: 24,
                                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                            }}
                        >
                            <TextInput
                                source={"text"}
                                label={"слово"}
                                style={{ width: "100%" }}
                                validate={[required()]}
                                resettable
                            />
                        </Grid>
                    </Grid>
                </div>
            </SimpleForm>
        </Create>
    );
}
