import React from "react";

import CheckPermissions from "../../../../hoc/CheckPermissions";
import { List, Datagrid, TextField, EditButton } from "react-admin";
import { get } from "lodash";

import AgreementActions from "../../../Form/Button/Approve";

function UnmappedListView(props) {
    const { permissions, roles } = props;

    return (
        <List {...props}>
            <Datagrid
                rowClick="show"
                sort={{ field: "sort_order", order: "ASC" }}
            >
                <TextField source="id" label={"id"} />
                <AgreementActions
                    actions={get(permissions, "actions.agreement", [])}
                    role={roles[0]}
                />
                <EditButton />
            </Datagrid>
        </List>
    );
}

const ListView = CheckPermissions(UnmappedListView, "city");

export { ListView };
