import React from "react";
import {
	TabbedForm,
	Create,
	TextInput,
	required,
	NumberInput,
	FormTab,
} from "react-admin";
import Grid from "@material-ui/core/Grid";

import CustomDatePicker from "../../../Form/CustomDatePicker"; 
import { SEO, Content } from "../../../Layout/Tabs/Edit";
import InputS3Uploader from "../../../Form/InputS3Uploader";

export function CreateView(props) {
	return (
		<Create {...props}>
			<TabbedForm>
				<FormTab label={"Основное"} {...props}>
					<div style={{ width: "100%" }}>
						<Grid container>
							<Grid item xs={8} style={{ paddingRight: 24, borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
								<TextInput
									source={"title_full"}
									label={"Наименование"}
									style={{ width: "100%" }}
									validate={[required()]}
									resettable
								/>
								<TextInput
									source={"title_short"}
									label={"Краткое наименование"}
									style={{ width: "100%" }}
									resettable
								/>
								<TextInput
									source={"page_title_full"}
									label={"Наименование страницы"}
									style={{ width: "100%" }}
									validate={[required()]}
									resettable
								/>
								<TextInput
									source={"page_title_short"}
									label={"Краткое наименование страницы"}
									style={{ width: "100%" }}
									resettable
								/>
							</Grid>
							<Grid item xs={4} style={{ paddingLeft: 24, marginBottom: 24 }}>
								<TextInput
									source={"slug"}
									label={"Слаг"}
									style={{ width: "100%" }}
									validate={[required()]}
									resettable
								/>
								<NumberInput
									source={"sort_order"}
									label={"Сортировка"}
									style={{ width: "100%" }}
									validate={[required()]}
									resettable
								/>
								<CustomDatePicker
									source={"event_date"}
									label={"Дата события"}
									style={{ width: "100%" }}
									validate={[required()]}
									resettable
								/>
								<InputS3Uploader
									source={"main_image"}
									label={"Главное изображение"}
									validate={[required()]}
								/>
								<InputS3Uploader
									source={"main_image_mobile"}
									label={"Главное изображение для мобильной версии"}
								/>
								<InputS3Uploader
									source={"main_image_preview"}
									label={"Превью изображение"}
								/>
							</Grid>
						</Grid>
					</div>
				</FormTab>
				<SEO />
				<Content />
			</TabbedForm>
		</Create>
	);
}
