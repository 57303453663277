const permissions = {
    admin: {
        login: "admin",
        allow: {
            models: {
                common: {
                    list: true,
                    create: true,
                    edit: true,
                    show: true,
                    attributes: true,
                    comment: true,
                    approve: true,
                },
            },
        },
        allowResourcesAll: true,
    },
    manager: {
        login: "manager",
        allow: {
            models: {
                common: {
                    list: true,
                    create: true,
                    edit: true,
                    show: true,
                    attributes: true,
                    comment: true,
                    approve: true,
                },
            },
        },
        allowResourcesAll: true,
    },
    writer: {
        login: "writer",
        allow: {
            models: {
                common: {
                    list: true,
                    create: true,
                    edit: true,
                    show: true,
                    attributes: true,
                    comment: true,
                    approve: true,
                },
            },
        },
        allowResourcesAll: false,
        allowResources: ["news", "media_articles", "authors", "categories"],
    },
    seo: {
        login: "seo-admin",
        allow: {
            models: {
                tabs: {
                    list: true,
                    create: false,
                    edit: {
                        main: false,
                        seo: true,
                        location: false,
                        content: false,
                        draft: false,
                        attributes: false,
                    },
                    show: true,
                    attributes: true,
                },
                common: {
                    list: true,
                    create: false,
                    edit: true,
                    show: true,
                    attributes: true,
                    comment: false,
                    approve: true,
                },
            },
        },
        allowResourcesAll: false,
        allowResources: [
            "audio_categories",
            "books",
            "broadcasts",
            "cathedrals",
            "churches",
            "city_objects",
            "cities",
            "competition_projects",
            "events",
            "hermitages",
            "holidays",
            "ikons",
            "landings",
            "main_page",
            "media_audios",
            "media_books",
            "media_periodics",
            "media_photos",
            "monasteries",
            "museums",
            "text_pages",
            "news",
            "prayers",
            "radio",
            "regions",
            "saints",
            "serafim_projects",
            "temples",
            "shrines",
            "tv_obraz",
            "wells",
            "v_media_videos",
            "v_media_audios",
            "tours",
            "votings",
            "belltowers",
            "chapels",
            "media_articles",
            "media_videos",
            "posts",
            "virtual_tours",
            "page_settings",
            "v_portal_admin_cities",
        ],
    },
};

export default permissions;
