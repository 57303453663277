import React from "react";
import {
    TabbedForm,
    Create,
    TextInput,
    required,
    FormTab,
    ReferenceInput,
    SelectInput,
} from "react-admin";
import Grid from "@material-ui/core/Grid";

import CustomDatePicker from "../../../Form/CustomDatePicker"; 

export function CreateView(props) {
    return (
        <Create {...props}>
            <TabbedForm>
                <FormTab label="Расписание" {...props}>
                    <div style={{ width: "100%" }}>
                        <Grid container spacing={3}>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    borderBottom:
                                        "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                            >
                                {props.before}
                            </Grid>
                            <Grid item xs={12}>
                                <CustomDatePicker
                                    source={"begins_at"}
                                    label={"Дата и время начала"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <TextInput
                                    source="data.holiday"
                                    label="Название праздника"
                                    style={{ width: "100%" }}
                                />
                                <TextInput
                                    source="data.title"
                                    label="Заголовок службы"
                                    style={{ width: "100%" }}
                                />
                                <ReferenceInput
                                    source={"broadcast_id"}
                                    reference={"broadcasts"}
                                    label={"Трансляция"}
                                    allowEmpty
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <SelectInput
                                        optionText="title_full"
                                        emptyValue={null}
                                    />
                                </ReferenceInput>
                            </Grid>
                            <Grid item xs={12}>
                                {props.children}
                            </Grid>
                        </Grid>
                    </div>
                </FormTab>
            </TabbedForm>
        </Create>
    );
}
