import React from "react";
import {
    TabbedForm,
    Edit,
    ReferenceInput,
    SelectInput,
    TopToolbar,
    ListButton,
    ShowButton,
    usePermissions,
} from "react-admin";
import Grid from "@material-ui/core/Grid";

import {
    SEO,
    Content,
    Main,
    Location,
    BuildingAttributes,
} from "../../../Layout/Tabs/Edit";
import { appeal_to_visitors } from "../CreateView";
import withPermissions from "../../../../hoc/CheckPermissions";
import { tabListByRole, transformDataByRole } from "../../../../utils";
import { BottomToolbar } from "../../../Layout/Actions";

function UnmappedEditView(props) {
    const { loaded, permissions: role } = usePermissions();

    const PostTitle = ({ record }) => {
        return (
            <span>Редактируется {record ? `"${record.title_short}"` : ""}</span>
        );
    };
    const PostEditActions = ({ basePath, data }) => (
        <TopToolbar>
            <ShowButton basePath={basePath} record={data} />
            <ListButton basePath={basePath} />
        </TopToolbar>
    );

    const main = (
        <Main ShowThreeDModelField={true} showPublishedAtField={true}>
            <Grid xs={2}>
                <ReferenceInput
                    source={"region_id"}
                    reference={"regions"}
                    label={"Регион"}
                    allowEmpty
                    style={{
                        width: "100%",
                    }}
                >
                    <SelectInput optionText="title_full" emptyValue={null} />
                </ReferenceInput>
                <ReferenceInput
                    source={"city_id"}
                    reference={"v_portal_admin_cities"}
                    label={"Город"}
                    allowEmpty
                    style={{
                        width: "100%",
                    }}
                >
                    <SelectInput optionText="title_full" emptyValue={null} />
                </ReferenceInput>
                <ReferenceInput
                    source={"monastery_id"}
                    reference={"monasteries"}
                    label={"Монастырь"}
                    allowEmpty
                    style={{
                        width: "100%",
                    }}
                >
                    <SelectInput optionText={"title_short"} emptyValue={null} />
                </ReferenceInput>
                <ReferenceInput
                    source={"cathedral_id"}
                    reference={"cathedrals"}
                    label={"Собор"}
                    allowEmpty
                    style={{
                        width: "100%",
                    }}
                >
                    <SelectInput optionText={"title_short"} emptyValue={null} />
                </ReferenceInput>
                <ReferenceInput
                    source={"temple_id"}
                    reference={"temples"}
                    label={"Храм"}
                    allowEmpty
                    style={{
                        width: "100%",
                    }}
                >
                    <SelectInput optionText={"title_short"} emptyValue={null} />
                </ReferenceInput>
                <ReferenceInput
                    source={"church_id"}
                    reference={"churches"}
                    label={"Церковь"}
                    allowEmpty
                    style={{
                        width: "100%",
                    }}
                >
                    <SelectInput optionText={"title_short"} emptyValue={null} />
                </ReferenceInput>
            </Grid>
        </Main>
    );
    const location = <Location />;
    const seo = <SEO />;
    const content = <Content before={appeal_to_visitors} />;
    const buildingAttributes = <BuildingAttributes />;

    return (
        loaded && (
            <Edit
                title={<PostTitle />}
                actions={<PostEditActions />}
                transform={(data) => transformDataByRole(role, data)}
                {...props}
            >
                <TabbedForm toolbar={<BottomToolbar role={role} {...props} />}>
                    {tabListByRole({
                        seo,
                        admin: [
                            main,
                            location,
                            seo,
                            content,
                            buildingAttributes,
                        ],
                    }).get(role)}
                </TabbedForm>
            </Edit>
        )
    );
}

const EditView = withPermissions(UnmappedEditView, "shrines");

export { EditView };
