import React from "react";
import { Tab } from "react-admin";

export function Connections(props) {
	return (
		props.children ? <Tab label="Связи" {...props}>
			{props.children}
		</Tab> : null
	)
};
