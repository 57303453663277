import React, { useEffect } from "react";

import { useVKContext } from "./context";

export function Post({ elementId, ownerId, postId, hash, options }) {
    const { Post } = useVKContext();

    useEffect(() => {
        Post(elementId, ownerId, postId, hash, options);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Post]);

    return (
        <div className={"vk-post"}>
            <div id={elementId} />
        </div>
    )
}

export function Article({ elementId, articleUrl }) {
    const { Article } = useVKContext();
    
    useEffect(() => {
        Article(elementId, articleUrl);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Article]);

    return (
        <div className={"vk-article"}>
            <div id={elementId} />
        </div>
    )
}