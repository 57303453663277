import React from "react";
import { List, TextField, EditButton, Datagrid } from "react-admin";
import { get } from "lodash";

import AgreementActions from "../../../Form/Button/Approve";
import CheckPermissions from "../../../../hoc/CheckPermissions";

function UnmappedListView(props) {

	const { permissions, roles } = props;

	return (
		<List {...props}>
			<Datagrid
				rowClick="show"
				sort={{ field: "id", order: "DESC" }}
			>
				<TextField source="id" label={"id"} />
				<TextField source="key" label={"Тип проекта"} />
				<TextField source="subject" label={"Тема письма"} />
				<AgreementActions
					actions={get(permissions, "actions.agreement", [])}
					role={roles[0]}
				/>
				<EditButton />
			</Datagrid>
		</List>
	);
}

const ListView = CheckPermissions(UnmappedListView, "mail_templates");

export { ListView };
