import React from "react";
import {
	TabbedForm,
	Edit,
	FormTab,
	ArrayInput,
	SimpleFormIterator,
	TextInput,
	BooleanInput,
	SelectInput,
	required,
	ReferenceInput
} from "react-admin";
import Grid from "@material-ui/core/Grid";

import { EditActions } from "../../../Layout/Actions";
import InputS3UploaderMulti from "../../../Form/InputS3UploaderMulti";
import CustomDatePicker from "../../../Form/CustomDatePicker"; 

export function EditView(props) {
	function PostTitle({record}) {
		return <span>Редактируется {record ? `"${record.id}"` : ""}</span>;
	}

	return (
		<Edit title={<PostTitle/>} actions={<EditActions/>} {...props}>
			<TabbedForm>
				<FormTab label={"Основное"} {...props}>
					<div style={{width: "100%"}}>
						<Grid container>
							<Grid item xs={8} style={{paddingRight: 24, borderRight: "1px solid rgba(0, 0, 0, 0.12)"}}>
								<TextInput
									source={"mailing_cron"}
									label={"Дата и время рассылки, в формате крон"}
									style={{ width: "100%" }}
									resettable
									helperText={"Классический формат состоит из пяти полей разделённых пробелами: <Минута> <Час> <Число> <Месяц> <День недели>, 0 0 0 0 0"}
								/>
								<TextInput
									source={"subject"}
									label={"Тема письма"}
									style={{width: "100%"}}
									validate={[required()]}
									resettable
								/>
								<TextInput
									source={"body_text"}
									label={"Текст"}
									style={{width: "100%"}}
									validate={[required()]}
									resettable
									multiline
								/>
								<TextInput
									source={"body_html"}
									label={"HTML"}
									style={{width: "100%"}}
									validate={[required()]}
									resettable
									multiline
								/>
								<SelectInput
									source={"key"}
									label={"Тип проекта"}
									defaultValue={"donate"}
									choices={[
										{
											id: "donate", 
											name: "Пожертвование",
										},
										{
											id: "treb", 
											name: "Требы",
										},
										{
											id: "crowdfunding", 
											name: "Crowdfunding",
										}
									]} 
								/>
								<p>
									<h4>Прикрепления</h4>
								</p>
								<InputS3UploaderMulti
									source={"params.attachments"}
									style={{ width: "100%" }}
								/>
								<ArrayInput
									source="params.addresses"
									label="Адресаты"
								>
									<SimpleFormIterator>
										<ReferenceInput
											source="crowdfunding_project_id"
											reference="v_crowdfunding_projects_addresses"
											perPage={1000}
											style={{ width: "100%" }}
											label={"Краундфандинг проект"}
										>
											<SelectInput optionText="data.title" />
										</ReferenceInput>
										<CustomDatePicker
											source={"date_from"}
											label={"Дата, с которой включать инвесторов в рассылку"}
											style={{ width: "100%" }}
											resettable
										/>
										<BooleanInput
											label="За весь период"
											source="entire_period"
											defaultValue={false}
										/>
									</SimpleFormIterator>
								</ArrayInput>
								<BooleanInput
									label="Отправить всем"
									source="params.send_to_everyone"
									defaultValue={false}
								/>
							</Grid>
						</Grid>
					</div>
				</FormTab>
			</TabbedForm>
		</Edit>
	);
}
