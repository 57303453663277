/* eslint-disable  */
import React, { useState, useEffect } from "react";
import {
    useCreateController,
    CreateContextProvider,
    useDataProvider,
    TabbedForm,
    FormTab,
    TextInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    required,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import { get } from "lodash";
import PropTypes from "prop-types";
import { useDebounceFn } from "@umijs/hooks";

import { SEO, Content, Location } from "../../../Layout/Tabs/Edit";
import InputS3Uploader from "../../../Form/InputS3Uploader";

const MyCreate = (props) => {
    const createControllerProps = useCreateController(props);

    const {
        basePath, // deduced from the location, useful for action buttons
        record, // empty object, unless some values were passed in the location state to prefill the form
        redirect, // the default redirection route. Defaults to 'edit', unless the resource has no edit view, in which case it's 'list'
        resource, // the resource name, deduced from the location. e.g. 'posts'
        saving, // boolean that becomes true when the dataProvider is called to create the record
        version, // integer used by the refresh feature
    } = createControllerProps;
    const dataProvider = useDataProvider();

    const [videoData, setVideoData] = useState();
    const [modal, setModal] = useState({ open: false, message: "" });

    const { run: showMessage } = useDebounceFn(() => {
        document.location.reload();
    }, 2000);

    useEffect(() => {
        if (videoData) {
            const data = {
                media_video_category_id: videoData.tv_obraz_id,
                media_video_id: videoData.id,
            };
            dataProvider
                .create("media_video_categories_media_videos", { data })
                .then(() =>
                    setModal({ open: true, message: "Создание завершено!" })
                )
                .catch((error) => console.error(error));
        }
    }, [videoData]);

    useEffect(() => {
        if (get(modal, "open")) {
            showMessage();
        }
    }, [modal]);

    function save(data) {
        const tv_obraz_id = data.tv_obraz_id;

        delete data.tv_obraz_id;

        dataProvider
            .create("media_videos", {
                data,
            })
            .then((response) =>
                setVideoData({
                    id: get(response, "data.id"),
                    tv_obraz_id,
                })
            )
            .catch((error) => console.error(error));
    }

    return (
        <CreateContextProvider value={createControllerProps}>
            <div>
                <h1>Создать видео (ТВ образ)</h1>
                {React.cloneElement(props.children, {
                    basePath,
                    record,
                    redirect,
                    resource,
                    save,
                    saving,
                    version,
                })}
            </div>
        </CreateContextProvider>
    );
};

MyCreate.propTypes = {
    children: PropTypes.node,
};

export function CreateView(props) {
    return (
        <MyCreate {...props}>
            <TabbedForm>
                <FormTab label={"Основное"} {...props}>
                    <div style={{ width: "100%" }}>
                        <Grid container>
                            <Grid
                                item
                                xs={8}
                                style={{
                                    paddingRight: 24,
                                    borderRight:
                                        "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                            >
                                <TextInput
                                    source={"title_full"}
                                    label={"Наименование"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <TextInput
                                    source={"title_short"}
                                    label={"Краткое наименование"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <TextInput
                                    source={"page_title_full"}
                                    label={"Наименование страницы"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <TextInput
                                    source={"page_title_short"}
                                    label={"Краткое наименование страницы"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <TextInput
                                    source={"src"}
                                    label={"Видео ссылка"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <TextInput
                                    source={"teaser"}
                                    label={"Тизер"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <ReferenceInput
                                    source={"tv_obraz_id"}
                                    reference={"tv_obraz"}
                                    label={"Тв образ"}
                                    allowEmpty
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <SelectInput
                                        optionText="title_full"
                                        emptyValue={null}
                                    />
                                </ReferenceInput>
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                style={{
                                    paddingLeft: 24,
                                    marginBottom: 24,
                                }}
                            >
                                <TextInput
                                    source={"slug"}
                                    label={"Слаг"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <NumberInput
                                    source={"sort_order"}
                                    label={"Сортировка"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <InputS3Uploader
                                    source={"main_image"}
                                    label={"Главное изображение"}
                                    validate={[required()]}
                                />
                                <InputS3Uploader
                                    source={"main_image_mobile"}
                                    label={
                                        "Главное изображение для мобильной версии"
                                    }
                                />
                                <InputS3Uploader
                                    source={"main_image_preview"}
                                    label={"Превью изображение"}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                            >
                                {props.children}
                            </Grid>
                        </Grid>
                    </div>
                </FormTab>
                <Location />
                <SEO />
                <Content />
            </TabbedForm>
        </MyCreate>
    );
}
