import React from "react";
import { TextField, ReferenceField } from "react-admin";
import {
    Create,
    ReferenceInput,
    SelectInput,
    TabbedShowLayout,
    Tab,
} from "react-admin";
import { Show, Edit } from "react-admin";

import { SimpleForm, required } from "react-admin";

import { parse } from "query-string";
import { get } from "lodash";

import AutocompleteField from "../../../Form/Autocomplete";

export const CreateView = (props) => {
    const { news_id } = parse(props.location.search);
    const redirect = get(props, "location.state.redirect_to", "") || false;

    return (
        <Create {...props}>
            <SimpleForm initialValues={{ news_id }} redirect={redirect}>
                <ReferenceInput
                    label={"Серафимовские проекты"}
                    source="serafim_project_id"
                    reference="serafim_projects"
                    validate={required()}
                    perPage={100}
                >
                    <AutocompleteField title="Серафимовские проекты" />
                </ReferenceInput>
                <ReferenceInput
                    source="news_id"
                    reference="news"
                    validate={required()}
                    style={{ display: "none" }}
                    perPage={10000}
                >
                    <SelectInput optionText="id" />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};

export const EditView = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <ReferenceInput
                label={"Серафимовские проекты"}
                source="serafim_project_id"
                reference="serafim_projects"
                validate={required()}
                perPage={100}
            >
                <AutocompleteField title="Серафимовские проекты" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

export const ShowView = (props) => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label={"Основное"} {...props}>
                <ReferenceField
                    label="Серафимовские проекты"
                    source="serafim_project_id"
                    reference="serafim_projects"
                    perPage={100}
                >
                    <TextField source="title_full" />
                </ReferenceField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
