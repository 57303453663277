import React, { useState } from "react";
import { FormTab } from "react-admin";
import { get } from "lodash";

import InputMap from "../../../../Form/InputMap";
import LocationPoint from "../../../../Form/LocationPoint";

export function Location(props) {
    const { edit: { location } = { location: true }, edit } = get(
        props,
        "permissions",
        {}
    );

    const [locationPoint, setLocationPoint] = useState();

    const getLocationPoint = (point) => {
        const points = get(
            get(point, "features", []).filter(
                (g) => get(g, "geometry.type", "") === "Point"
            ),
            "[0].geometry.coordinates",
            []
        );

        if (points.length === 2) {
            setLocationPoint(`${points[0]},${points[1]}`);
        }
    };
    const showLocation = (typeof edit === "boolean" && edit) || location;

    return showLocation ? (
        <FormTab label={"Расположение"} {...props}>
            <LocationPoint
                source={"location_point"}
                label={"Центр"}
                point={locationPoint}
            />
            <InputMap
                source={"location_object"}
                label={"Контур"}
                edit
                onChange={(point) => getLocationPoint(point)}
            />
            {props.children}
        </FormTab>
    ) : null;
}
