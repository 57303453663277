import React from "react";
import {
    Show,
    TextField,
    TabbedShowLayout,
    ImageField,
    Tab,
} from "react-admin";

import {
    SEO
} from "../../../Layout/Tabs/Show";

export function ShowView(props) {
    const PostTitle = ({ record }) => {
        return <span>Просмотр {record ? `"${record.page_title_full}"` : ""}</span>;
    };

    return (
        <Show title={<PostTitle />} {...props}>
            <TabbedShowLayout>
                <Tab label="Основное" {...props}>
                    <TextField
                        source={"slug"}
                        label={"Слаг"}
                        style={{ width: "100%" }}
                    />
                    <ImageField source="main_image.src" label="Главное изображение" />
                    <ImageField
                        source="main_image_preview.src"
                        label="Превью изображение"
                    />
                    <TextField
                        source="page_title_full"
                        label={"Наименование страницы"}
                    />
                    <TextField
                        source="page_title_short"
                        label={"Краткое наименование страницы"}
                    />
                    <TextField
                        source="teaser"
                        label={"Тизер"}
                    />
                </Tab>
                <SEO />
            </TabbedShowLayout>
        </Show>
    );
}
