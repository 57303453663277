import React from "react";
import {
    Show,
    TabbedShowLayout,
    DateField,
    Tab,
    ReferenceField,
    TextField,
    ReferenceManyField,
    SingleFieldList,
    Button,
    ChipField,
} from "react-admin";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";

import { Connections } from "../../../Layout/Tabs/Show";
import withPermissions from "../../../../hoc/CheckPermissions";

function UnmappedShowView(props) {
    const PostTitle = ({ record }) => {
        return (
            <span>Просмотр {record ? `"${record.page_title_full}"` : ""}</span>
        );
    };

    const AddButton = ({ redirect_to, record, table, label }) => (
        <Button
            variant="raised"
            component={Link}
            to={{
                pathname: `/${table}/create`,
                state: {
                    record: { main_page_fixed_content_id: record.id },
                    redirect_to,
                },
            }}
            label={label}
            title={label}
        >
            <AddIcon />
        </Button>
    );

    const AddReferenceButton = AddButton;

    return (
        <Show
            title={<PostTitle />}
            /* actions={<PostShowActions />} */ {...props}
        >
            <TabbedShowLayout>
                <Tab label="Основное" {...props}>
                    <DateField label={"Позиция"} source="position" />
                    <ReferenceField
                        source="component_id"
                        reference="components"
                        link={false}
                        label="Тип блока"
                    >
                        <TextField label={"Тип блока"} source="name" />
                    </ReferenceField>
                </Tab>
                <Connections>
                    <ReferenceManyField
                        label="Таргетинг"
                        reference="targetings_main_page_fixed_contents"
                        target="main_page_fixed_content_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="targeting_id"
                                reference="targetings"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"targetings_main_page_fixed_contents"}
                        label={"Прикрепить к таргету"}
                    />
                </Connections>
            </TabbedShowLayout>
        </Show>
    );
}

const ShowView = withPermissions(UnmappedShowView, "news");

export { ShowView };
