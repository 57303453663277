import React from "react";
import {
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
    ImageField,
    Button,
    ReferenceManyField,
    SingleFieldList,
    ReferenceField,
    ChipField,
} from "react-admin";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";

import { Connections } from "../../../Layout/Tabs/Show";

export function ShowView(props) {
    const PostTitle = ({ record }) => {
        return <span>Просмотр {record ? `"${record.title}"` : ""}</span>;
    };

    const AddButton = ({ redirect_to, record, table, label }) => (
        <Button
            variant="raised"
            component={Link}
            to={{
                pathname: `/${table}/create`,
                state: { record: { banner_id: record.id }, redirect_to },
            }}
            label={label}
            title={label}
        >
            <AddIcon />
        </Button>
    );

    const AddReferenceButton = AddButton;

    return (
        <Show title={<PostTitle />} {...props}>
            <TabbedShowLayout>
                <Tab label="Основное" {...props}>
                    <TextField source="title" label={"Название"} />
                    <TextField source="params.type" label={"Тип баннера"} />
                    <TextField source={"link"} label={"Ссылка"} />
                    <TextField
                        source={"params.locations"}
                        label={"Места вывода"}
                    />
                    <ImageField
                        source="params.image.src"
                        label="Главное изображение"
                    />
                </Tab>
                <Connections>
                    <ReferenceManyField
                        label="Текстовые страницы"
                        reference="banners_text_pages"
                        target="banner_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="text_page_id"
                                reference="text_pages"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"banners_text_pages"}
                        label={"Прикрепить к текстовой странице"}
                    />
                </Connections>
            </TabbedShowLayout>
        </Show>
    );
}
