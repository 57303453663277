import React from "react";
import {
    TextField,
    DateField,
    List,
    ImageField,
    EditButton,
    Datagrid,
    usePermissions,
} from "react-admin";
import { get } from "lodash";

import withPermissions from "../../../../hoc/CheckPermissions";
import AgreementActions from "../../../Form/Button/Approve";
import Rating from "../../../Form/Rating";
import VoteCount from "../../../Form/VoteCount";

import "./style.css";

function UnmappedListView(props) {
    const { loaded, permissions: role } = usePermissions();

    const { permissions, roles } = props;
    const list = get(permissions, "list", []);

    return loaded && list ? (
        <List {...props} sort={{ field: "issued_at", order: "DESC" }}>
            <Datagrid rowClick="show" isRowSelectable={() => role === "admin"}>
                <TextField source="id" />
                <DateField source="issued_at" label="Дата новости" />
                <TextField source="sort_order" label={"Сортировка"} />
                <DateField source="published_at" label="Показывать после" />
                <Rating source="id" label="Рейтинг" sortable={false} />
                <VoteCount
                    source="id"
                    label="Кол-во проголосовавших"
                    sortable={false}
                />
                <ImageField
                    source={"main_image.src"}
                    label={"Главное изображение"}
                    className={"image-cell"}
                />
                <TextField
                    source="page_title_short"
                    width={"50%"}
                    label={"Название"}
                />
                <AgreementActions
                    actions={get(permissions, "actions.agreement", [])}
                    role={roles[0]}
                />
                <EditButton />
            </Datagrid>
        </List>
    ) : null;
}

const ListView = withPermissions(UnmappedListView, "news");

export { ListView };
