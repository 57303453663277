import 'date-fns';
import React, {useState, useEffect} from "react"
import { addField } from "ra-core";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker,
} from '@material-ui/pickers';
import moment from "moment"

function CustomDatePicker({ input: { onChange, value }, label }) {
    const [defaultDate, setDefaultDate] = useState(null)

    useEffect(() => {
        if(value.length){
            setDefaultDate(value)
        }

        return () => {
            if(defaultDate !== value){
                onChange(defaultDate)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function handleDateChange(value) {
        const verifiedValue = moment(
            new Date(value)
        ).format();
        if (value) {
            setDefaultDate(verifiedValue);
            onChange(verifiedValue);
        } else {
            setDefaultDate(value);
            onChange(value);
        }
    }
    
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
                inputVariant="filled"
                margin="normal"
                value={defaultDate}
                onChange={handleDateChange}
                variant="inline"
                label={label}
                style={{
                    width: "100%"
                }}
                format="dd.MM.yyyy / HH:mm"
                emptyLabel={"__.__.____ / __:__"}
            />
        </MuiPickersUtilsProvider>
    )
}

export default addField(CustomDatePicker);