import React from "react";
import { TextField, ReferenceField } from "react-admin";

import List from "../../../Layout/List";
import CheckPermissions from "../../../../hoc/CheckPermissions";

function UnmappedListView(props) {
    return (
        <List {...props} sort={{ field: "created_at", order: "DESC" }}>
            <ReferenceField
                source="region_id"
                reference="regions"
                link={false}
                label="Регион"
            >
                <TextField source="title_full" />
            </ReferenceField>
        </List>
    );
}

const ListView = CheckPermissions(UnmappedListView, "city");

export { ListView };
