import React from "react";
import {
    Show,
    TabbedShowLayout,
    Button,
    ReferenceManyField,
    SingleFieldList,
    ReferenceField,
    ChipField,
} from "react-admin";

import {
    SEO,
    Content,
    Main,
    Location,
    Connections,
} from "../../../Layout/Tabs/Show";
import { Link } from "react-router-dom";

import AddIcon from "@material-ui/icons/Add";

export function ShowView(props) {
    const PostTitle = ({ record }) => {
        return <span>Просмотр {record ? `"${record.title_full}"` : ""}</span>;
    };

    const AddButton = ({ redirect_to, record, table, label }) => (
        <Button
            variant="raised"
            component={Link}
            to={{
                pathname: `/${table}/create`,
                state: { record: { tour_id: record.id }, redirect_to },
            }}
            label={label}
            title={label}
        >
            <AddIcon />
        </Button>
    );

    const AddReferenceButton = AddButton;

    return (
        <Show title={<PostTitle />} {...props}>
            <TabbedShowLayout>
                <Main />
                <Connections>
                    <ReferenceManyField
                        label="Города"
                        reference="cities_tours"
                        target="tour_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="city_id"
                                reference="v_portal_admin_cities"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"cities_tours"}
                        label={"Прикрепить к городу"}
                    />
                </Connections>
                <Location />
                <SEO />
                <Content />
            </TabbedShowLayout>
        </Show>
    );
}
