import React from "react";
import {
    Create,
    ReferenceInput,
    TabbedShowLayout,
    Tab,
    SimpleForm,
    required,
    TextField,
    ReferenceField,
    Show,
    Edit,
} from "react-admin";
import { get } from "lodash";

import AutocompleteField from "../../../Form/Autocomplete";

export const CreateView = (props) => {
    const redirect = get(props, "location.state.redirect_to", "") || false;

    return (
        <Create {...props}>
            <SimpleForm redirect={redirect}>
                <ReferenceInput
                    label={"Статья"}
                    source="linked_media_article_id"
                    reference="media_articles"
                    perPage={1000}
                >
                    <AutocompleteField title={"Статья"} />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};

export const EditView = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <ReferenceInput
                    label={"Статья"}
                    source="linked_media_article_id"
                    reference="media_articles"
                    validate={required()}
                    perPage={1000}
                    sort={{
                        field: "id",
                        order: "desc",
                    }}
                >
                    <AutocompleteField
                        resource="media_articles"
                        source={"linked_media_article_id"}
                    />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
};

export const ShowView = (props) => {
    return (
        <Show {...props}>
            <TabbedShowLayout>
                <Tab label={"Основное"} {...props}>
                    <ReferenceField
                        label="Статья"
                        source="linked_media_article_id"
                        reference="linked_media_articles"
                        perPage={1000}
                        sort={{
                            field: "id",
                            order: "desc",
                        }}
                    >
                        <TextField source="page_title_full" />
                    </ReferenceField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};
