import React from "react";
import {
	TabbedForm,
	Create,
	TextInput,
	required,
	FormTab, NumberInput
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import InputS3Uploader from "../../../Form/InputS3Uploader";

import { SEO, Content } from "../../../Layout/Tabs/Edit";

export function CreateView(props) {
	return (
		<Create {...props}>
			<TabbedForm>
				<FormTab label={"Основное"} {...props}>
					<div style={{ width: "100%" }}>
						<Grid container>
							<Grid item xs={8} style={{ paddingRight: 24, borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
								<TextInput
									source={"title"}
									label={"Наименование категории аудиогидов"}
									style={{ width: "100%" }}
									validate={[required()]}
									resettable
								/>
							</Grid>
							<Grid
								item
								xs={4}
								style={{ paddingLeft: 24, marginBottom: 24 }}
							>
								<NumberInput
									source={"sort_order"}
									label={"Сортировка"}
									style={{ width: "100%" }}
									validate={[required()]}
									resettable
								/>
								<TextInput
									source={"slug"}
									label={"Слаг"}
									style={{ width: "100%" }}
									validate={[required()]}
									resettable
								/>
								<InputS3Uploader
									source={"main_image"}
									label={"Главное изображение"}
									validate={[required()]}
								/>
							</Grid>
						</Grid>
					</div>
				</FormTab>
				<SEO />
				<Content />
			</TabbedForm>
		</Create>
	);
}
