import React from "react";
import { Show, TabbedShowLayout } from "react-admin";

import { SEO, Content, Main, Location } from "../../../Layout/Tabs/Show";

export function ShowView(props) {
	const PostTitle = ({ record }) => {
		return <span>Просмотр {record ? `"${record.title_full}"` : ""}</span>;
	}

	return (
		<Show title={<PostTitle />} {...props}>
			<TabbedShowLayout>
				<Main />
				<Location />
				<SEO />
				<Content />
			</TabbedShowLayout>
		</Show>
	)
};
