import React from "react";
import {
    TopToolbar,
    ListButton,
    ShowButton,
    Toolbar,
    SaveButton,
    DeleteButton,
} from "react-admin";

export function EditActions({
    basePath,
    className,
    data,
    hasList,
    hasShow,
    resource,
}) {
    return (
        <TopToolbar>
            <ShowButton basePath={basePath} record={data} />
            <ListButton basePath={basePath} />
        </TopToolbar>
    );
}

export function BottomToolbar(props) {
    return (
        <Toolbar
            {...props}
            style={{
                display: "flex",
                justifyContent: "space-between",
            }}
        >
            <SaveButton />
            {props.role === "admin" && <DeleteButton />}
        </Toolbar>
    );
}
