import React from "react";
import {
    TabbedForm,
    Create,
    TextInput,
    ReferenceInput,
    required,
    SelectInput, NumberInput, FormTab,
} from "react-admin";

import {
    SEO
} from "../../../Layout/Tabs/Edit";
import Grid from "@material-ui/core/Grid";
import InputS3Uploader from "../../../Form/InputS3Uploader";

export function CreateView(props) {
    return (
        <Create {...props}>
            <TabbedForm>
                <FormTab label={"Основное"} {...props}>
                    <div style={{ width: "100%" }}>
                        <Grid container>
                            <Grid
                                item
                                xs={8}
                                style={{
                                    paddingRight: 24,
                                    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                            >
                                <TextInput
                                    source={"page_title_full"}
                                    label={"Наименование"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <TextInput
                                    source={"page_title_short"}
                                    label={"Краткое наименование"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <TextInput
                                    source={"teaser"}
                                    label={"Тизер новости"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <TextInput
                                    source={"author"}
                                    label={"Автор"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                style={{ paddingLeft: 24, marginBottom: 24 }}
                            >
                                <NumberInput
                                    source={"sort_order"}
                                    label={"Сортировка"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <InputS3Uploader
                                    source={"main_image"}
                                    label={"Главное изображение"}
                                    validate={[required()]}
                                    options={
                                        {
                                            // TODO: generate category path s3path: `uploads-test/${category}`,
                                        }
                                    }
                                />
                                <InputS3Uploader
                                    source={"main_image_preview"}
                                    label={"Превью изображение"}
                                    options={
                                        {
                                            // TODO: generate category path s3path: `uploads-test/${category}`,
                                        }
                                    }
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <Grid xs={6}>
                        <ReferenceInput
                            source={"voting_id"}
                            reference={"votings"}
                            label={"Голосование"}
                            validate={[required()]}
                            style={{
                                width: "100%",
                            }}
                        >
                            <SelectInput optionText="page_title_full" />
                        </ReferenceInput>
                    </Grid>
                </FormTab>
                <SEO />
            </TabbedForm>
        </Create>
    );
}
