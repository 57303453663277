import React from "react";
import {
    TextField,
    List,
    ImageField,
    EditButton,
    Datagrid,
    usePermissions,
} from "react-admin";
import { get } from "lodash";

function ListView(props) {
    const { loaded, permissions: role } = usePermissions();

    const { permissions } = props;
    const list = get(permissions, "list", []);

    return loaded && list ? (
        <List {...props} sort={{ field: "id", order: "DESC" }}>
            <Datagrid rowClick="show" isRowSelectable={() => role === "admin"}>
                <TextField source="id" />
                <TextField source="sort_order" label={"Сортировка"} />
                <ImageField
                    source={"main_image.src"}
                    label={"Главное изображение"}
                    className={"image-cell"}
                />
                <TextField source="title_full" label={"Название"} />
                <EditButton />
            </Datagrid>
        </List>
    ) : null;
}

export { ListView };
