import React from "react";
import { TopToolbar, Button, usePermissions } from "react-admin";
import { useSnackbar } from "notistack";
import { useToggle } from "@umijs/hooks";

import { ModalLogs } from "../ModalLogs";

export const ListActions = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { permissions } = usePermissions();
    const { state, toggle } = useToggle();

    function rebuildProject(keys = []) {
        const BUILD_PARAMS = { keys };
        const headers = new Headers();

        headers.append("Content-Type", "application/json");

        const request = new Request(
            `${process.env.REACT_APP_BASE_API}/api/admin/front_build_requests`,
            {
                headers,
                method: "POST",
                body: JSON.stringify({
                    build_params: BUILD_PARAMS,
                }),
                mode: "cors",
                credentials: "include",
                redirect: "follow",
            }
        );

        fetch(request)
            .then((response) => {
                const { ok, status } = response;

                if (ok) {
                    enqueueSnackbar("Запрос на пересборку принят!");
                } else {
                    console.error(`Rebuild failed ${status}`);
                }
            })
            .catch((error) => {
                enqueueSnackbar("Возникла ошибка, при запросе!");
                console.error("Rebuild response error", { error });
            });
    }

    function stopRebuild() {
        const request = new Request(
            `${process.env.REACT_APP_BASE_API}/api/admin/front_build_requests`,
            {
                method: "DELETE",
                credentials: "include",
            }
        );

        fetch(request)
            .then((response) => {
                const { status } = response;

                if (status === 200) {
                    enqueueSnackbar("Запрос на остановку принят!");
                } else if (status === 204) {
                    enqueueSnackbar("Нечего остановить!");
                } else {
                    enqueueSnackbar("Возникла ошибка, при запросе!");
                    console.error(`Stop rebuild failed ${status}`);
                }
            })
            .catch((error) => {
                enqueueSnackbar("Возникла ошибка, при запросе!");
                console.error("Stop rebuild response error", { error });
            });
    }

    const actions = [
        {
            label: "Запустить пересборку новостей",
            onClick: () => rebuildProject(["NEWS", "MEDIA_ARTICLES"]),
        },
        {
            label: "Запустить общую пересборку",
            onClick: () => rebuildProject(),
        },
        {
            label: "Остановить сборку",
            onClick: () => stopRebuild(),
        },
        {
            label: "Показать логи",
            onClick: () => toggle(),
        },
    ];

    return (
        <TopToolbar>
            {permissions === "admin" && (
                <>
                    {actions.map((item) => (
                        <Button
                            onClick={item.onClick}
                            label={item.label}
                            color="primary"
                            variant="outlined"
                            style={{ marginLeft: "24px" }}
                        />
                    ))}
                    <ModalLogs state={state} toggle={toggle} />
                </>
            )}
        </TopToolbar>
    );
};
