import React, { useState } from "react";
import {
    TabbedForm,
    Create,
    TextInput,
    required,
    FormTab,
    SelectInput,
    CheckboxGroupInput,
    ArrayInput,
    SimpleFormIterator,
    NumberInput,
    BooleanInput,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
// import { ColorInput } from "react-admin-color-input";
import { get } from "lodash";

import InputS3Uploader from "../../../Form/InputS3Uploader";

export function CreateView(props) {
    const blocks = {
        BannerImg: {
            fontSetting: {
                devices: (
                    <ArrayInput
                        source="params.media"
                        label="Устройства"
                        style={{ width: "100%" }}
                    >
                        <SimpleFormIterator>
                            <NumberInput
                                source="breakpoint"
                                label="Ширина экрана: (px)"
                                style={{ width: "100%" }}
                                resettable
                            />
                            <NumberInput
                                source={"bannerHeight"}
                                label={"Высота баннера: (px)"}
                                style={{ width: "100%" }}
                                resettable
                            />
                        </SimpleFormIterator>
                    </ArrayInput>
                ),
            },
        },
        BannerQuote: {
            quote: (
                <>
                    <TextInput
                        source="params.text"
                        label="Цитата"
                        style={{ width: "100%" }}
                        multiline
                    />
                    <TextInput
                        source="params.author"
                        label="Автор"
                        style={{ width: "100%" }}
                        multiline
                    />
                </>
            ),
            personImg: (
                <>
                    <InputS3Uploader
                        source={"params.serafim_image"}
                        label={"Изображение персоны"}
                        width={"30%"}
                    />
                </>
            ),
            fontSetting: {
                mobile: (
                    <>
                        <TextInput
                            source={"params.textColor"}
                            label={"Цвет текста"}
                        />
                        <NumberInput
                            source="params.textSize"
                            label="Размер шрифта цитаты: (px)"
                            style={{ width: "100%" }}
                            resettable
                        />
                        <NumberInput
                            source="params.authorTextSize"
                            label="Размер шрифта автора: (px)"
                            style={{ width: "100%" }}
                        />
                    </>
                ),
                devices: (
                    <ArrayInput
                        source="params.media"
                        label="Устройства"
                        style={{ width: "100%" }}
                    >
                        <SimpleFormIterator>
                            <NumberInput
                                source="breakpoint"
                                label="Ширина экрана: (px)"
                                style={{ width: "100%" }}
                                resettable
                            />
                            <NumberInput
                                source={"bannerHeight"}
                                label={"Высота баннера: (px)"}
                                style={{ width: "100%" }}
                                resettable
                            />
                            <TextInput
                                source={"textColor"}
                                label={"Цвет текста"}
                            />
                            <NumberInput
                                source="textSize"
                                label="Размер шрифта цитаты: (px)"
                                style={{ width: "100%" }}
                                resettable
                            />
                            <NumberInput
                                source="authorTextSize"
                                label="Размер шрифта автора: (px)"
                                style={{ width: "100%" }}
                            />
                        </SimpleFormIterator>
                    </ArrayInput>
                ),
            },
        },
        CombinedBanner: {
            items: (
                <>
                    <ArrayInput
                        source="params.items"
                        label="Иконки"
                        style={{ width: "100%" }}
                    >
                        <SimpleFormIterator>
                            <TextInput
                                source="link"
                                label="Ссылка"
                                style={{ width: "100%" }}
                            />
                            <TextInput
                                source="title"
                                label="Заголовок"
                                style={{ width: "100%" }}
                            />
                            <InputS3Uploader
                                source={"image"}
                                label={"Изображение"}
                            />
                        </SimpleFormIterator>
                    </ArrayInput>
                </>
            ),
            fontSetting: {
                mobile: (
                    <>
                        <TextInput
                            source={"params.textColor"}
                            label={"Цвет текста"}
                        />
                        <NumberInput
                            source="params.textSize"
                            label="Размер шрифта: (px)"
                            style={{ width: "100%" }}
                            resettable
                        />
                    </>
                ),
                devices: (
                    <ArrayInput
                        source="params.media"
                        label="Устройства"
                        style={{ width: "100%" }}
                    >
                        <SimpleFormIterator>
                            <NumberInput
                                source="breakpoint"
                                label="Ширина экрана: (px)"
                                style={{ width: "100%" }}
                                resettable
                            />
                            <NumberInput
                                source={"bannerHeight"}
                                label={"Высота баннера: (px)"}
                                style={{ width: "100%" }}
                                resettable
                            />
                            <TextInput
                                source={"textColor"}
                                label={"Цвет текста"}
                            />
                            <NumberInput
                                source="textSize"
                                label="Размер шрифта: (px)"
                                style={{ width: "100%" }}
                                resettable
                            />
                        </SimpleFormIterator>
                    </ArrayInput>
                ),
            },
        },
    };

    const [block, setBlock] = useState(blocks["BannerQuote"]);

    function handleSelect(value) {
        setBlock(blocks[value]);
    }

    return (
        <Create {...props}>
            <TabbedForm>
                <FormTab label={"Основное"} {...props}>
                    <div style={{ width: "100%" }}>
                        <Grid container>
                            <Grid
                                item
                                xs={8}
                                style={{
                                    paddingRight: 24,
                                    borderRight:
                                        "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                            >
                                <TextInput
                                    source={"title"}
                                    label={"Наименование баннера"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <BooleanInput
                                    label="Активный"
                                    source="params.enabled"
                                />
                                <CheckboxGroupInput
                                    source={"params.targeting_keys"}
                                    label={"Типы таргетингов"}
                                    choices={[
                                        { id: "default", name: "По умолчанию" },
                                        { id: "tourist", name: "Турист" },
                                        { id: "palomnik", name: "Паломник" },
                                        { id: "trudnik", name: "Трудник" },
                                    ]}
                                />
                                <SelectInput
                                    source={"params.type"}
                                    label={"Тип баннера"}
                                    defaultValue={"BannerQuote"}
                                    style={{ width: "100%" }}
                                    choices={[
                                        {
                                            id: "BannerImg",
                                            name: "По умолчанию",
                                        },
                                        {
                                            id: "BannerQuote",
                                            name: "Цитатник",
                                        },
                                        {
                                            id: "CombinedBanner",
                                            name: "Составной баннер",
                                        },
                                    ]}
                                    onChange={(e, value) => handleSelect(value)}
                                />
                                <TextInput
                                    source={"link"}
                                    label={"Ссылка на страницу"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <TextInput
                                    source={"params.locations"}
                                    label={"Места вывода"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <TextInput
                                    source={"params.bgColor"}
                                    label={"Фоновый цвет"}
                                />
                                {get(block, "quote", "")}
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                style={{
                                    paddingLeft: 24,
                                    marginBottom: 24,
                                }}
                            >
                                <InputS3Uploader
                                    source={"params.image"}
                                    label={"Фоновое изображение (1920x270)"}
                                />
                                <InputS3Uploader
                                    source={"params.image_tablet"}
                                    label={
                                        "Фоновое изображение для планшета (1152x255)"
                                    }
                                />
                                <InputS3Uploader
                                    source={"params.mobile_image"}
                                    label={
                                        "Фоновое изображение мобильной версии (510x255)"
                                    }
                                />
                                {get(block, "personImg", "")}
                                {get(block, "items", "")}
                            </Grid>
                        </Grid>
                    </div>
                </FormTab>
                <FormTab label={"Настройки отображения"} {...props}>
                    <BooleanInput
                        label="Показывать рамку"
                        source="params.border.enabled"
                    />
                    <TextInput
                        source={"params.border.color"}
                        label={"Цвет рамки"}
                    />
                    <NumberInput
                        source={"params.bannerNaturalHeight"}
                        label={"Натуральная высота баннера:"}
                        style={{ width: "100%" }}
                        resettable
                    />
                    <NumberInput
                        source={"params.bannerHeight"}
                        label={"Высота баннера: (px)"}
                        style={{ width: "100%" }}
                        resettable
                    />
                    {get(block, "fontSetting.mobile", "")}
                    {get(block, "fontSetting.devices", "")}
                </FormTab>
            </TabbedForm>
        </Create>
    );
}
