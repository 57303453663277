import React, { useState, useEffect } from "react";
import {
    CircularProgress,
    FormControl,
    InputAdornment,
    Input,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
} from "@material-ui/core";
import { Search, Close } from "@material-ui/icons";
import { useDebounceFn } from "@umijs/hooks";
import { get } from "lodash";
import { withRouter } from "react-router";

import { search } from "../../../utils";

export default withRouter(function SearchPanel({ history, location }) {
    const [value, setValue] = useState("");
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);

    const typesMap = {
        banners: "Баннер",
        categories: "Категория новости",
        v_portal_admin_cities: "Город",
        votings: "Голосование",
        regions: "Регион",
        virtual_tours: "Виртуальный тур",
        monasteries: "Монастырь",
        cathedrals: "Собор",
        temples: "Храм",
        churches: "Церковь",
        belltowers: "Колокольня",
        wells: "Источник",
        chapels: "Часовня",
        shrines: "Святыня",
        tours: "Тур",
        hermitages: "Скит",
        v_media_audios: "Аудио",
        v_media_audio_guides: "Аудио экскурсия",
        audio_categories: "Категория аудиогида",
        media_photos: "Фото",
        persons: "Персона",
        authors: "Автор",
        media_periodics: "Журнал",
        books: "Книга",
        media_books: "Библиотека",
        museums: "Музей",
        city_objects: "Городской объект",
        text_pages: "Текстовая страница",
        crowdfunding_projects: "Краундфандинг проект",
        radio: "Радио",
        mail_templates: "Шаблоны email",
        targetings: "Таргетинг",
        serafim_projects: "Серафимовские проекты",
        events: "Богослужения",
        media_video_categories: "Категория видео",
        posts: "Пост",
        saint_books: "Священная книга",
        menus: "Меню",
        broadcasts: "Трансляция",
        architectural_styles: "Архитектурный стиль",
        v_media_videos: "Серафимовское видео",
        v_tv_obraz_videos: "Видео тв образа",
        media_articles: "Статья",
        news: "Новость",
        calendar: "Календарь",
    };

    function navigate(path) {
        if (path) {
            history.push(path);
        } else {
            console.warn("Path not found");
        }
    }

    function reset() {
        setResults([]);
        setLoading(false);
        setValue("");
    }

    useDebounceFn(
        () => {
            search(value, (data) => {
                setResults(get(data, "data.adminSearcheableContents", []));
                setLoading(false);
            });
        },
        [value],
        500
    );

    useEffect(() => {
        reset();
    }, [location]);

    return (
        <div
            className={"search"}
            style={{
                marginLeft: "auto",
                position: "relative",
            }}
        >
            <FormControl>
                <Input
                    id="input-with-icon-adornment"
                    placeholder={"Поиск"}
                    style={{
                        borderColor: "#fff",
                        color: "#fff",
                    }}
                    onChange={(event) => {
                        setLoading(true);
                        setValue(event.target.value);
                    }}
                    value={value}
                    startAdornment={
                        <>
                            <InputAdornment position="start">
                                {!loading ? (
                                    <Search style={{ color: "#fff" }} />
                                ) : (
                                    <CircularProgress
                                        size={18}
                                        style={{ color: "#fff" }}
                                    />
                                )}
                            </InputAdornment>
                            <IconButton
                                onClick={reset}
                                style={{
                                    color: "#fff",
                                    position: "absolute",
                                    right: 0,
                                    top: 0,
                                    bottom: 0,
                                    margin: "auto",
                                }}
                            >
                                <Close />
                            </IconButton>
                        </>
                    }
                />
            </FormControl>
            {!!value && (
                <>
                    <div
                        className={"search__overlay"}
                        style={{
                            position: "fixed",
                            width: "100vw",
                            height: "100vh",
                            left: 0,
                            top: 0,
                        }}
                        onClick={reset}
                    />
                    <div
                        className={"search__results"}
                        style={{
                            position: "absolute",
                            top: "140%",
                            right: 0,
                            minWidth: "60vw",
                            maxHeight: "85vh",
                            overflow: "scroll",
                            backgroundColor: "#fff",
                            boxShadow: "rgb(221, 221, 221) 0px 0px 10px 5px",
                            borderRadius: 4,
                        }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align={"left"}>id</TableCell>
                                    <TableCell align={"left"}>
                                        Тип контента
                                    </TableCell>
                                    <TableCell align={"left"}>
                                        Заголовок
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            {!loading && results && results.length ? (
                                <TableBody>
                                    {results.map(
                                        ({ id, pageTitleShort, type }, idx) => {
                                            return (
                                                <TableRow
                                                    key={`${pageTitleShort}-${idx}`}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                        navigate(
                                                            `/${type === "calendar" ? "days" : type}/${id}/show`
                                                        )
                                                    }
                                                >
                                                    <TableCell align={"left"}>
                                                        {id}
                                                    </TableCell>
                                                    <TableCell align={"left"}>
                                                        {typesMap[type]}
                                                    </TableCell>
                                                    <TableCell align={"left"}>
                                                        {pageTitleShort}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        }
                                    )}
                                </TableBody>
                            ) : (
                                <div
                                    style={{
                                        padding: "16px 0",
                                        textAlign: "center",
                                        color: "rgba(0, 0, 0, 0.87)",
                                    }}
                                >
                                    {loading ? (
                                        <CircularProgress size={18} />
                                    ) : (
                                        "Поиск не дал результатов"
                                    )}
                                </div>
                            )}
                        </Table>
                    </div>
                </>
            )}
        </div>
    );
});
