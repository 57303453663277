import React from "react";

import CheckPermissions from "../../../../hoc/CheckPermissions";
import {
    List,
    Datagrid,
    EditButton,
    TextField,
    BooleanField,
    usePermissions,
} from "react-admin";

function UnmappedListView(props) {
    const { loaded, permissions: role } = usePermissions();

    return (
        loaded && (
            <List {...props} filter={{ type: "main" }}>
                <Datagrid
                    rowClick="show"
                    sort={{ field: "sort_order", order: "ASC" }}
                    isRowSelectable={() => role === "admin"}
                >
                    <TextField source="id" label={"Id"} />
                    <TextField source="page_title_full" label={"Название"} />
                    <BooleanField
                        source="settings.banners.enabled"
                        label={" Автовоспроизведение баннера"}
                    />
                    <EditButton />
                </Datagrid>
            </List>
        )
    );
}

const ListView = CheckPermissions(UnmappedListView, "city");

export { ListView };
