import React from "react";
import { Edit, TextInput, required, SimpleForm } from "react-admin";
import Grid from "@material-ui/core/Grid";

export function EditView(props) {
    const PostTitle = ({ record }) => {
        return <span>Редактируется {record ? `"${record.name}"` : ""}</span>;
    };

    return (
        <Edit title={<PostTitle />} {...props}>
            <SimpleForm>
                <div style={{ width: "100%" }}>
                    <Grid container>
                        <Grid
                            item
                            xs={8}
                            style={{
                                paddingRight: 24,
                                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                            }}
                        >
                            <TextInput
                                source={"text"}
                                label={"Слово"}
                                style={{ width: "100%" }}
                                validate={[required()]}
                                resettable
                            />
                        </Grid>
                    </Grid>
                </div>
            </SimpleForm>
        </Edit>
    );
}
