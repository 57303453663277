import React from "react";
import {
	TabbedForm,
	Create,
	TextInput,
	required,
	FormTab
} from "react-admin";
import Grid from "@material-ui/core/Grid";

import { SEO, Content } from "../../../Layout/Tabs/Edit";

export function CreateView(props) {
	return (
		<Create {...props}>
			<TabbedForm>
				<FormTab label={"Основное"} {...props}>
					<div style={{ width: "100%" }}>
						<Grid container>
							<Grid item xs={8} style={{ paddingRight: 24, borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
								<TextInput
									source={"title"}
									label={"Заголовок"}
									style={{ width: "100%" }}
									validate={[required()]}
									resettable
								/>
								<TextInput
									source={"description"}
									label={"Описание"}
									style={{ width: "100%" }}
									resettable
									multiline
								/>
							</Grid>
						</Grid>
					</div>
				</FormTab>
				<SEO />
				<Content />
			</TabbedForm>
		</Create>
	);
}
