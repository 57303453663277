import React from "react";
import {TabbedForm, Edit, TextInput, required, FormTab, NumberInput} from "react-admin";
import Grid from "@material-ui/core/Grid";

import { SEO, Content } from "../../../Layout/Tabs/Edit";
import InputS3Uploader from "../../../Form/InputS3Uploader";
import withPermissions from "../../../../hoc/CheckPermissions";

function UnmappedEditView(props) {
    const PostTitle = ({ record }) => {
        return (
            <span>Редактируется {record ? `"${record.title_short}"` : ""}</span>
        );
    };

    const { permissions } = props;
    const { edit: { main } = { main: true }, edit } = permissions;
    const showMainTab = (typeof edit === "boolean" && edit) || main;

    return (
        <Edit title={<PostTitle />} {...props}>
            <TabbedForm>
                {showMainTab && (
                    <FormTab label={"Основное"} {...props}>
                        <div style={{ width: "100%" }}>
                            <Grid container>
                                <Grid
                                    item
                                    xs={8}
                                    style={{
                                        paddingRight: 24,
                                        borderRight:
                                            "1px solid rgba(0, 0, 0, 0.12)",
                                    }}
                                >
                                    <TextInput
                                        source={"title_full"}
                                        label={"Наименование"}
                                        style={{ width: "100%" }}
                                        validate={[required()]}
                                        resettable
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    style={{
                                        paddingLeft: 24,
                                        marginBottom: 24,
                                    }}
                                >
                                    <TextInput
                                        source={"slug"}
                                        label={"Слаг"}
                                        style={{ width: "100%" }}
                                        validate={[required()]}
                                        resettable
                                    />
                                    <NumberInput
                                        source={"sort_order"}
                                        label={"Сортировка"}
                                        style={{ width: "100%" }}
                                        resettable
                                    />
                                    <InputS3Uploader
                                        source={"main_image"}
                                        label={"Главное изображение"}
                                        validate={[required()]}
                                        options={
                                            {
                                                // TODO: generate category path s3path: `uploads-test/${category}`,
                                            }
                                        }
                                    />
                                    <InputS3Uploader
                                        source={"main_image_mobile"}
                                        label={
                                            "Главное изображение для мобильной версии"
                                        }
                                    />
                                    <InputS3Uploader
                                        source={"main_image_preview"}
                                        label={"Превью изображение"}
                                        options={
                                            {
                                                // TODO: generate category path s3path: `uploads-test/${category}`,
                                            }
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </FormTab>
                )}
                <SEO permissions={permissions} />
                <Content permissions={permissions} />
            </TabbedForm>
        </Edit>
    );
}

const EditView = withPermissions(UnmappedEditView, "tv_obraz");

export { EditView };
