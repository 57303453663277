import React from "react";
import { Show, TabbedShowLayout } from "react-admin";
import { Main, SEO, Content } from "../../../Layout/Tabs/Show";

export function ShowView(props) {
    const PostTitle = ({ record }) => {
        return <span>Просмотр {record ? `"${record.title_full}"` : ""}</span>;
    };

    return (
        <Show title={<PostTitle />} {...props}>
            <TabbedShowLayout>
                <Main />
                <SEO />
                <Content />
            </TabbedShowLayout>
        </Show>
    );
}
