import React from "react";
import { TabbedForm, Create, TextInput, NumberInput, required, FormTab } from "react-admin";
import Grid from "@material-ui/core/Grid";
import RichText from "../../../Form/RichText";

import { SEO, Content, Location } from "../../../Layout/Tabs/Edit";
import InputS3Uploader from "../../../Form/InputS3Uploader";

export function CreateView(props) {
    return (
        <Create {...props}>
            <TabbedForm>
                <FormTab label={"Основное"} {...props}>
                    <div style={{ width: "100%" }}>
                        <Grid container>
                            <Grid
                                item
                                xs={8}
                                style={{
                                    paddingRight: 24,
                                    borderRight:
                                        "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                            >
                                <TextInput
                                    source={"title_full"}
                                    label={"Наименование"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <TextInput
                                    source={"title_short"}
                                    label={"Краткое наименование"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <TextInput
                                    source={"page_title_full"}
                                    label={"Наименование страницы"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <TextInput
                                    source={"page_title_short"}
                                    label={"Краткое наименование страницы"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <TextInput
                                    source={"src"}
                                    label={"Источник"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <TextInput
                                    source={"settings.mainPageDescription"}
                                    label={"Заголовок для главной"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <RichText
                                    source={"settings.srcDescription"}
                                    label={"Описание источника"}
                                    style={{ width: "100%" }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                style={{ paddingLeft: 24, marginBottom: 24 }}
                            >
                                <TextInput
                                    source={"slug"}
                                    label={"Слаг"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <NumberInput
                                    source={"sort_order"}
                                    label={"Сортировка"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <InputS3Uploader
                                    source={"main_image"}
                                    label={"Главное изображение"}
                                    validate={[required()]}
                                />
                                <InputS3Uploader
                                    source={"main_image_mobile"}
                                    label={
                                        "Главное изображение для мобильной версии"
                                    }
                                />
                                <InputS3Uploader
                                    source={"main_image_preview"}
                                    label={"Превью главного изображения"}
                                />
                                <InputS3Uploader
                                    source={"preview"}
                                    label={"Превью изображение"}
                                />
                                <InputS3Uploader
                                    source={"preview_mobile"}
                                    label={"Превью изображение для мобильной версии"}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </FormTab>
                <Location />
                <SEO />
                <Content />
            </TabbedForm>
        </Create>
    );
}
