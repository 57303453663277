import React, { Component } from "react";
import ReactS3Uploader from "react-s3-uploader";
import { addField } from "ra-core";
import { Button, CircularProgress } from "@material-ui/core";
import { Image, DeleteForever } from "@material-ui/icons";
import uuid from "uuid";
import { get } from "lodash";

import { REACT_APP_S3_API } from "../../../config";

class InputS3FileUploader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: `input-s3-${uuid()}`,
            file: null,
            uploading: false,
        };
        this.input = React.createRef();

        this.handleFinish = this.handleFinish.bind(this);
        this.handleProgress = this.handleProgress.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        const file = get(this.props, "input.value", null);

        this.setState({
            file,
        });
    }

    handleFinish(result) {
        const { onChange = () => {} } = get(this.props, "input", {});
        const file = { src: result.signedUrl.split("?")[0] };

        this.setState({ file }, () => onChange(file));
    }

    handleProgress(state) {
        this.setState({
            uploading: !state,
        });
        // , () => this.input.current.myUploader.fileElement = "" TODO: need to clear value
    }

    handleDelete() {
        const { onChange = () => {} } = get(this.props, "input", {});

        this.setState(
            {
                file: null,
            },
            () => onChange({ src: "" })
        );
    }

    render() {
        const { label = "Загрузить файл", options = {} } = this.props;
        const uploadOptions = Object.assign(
            {},
            {
                signingUrlMethod: "GET",
                accept: "*/*",
                uploadRequestHeaders: {
                    "x-amz-acl": "public-read",
                    "Cache-Control": "max-age=31536000",
                },
                signingUrlWithCredentials: false,
                signingUrlQueryParams: {
                    kind: "serafim-uploads",
                    path: "content",
                },
                autoUpload: true,
                multiple: false,
                signingUrl: REACT_APP_S3_API,
                onFinish: this.handleFinish,
                onProgress: this.handleProgress,
                ref: this.input,
            },
            options
        );

        return (
            <div className={"uploader"} style={{ width: "100%" }}>
                <h3>{label}</h3>
                <div className={"uploader__input"}>
                    <div
                        style={{
                            position: "relative",
                            marginBottom: "1rem",
                        }}
                    >
                        <Button
                            variant={"contained"}
                            color={"primary"}
                            style={{
                                width: "100%",
                                marginBottom: "1rem",
                            }}
                            disabled={this.state.uploading}
                        >
                            <label
                                htmlFor={this.state.id}
                                style={{
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",
                                    left: 0,
                                    top: 0,
                                    display: "block",
                                    cursor: "pointer",
                                }}
                            />
                            {!this.state.uploading && (
                                <Image style={{ marginRight: ".5rem" }} />
                            )}
                            {this.state.uploading
                                ? "Загрузка"
                                : "Загрузить файл"}
                        </Button>
                        {get(this.state, "file.src", null) && (
                            <Button
                                variant={"contained"}
                                color={"default"}
                                style={{
                                    width: "100%",
                                }}
                                onClick={this.handleDelete}
                            >
                                <DeleteForever
                                    style={{ marginRight: ".5rem" }}
                                />
                                Удалить файл
                            </Button>
                        )}
                        {this.state.uploading && (
                            <CircularProgress
                                size={24}
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: -12,
                                    marginLeft: -12,
                                }}
                            />
                        )}
                    </div>
                    <ReactS3Uploader
                        id={this.state.id}
                        style={{ display: "none" }}
                        {...uploadOptions}
                    />
                </div>
                {this.state.file && (
                    <div className={"uploader__file"}>
                        {this.state.file.src}
                    </div>
                )}
            </div>
        );
    }
}

export default addField(InputS3FileUploader);
