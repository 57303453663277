import React from "react";
import {
    Show,
    TextField,
    TabbedShowLayout,
    ReferenceField,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
    Button,
} from "react-admin";
import { Link } from "react-router-dom";

import {
    SEO,
    Content,
    Main,
    Location,
    Connections,
} from "../../../Layout/Tabs/Show";
import AddIcon from "@material-ui/icons/Add";

export function ShowView(props) {
    const PostTitle = ({ record }) => {
        return <span>Просмотр {record ? `"${record.title_short}"` : ""}</span>;
    };

    const AddButton = ({ redirect_to, record, table, label }) => (
        <Button
            variant="raised"
            component={Link}
            to={{
                pathname: `/${table}/create`,
                state: {
                    record: { [resourses_ids[props.resource]]: record.id },
                    redirect_to,
                },
            }}
            label={label}
            title={label}
        >
            <AddIcon />
        </Button>
    );

    const resourses_ids = {
        museums: "museum_id",
        city_objects: "city_object_id",
    };

    return (
        <Show title={<PostTitle />} {...props}>
            <TabbedShowLayout>
                <Main>
                    <ReferenceField
                        source={"region_id"}
                        reference={"regions"}
                        label={"Регион"}
                    >
                        <TextField source="title_full" />
                    </ReferenceField>
                    <ReferenceField
                        source={"city_id"}
                        reference={"v_portal_admin_cities"}
                        label={"Город"}
                    >
                        <TextField source="title_full" />
                    </ReferenceField>
                </Main>
                <Location />
                <SEO />
                <Content />
                <Connections>
                    <Connections>
                        <ReferenceManyField
                            label="Пределы"
                            reference={`${props.resource}_saints`}
                            target={resourses_ids[props.resource]}
                            sort={{ field: "id", order: "ASC" }}
                        >
                            <SingleFieldList>
                                <ReferenceField
                                    source="saint_id"
                                    reference="saints"
                                    link={false}
                                >
                                    <ChipField source="title" />
                                </ReferenceField>
                            </SingleFieldList>
                        </ReferenceManyField>
                        <AddButton
                            redirect_to={props.location.pathname}
                            table={`${props.resource}_saints`}
                            label={"Прикрепить к пределу"}
                        />

                        <ReferenceManyField
                            label="Праздники"
                            reference={`${props.resource}_holidays`}
                            target={resourses_ids[props.resource]}
                            sort={{ field: "id", order: "ASC" }}
                        >
                            <SingleFieldList>
                                <ReferenceField
                                    source="holiday_id"
                                    reference="holidays"
                                    link={false}
                                >
                                    <ChipField source="title_full" />
                                </ReferenceField>
                            </SingleFieldList>
                        </ReferenceManyField>
                        <AddButton
                            redirect_to={props.location.pathname}
                            table={`${props.resource}_holidays`}
                            label={"Прикрепить к празднику"}
                        />
                    </Connections>
                </Connections>
            </TabbedShowLayout>
        </Show>
    );
}
