import React from "react";
import { List, Datagrid, TextField, EditButton } from "react-admin";

import CheckPermissions from "../../../../hoc/CheckPermissions";

function UnmappedListView(props) {
    return (
        <List {...props}>
            <Datagrid
                rowClick="show"
                sort={{ field: "sort_order", order: "ASC" }}
            >
                <TextField source="text" label={"Слова"} />
                <EditButton />
            </Datagrid>
        </List>
    );
}

const ListView = CheckPermissions(UnmappedListView, "stop_words");

export { ListView };
