import React from "react";
import { Show, TabbedShowLayout, Tab, TextField, DateField } from "react-admin";

export function ShowView(props) {
    const PostTitle = ({ record }) => {
        return <span>Просмотр {record ? `"${record.resource_id}"` : ""}</span>;
    };

    return (
        <Show title={<PostTitle />} {...props}>
            <TabbedShowLayout>
                <Tab label="Основное" {...props}>
                    <TextField source="user" label={"Пользователь"} />
                    <DateField
                        source="created_at"
                        label={"Дата/время действия"}
                    />
                    <TextField source="actionCyrillic" label={"Действие"} />
                    <TextField source="resource_table" label={"Таблица"} />
                    <TextField source="resource_id" label="ID-записи" />

                    <TextField
                        source="prev_value"
                        label={"Предыдущее значение"}
                    />
                    <TextField
                        source="current_value"
                        label={"Текущее значение"}
                    />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
}
