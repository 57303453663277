import React from "react";
import {
    List,
    Datagrid,
    TextField,
    ImageField,
    EditButton,
    usePermissions,
} from "react-admin";

import CheckPermissions from "../../../../hoc/CheckPermissions";

function UnmappedListView(props) {
    const { loaded, permissions: role } = usePermissions();

    return (
        loaded && (
            <List {...props}>
                <Datagrid
                    rowClick="show"
                    sort={{ field: "sort_order", order: "ASC" }}
                    isRowSelectable={() => role === "admin"}
                >
                    <TextField source="id" label={"Id"} />
                    <TextField source="page_title_full" label={"Название"} />
                    <ImageField
                        source={"main_image.src"}
                        label={"Главное изображение"}
                        className={"image-cell"}
                    />
                    <EditButton />
                </Datagrid>
            </List>
        )
    );
}

const ListView = CheckPermissions(UnmappedListView, "city");

export { ListView };
