import React from "react";
import { Show, TabbedShowLayout } from "react-admin";

import { SEO, Content, Main, Location } from "../../../Layout/Tabs/Show";

export function ShowView(props) {
	const PostTitle = ({ record }) => {
		return <span>Просмотр {record ? `"${record.title_full}"` : ""}</span>;
	}

	const Preview = ({ record }) => (
		<a href={`https://xn--80ajkqrey.xn--p1ai/page/index.html?type=pages&url=${record.slug}`} className={"preview"}>
            ПРЕДПРОСМОТР
		</a>
    );

	return (
		<Show title={<PostTitle />} {...props}>
			<TabbedShowLayout>
				<Main>
					<Preview />
				</Main>
				<Location />
				<SEO />
				<Content />
			</TabbedShowLayout>
		</Show>
	)
};
