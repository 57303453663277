import React from "react";
import {
    TabbedForm,
    Create,
    TextInput,
    FormTab,
    required,
} from "react-admin";
import Grid from "@material-ui/core/Grid";

import { SEO } from "../../../Layout/Tabs/Edit";

export function CreateView(props) {

    return (
        <Create {...props} >
            <TabbedForm>
                <FormTab label={"Основное"} {...props}>
                    <div style={{ width: "100%" }}>
                        <Grid container>
                            <Grid
                                item
                                xs={8}
                                style={{
                                    paddingRight: 24,
                                    borderRight:
                                        "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                            >
                                <TextInput
                                    source={"path"}
                                    label={"Слаг страницы"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                            </Grid>
                        </Grid>
                    </div>
                </FormTab>
                <SEO />
            </TabbedForm>
        </Create>
    );
}
