import React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    Filter,
    DateInput,
    Pagination,
} from "react-admin";

import CheckPermissions from "../../../../hoc/CheckPermissions";

function UnmappedListView(props) {
    const PostPagination = (props) => (
        <Pagination
            rowsPerPageOptions={[100, 250, 500, 1000, 1500]}
            {...props}
        />
    );

    const CalendarFilter = (props) => (
        <Filter {...props}>
            <DateInput source="date" lable={"Дата"} />
        </Filter>
    );
    return (
        <List
            {...props}
            filters={<CalendarFilter />}
            sort={{ field: "date", order: "ASC" }}
            perPage={50}
            pagination={<PostPagination />}
        >
            <Datagrid rowClick="show">
                <TextField source="id" />
                <DateField source="date" label="Дата" />
                <TextField source="title" label={"Название"} />
                <EditButton />
            </Datagrid>
        </List>
    );
}

const ListView = CheckPermissions(UnmappedListView, "calendar");

export { ListView };
