import React, { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";
import { get } from "lodash";

export default function Rating({ record }) {
    const dataProvider = useDataProvider();

    const [average, setAverage] = useState(0);
    const id = get(record, "id", null);

    useEffect(() => {
        dataProvider
            .getManyReference("news_ratings", {
                target: "news_id",
                id,
                pagination: { perPage: Infinity },
                sort: { field: "id", order: "desc" },
            })
            .then(({ data }) => {
                setAverage(
                    data.length
                        ? data
                              .map((item) => item.rating)
                              .reduce((a, b) => a + b, 0) / data.length
                        : 0
                );
            })
            .catch((ratingError) =>
                console.log("Rating load error", { ratingError })
            );
    }, [dataProvider, id]);

    return (
        average && (
            <span className={"MuiTypography-root-82 MuiTypography-body2-83"}>
                {average}
            </span>
        )
    );
}
