import React from "react";

import List from "../../../Layout/List";
import CheckPermissions from "../../../../hoc/CheckPermissions";

function UnmappedListView(props) {
    return (
        <List {...props} sort={{ field: "created_at", order: "DESC" }}/>
    );
}

const ListView = CheckPermissions(UnmappedListView, "page_settings");

export { ListView };
