import React from "react";
import PropTypes from "prop-types";

import Modal from "@material-ui/core/Modal";

import "./modalStyles.scss";

import { ModalBody } from "./modalBody";

export function ModalLogs({ state, toggle }) {
    return (
        <Modal
            open={state}
            onClose={toggle}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <ModalBody toggle={toggle} />
        </Modal>
    );
}

ModalLogs.propTypes = {
    state: PropTypes.bool,
    toggle: PropTypes.func,
};

ModalLogs.defaultProps = {
    state: false,
    toggle: () => Function,
};
