import React from "react";
import {
    ReferenceField,
    TextField,
    List,
    EditButton,
    Datagrid,
} from "react-admin";

// import List from "../../../Layout/List";
import CheckPermissions from "../../../../hoc/CheckPermissions";

function UnmappedListView(props) {
    // window.addEventListener("message", (event) => {
    // 		console.log("data",event.data)
    //
    // });

    return (
        <List {...props}>
            <Datagrid
                rowClick="show"
                sort={{ field: "sort_order", order: "ASC" }}
            >
                <TextField source="position" />
                <ReferenceField
                    source="component_id"
                    reference="components"
                    link={false}
                    label="Тип блока"
                >
                    <TextField source="name" />
                </ReferenceField>
                <EditButton />
            </Datagrid>
        </List>
    );
}

const ListView = CheckPermissions(UnmappedListView, "city");

export { ListView };
