import React from "react";
import PropTypes from "prop-types";

import { PermissionContextProvider } from "../Context";
import permissions from "../permissions";

export default function PermissionProvider({ children }) {
    const role = localStorage.getItem("permission");
    const current_permissions = permissions[role];

    return (
        <PermissionContextProvider
            value={{
                permissions,
                role,
                current_permissions,
            }}
        >
            {children}
        </PermissionContextProvider>
    );
}

PermissionProvider.propTypes = {
    children: PropTypes.object,
};

PermissionProvider.defaultProps = {
    children: {},
};
