import React from "react";

import CheckPermissions from "../../../../hoc/CheckPermissions";
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    DeleteButton,
    ImageField,
} from "react-admin";

import ApproveButton from "../../../Form/Button/Approve";

function UnmappedListView(props) {
    const { basePath, data } = props;

    return (
        <List sort={{ field: "id", order: "DESC" }} {...props}>
            <Datagrid rowClick="show">
                {props.children}
                <TextField source="id" label={"id"} />
                <TextField source="kind" label={"Тип"} />
                <TextField source="data.title" label={"Название"} />
                <TextField source={"data.place"} label={"Расположение"} />
                <TextField source={"path"} label={"Ссылка до объекта"} />
                <ImageField
                    source="main_image.src"
                    label="Главное изображение"
                />
                <TextField
                    source={"data.visible_in_site"}
                    label={"Видимость в общем списке"}
                />
                <ApproveButton basePath={basePath} record={data} />}
                <EditButton />
                <DeleteButton />
            </Datagrid>
        </List>
    );
}

const ListView = CheckPermissions(UnmappedListView, "city");

export { ListView };
