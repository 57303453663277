import React from "react";
import { List, TextField, Datagrid, EditButton, ImageField } from "react-admin";
import { get } from "lodash";

import CheckPermissions from "../../../../hoc/CheckPermissions";
import AgreementActions from "../../../Form/Button/Approve";

function UnmappedListView(props) {
    const { permissions, roles } = props;

    return (
        <List sort={{ field: "id", order: "DESC" }} {...props}>
            <Datagrid rowClick="show">
                <TextField source="id" label={"Id"} />
                <TextField source="title" label={"Название"} />
                <TextField source="link" label={"Ссылка"} />
                <TextField source="params.locations" label={"Места вывода"} />

                <ImageField
                    source={"params.image.src"}
                    label={"Главное изображение"}
                    className={"image-cell"}
                />
                <AgreementActions
                    actions={get(permissions, "actions.agreement", [])}
                    role={roles[0]}
                />
                <EditButton />
            </Datagrid>
        </List>
    );
}

const ListView = CheckPermissions(UnmappedListView, "banners");

export { ListView };
