import React from "react";
import { TabbedForm, Edit, usePermissions } from "react-admin";

import { SEO, Content, Main, Location } from "../../../Layout/Tabs/Edit";
import withPermissions from "../../../../hoc/CheckPermissions";
import { tabListByRole, transformDataByRole } from "../../../../utils";
import { BottomToolbar } from "../../../Layout/Actions";

function UnmappedEditView(props) {
    const { loaded, permissions: role } = usePermissions();

    const PostTitle = ({ record }) => {
        return (
            <span>Редактируется {record ? `"${record.title_full}"` : ""}</span>
        );
    };

    const main = <Main />;
    const location = <Location />;
    const seo = <SEO />;
    const content = <Content />;

    return (
        loaded && (
            <Edit
                title={<PostTitle />}
                transform={(data) => transformDataByRole(role, data)}
                {...props}
            >
                <TabbedForm toolbar={<BottomToolbar role={role} {...props} />}>
                    {tabListByRole({
                        seo,
                        admin: [main, location, seo, content],
                    }).get(role)}
                    {/* <FormTab label={"Атрибуты"} {...props}>
                    <div style={{ width: "100%" }}>
                        <Grid container>
                            <Grid
                                item
                                xs={8}
                                style={{
                                    paddingRight: 24,
                                    borderRight:
                                        "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                            >
                                <TextInput
                                    source={"attributes.name"}
                                    label={"Входит в"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <TextInput
                                    source={"attributes.center"}
                                    label={"Административный центр"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <DateInput
                                    source={"attributes.founded_date"}
                                    label={"Дата основания"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <NumberInput
                                    source={"attributes.area"}
                                    label={"Площадь"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <TextInput
                                    source={"attributes.biggest_cities"}
                                    label={"Крупнейшие города"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <NumberInput
                                    source={"attributes.population"}
                                    label={"Население"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <TextInput
                                    source={"attributes.population_density"}
                                    label={"Плотность населения"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <TextInput
                                    source={"attributes.nationality"}
                                    label={"Национальности"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <InputS3Uploader
                                    source={"attributes.image"}
                                    label={"Изображение"}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </FormTab> */}
                </TabbedForm>
            </Edit>
        )
    );
}

const EditView = withPermissions(UnmappedEditView, "regions");

export { EditView };
