/* eslint-disable */

import React, { useState, useEffect } from "react";
import DropzoneS3Uploader from "react-dropzone-s3-uploader";
import { ReactSortable, Sortable, MultiDrag, Swap } from "react-sortablejs";
import { addField } from "ra-core";
import { Button, TextField } from "@material-ui/core";
import { DeleteForever, PanTool, Sort as SortIcon } from "@material-ui/icons";
import uuid from "uuid";
import { get } from "lodash";
import moment from "moment";
import { useToggle } from '@umijs/hooks';

import { REACT_APP_S3_API } from "../../../config";
import "./inputReactUploader.scss";
import { buttons } from "./config";

Sortable.mount(new MultiDrag(), new Swap());

function InputS3UploaderMulti({ input }) {
    const preparedItem = (typeof get(input, "value", []) === "string"
        ? []
        : get(input, "value", [])
    ).map(({ id, src, title, alt, fileName, size, lastModified, direction = true, sortType = "size" }) => ({
        id: id || uuid(),
        src,
        title,
        alt,
        fileName,
        size,
        lastModified,
        direction,
        sortType,
    }));

    const { state: isMount, toggle } = useToggle();
    const [items, setItems] = useState(preparedItem);

    const direction = get(items, "[0].direction", true);
    const sortType = get(items, "[0].sortType", "size");

    function makeItems(items, { direction = true, sortType = "custom" }) {

       return items.map(({ id, src, title, alt, fileName, size, lastModified }) => ({
           id,
           src,
           title,
           alt,
           fileName,
           size,
           lastModified,
           direction,
           sortType,
       }))
    }

    function sortAsc(valueA, valueB) {
        return valueA > valueB ? 1 : -1;
    }

    function sortDesc(valueA, valueB) {
        return valueA > valueB ? -1 : 1;
    }

    // TODO: Need to fix sorted by empty title
    // TODO: Need to fix sorted by empty filename

    function sortCommon(sortedBy){
        const sortedItems = items.sort((itemA, itemB) => {

            switch (true) {
                case /title/.test(sortedBy) || /fileName/.test(sortedBy):
                    const nameA = `${get(itemA, sortedBy, "")}`.split(".")[0];
                    const nameB = `${get(itemB, sortedBy, "")}`.split(".")[0];

                    if (isNaN(+nameA) && isNaN(+nameB)) {
                        return direction
                            ? sortAsc(nameA, nameB)
                            : sortDesc(nameA, nameB);
                    }

                    return direction
                        ? sortAsc(+nameA, +nameB)
                        : sortDesc(+nameA, +nameB);
                case /lastModified/.test(sortedBy):
                    const dateA = +get(itemA, sortedBy, "");
                    const dateB = +get(itemB, sortedBy, "");

                    return direction
                        ? sortAsc(dateA, dateB)
                        : sortDesc(dateA, dateB);
                default:
                    const sizeA = get(itemA, sortedBy, 0);
                    const sizeB = get(itemB, sortedBy, 0);

                    return direction
                        ? sortAsc(sizeA, sizeB)
                        : sortDesc(sizeA, sizeB);
            }
        });

        setItems(makeItems(sortedItems, { direction: !direction, sortType: sortedBy }));
    }

    //FIle upload finish
    function handleFinish(result) {
        setItems([
            ...items,
            {
                id: uuid(),
                src: result.signedUrl.split("?")[0],
                fileName: get(result, "file.name"),
                size: get(result, "file.size"),
                lastModified: get(result, "file.lastModified"),
                title: "",
                direction,
                sortType,
            },
        ]);
    }

    function inputChange(event, position, field) {
        setItems(
            items.map((item) => {
                if (item.id === position) {
                    return {
                        ...item,
                        [field]: event.target.value,
                    };
                } else {
                    return item;
                }
            })
        );
    }

    function handleDelete(deletableId) {
        const updatedItems = items.filter((item) => item.id !== deletableId);

        setItems(updatedItems);
    }

    useEffect(() => {
        toggle();
    }, []);

    useEffect(() => {
        const { onChange } = input;

        onChange(items);
    }, [items]);

    const uploadOptions = Object.assign(
        {},
        {
            signingUrlMethod: "GET",
            accept: "*/*",
            uploadRequestHeaders: {
                "x-amz-acl": "public-read",
                "Cache-Control": "max-age=31536000",
            },
            signingUrlWithCredentials: false,
            signingUrlQueryParams: {
                kind: "serafim-uploads",
                path: "content",
            },
            autoUpload: true,
            signingUrl: REACT_APP_S3_API,
        }
    );

    return (
        <>
            <DropzoneS3Uploader
                onFinish={handleFinish}
                s3Url={REACT_APP_S3_API}
                maxSize={1024 * 1024 * 5}
                upload={uploadOptions}
                noDrag={true}
            />
            <div style={{ marginBottom: 16, display: "flex" }}>
                {buttons.map((item)=> {
                    const type = get(item, "type", "");
                    const title = get(item, "title", "");
                    const action = get(item, "action", "true");

                    return (
                        <Button
                            key={type}
                            color={"primary"}
                            className={"sort__btn"}
                            onClick={() => action && sortCommon(type)}
                            variant={sortType === type ? "contained" : ""}
                            disabled={type === "custom"}
                            style={{
                                marginRight: 8,
                                flex: 1,
                            }}
                        >
                            {title}
                            {
                                type !== "custom" ?
                                <SortIcon
                                    style={{
                                        transform:
                                            direction && sortType === type
                                                ? "rotateX(0deg)"
                                                : "rotateX(180deg)",
                                        marginLeft: 8,
                                    }}
                                /> :
                                <PanTool
                                    style={{
                                        marginLeft: 8,
                                    }}
                                />
                            }
                        </Button>
                    )
                })}
            </div>
            {items && (
                <ReactSortable
                    list={items}
                    setList={(list) => {
                        if(isMount) {
                            setItems(makeItems(list, {}));
                        }
                    }}
                    className={"droppable"}
                    animation={150}
                >
                    {items.map((item) => {
                        const id = get(item, "id", "");
                        const src = get(item, "src", "");
                        const alt = get(item, "alt", "");
                        const title = get(item, "title", "");
                        const fileName = get(item, "fileName", "");
                        const lastModified = get(item, "lastModified", "");
                        const size = get(item, "size", 0);

                        return (
                            <div key={id} className={"upload-item"}>
                                <img
                                    src={src}
                                    alt={alt}
                                    className={"upload-item__img"}
                                    style={{
                                        marginBottom: 8,
                                    }}
                                />
                                <TextField
                                    type={"text"}
                                    value={moment(
                                        new Date(lastModified)
                                    ).format("DD.MM.YYYY HH:mm")}
                                    label={"Дата изменения файла"}
                                    className={"upload-item__text-input"}
                                    disabled
                                    style={{
                                        marginBottom: 8,
                                    }}
                                />
                                <TextField
                                    type={"text"}
                                    value={fileName}
                                    label={"Имя файла"}
                                    className={"upload-item__text-input"}
                                    disabled
                                    style={{
                                        marginBottom: 8,
                                    }}
                                />
                                <TextField
                                    type={"text"}
                                    value={`${(size / 1000).toFixed(2)} kb`}
                                    label={"Размер файла"}
                                    className={"upload-item__text-input"}
                                    disabled
                                    style={{
                                        marginBottom: 8,
                                    }}
                                />
                                <TextField
                                    type={"text"}
                                    value={alt}
                                    label={"Альтернативный текст"}
                                    className={"upload-item__text-input"}
                                    onChange={(event) =>
                                        inputChange(event, id, "alt")
                                    }
                                    style={{
                                        marginBottom: 8,
                                    }}
                                />
                                <TextField
                                    type={"text"}
                                    value={title}
                                    label={"Подпись к фото"}
                                    className={"upload-item__text-input"}
                                    onChange={(event) =>
                                        inputChange(event, id, "title")
                                    }
                                />
                                <TextField
                                    type={"text"}
                                    value={id}
                                    label={"id изображения"}
                                    className={"upload-item__text-input"}
                                />
                                <Button
                                    variant={"contained"}
                                    color={"default"}
                                    className={"upload-item__btn"}
                                    onClick={() => handleDelete(id)}
                                >
                                    <DeleteForever />
                                </Button>
                            </div>
                        );
                    })}
                </ReactSortable>
            )}
        </>
    );
}

export default addField(InputS3UploaderMulti);
