import { createContext } from "react";

export const permissions = {};
export const role = "";
export const current_permissions = {};

export const PermissionContext = createContext(
	{
		permissions,
		role,
		current_permissions,
		changePermissions:() => Function,
	},
);

PermissionContext.displayName = "PermissionContext";

export const PermissionContextProvider = PermissionContext.Provider;
export const PermissionConsumer = PermissionContext.Consumer;
export default PermissionContext;
