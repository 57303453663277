import React from "react";

import List from "../../../Layout/List";
import CheckPermissions from "../../../../hoc/CheckPermissions";

function UnmappedListView(props) {
    return <List sort={{ field: "id", order: "DESC" }} {...props} />;
}

const ListView = CheckPermissions(UnmappedListView, "city");

export { ListView };
