import React from "react";
import { List, Datagrid, TextField, EditButton, Pagination } from "react-admin";
import { get } from "lodash";

import CheckPermissions from "../../../../hoc/CheckPermissions";
import AgreementActions from "../../../Form/Button/Approve";

function UnmappedListView(props) {
    const { permissions, roles } = props;
    const PostPagination = (props) => (
        <Pagination
            rowsPerPageOptions={[
                10,
                25,
                50,
                100,
                300,
                500,
                700,
                900,
                1100,
                1300,
                1500,
                1700,
            ]}
            {...props}
        />
    );

    return (
        <List {...props} pagination={<PostPagination />}>
            <Datagrid rowClick="show" sort={{ field: "id", order: "ASC" }}>
                <TextField source="id" label={"Id"} />
                <TextField source="begins_at" label={"Дата"} />
                <TextField source="data.holiday" label={"Название праздника"} />
                <TextField source="data.title" label={"Название службы"} />
                <AgreementActions
                    actions={get(permissions, "actions.agreement", [])}
                    role={roles[0]}
                />
                <EditButton />
            </Datagrid>
        </List>
    );
}

const ListView = CheckPermissions(UnmappedListView, "broadcast_schedules");

export { ListView };
