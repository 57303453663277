import React from "react";
import { 
	Show, 
	TabbedShowLayout,
} from "react-admin";

import { SEO, Content, Main } from "../../../Layout/Tabs/Show";

export function ShowView(props) {
	const PostTitle = ({ record }) => {
		return <span>Просмотр {record ? `"${record.title_short}"` : ""}</span>;
	}
	
	return (
		<Show title={<PostTitle />} {...props}>
			<TabbedShowLayout>
				<Main/>>
				<SEO />
				<Content />
			</TabbedShowLayout>
		</Show>
	)
};
