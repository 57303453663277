import React from "react";
import {
    TabbedForm,
    Edit,
    TextInput,
    required,
    SelectInput,
    ReferenceInput,
    usePermissions,
} from "react-admin";

import withPermissions from "../../../../hoc/CheckPermissions";
import { BottomToolbar } from "../../../Layout/Actions/Edit";
import InputS3Uploader from "../../../Form/InputS3Uploader";
import { SEO, Content, Main } from "../../../Layout/Tabs/Edit";
import { tabListByRole, transformDataByRole } from "../../../../utils";

function UnmappedEditView(props) {
    const { loaded, permissions: role } = usePermissions();

    const PostTitle = ({ record }) => {
        return (
            <span>Редактируется {record ? `"${record.title_short}"` : ""}</span>
        );
    };

    const main = (
        <Main>
            <TextInput
                source="type"
                defaultValue={"audio_guides"}
                label="Тип аудио"
                style={{ display: "none" }}
            />
            <TextInput
                multiline
                source={"teaser"}
                label={"Тизер"}
                style={{ width: "100%" }}
                resettable
            />
            <ReferenceInput
                source={"audio_category_id"}
                reference={"audio_categories"}
                label={"Категория"}
                style={{
                    width: "100%",
                }}
            >
                <SelectInput
                    optionText="title"
                    emptyValue={null}
                    validate={[required()]}
                />
            </ReferenceInput>
            <InputS3Uploader
                source={"audio"}
                label={"Аудио"}
                validate={[required()]}
                file
            />
        </Main>
    );
    const seo = <SEO />;
    const content = <Content />;

    return (
        loaded && (
            <Edit
                title={<PostTitle />}
                transform={(data) => transformDataByRole(role, data)}
                {...props}
            >
                <TabbedForm toolbar={<BottomToolbar role={role} {...props} />}>
                    {tabListByRole({
                        seo,
                        admin: [main, seo, content],
                    }).get(role)}
                </TabbedForm>
            </Edit>
        )
    );
}

const EditView = withPermissions(UnmappedEditView, "media_articles");

export { EditView };
