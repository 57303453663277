import * as React from 'react';
import { Form } from 'react-final-form';
import { Box, Button } from '@material-ui/core';
import get from "lodash/get";
import {
    useListContext,
    ReferenceInput,
    SelectInput,
    DateInput,
    BooleanInput
} from 'react-admin';

import "./filter-style.scss";

const PostFilterForm = () => {
    const {
        filterValues,
        setFilters,
        hideFilter
    } = useListContext();

    const onSubmit = (values) => {
        if (Object.keys(values).length > 0) {
            setFilters({
                user_id: values.user_id,
                created_at_date: {
                    _gte: get(values, "created_at_date._gte"),
                    _lte: get(values, "created_at_date._lte")
                },
                resource_table: get(values, "resource_table") ? "broadcasts" : null
            });
        } else {
            hideFilter("main");
        }
    };

    const resetFilter = () => {
        setFilters({}, []);
    };

    return (
        <div>
            <Form onSubmit={onSubmit} initialValues={filterValues}>
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Box display="flex" alignItems="flex-end" mb={1} className={"filter-form"}>
                            <Box component="span" mr={2}>
                                <DateInput source="created_at_date._gte" label={"Дата с"} resettable />
                            </Box>
                            <Box component="span" mr={2}>
                                <DateInput source="created_at_date._lte" label={"Дата по"} resettable />
                            </Box>
                            <Box component="span" mr={2}>
                                <ReferenceInput source="user_id" reference="v_admin_action_users" label={"Администратор"}>
                                    <SelectInput
                                        optionText={(choice) => get(choice, "user_fio", "")}
                                        clearAlwaysVisible
                                    />
                                </ReferenceInput>
                            </Box>
                            <Box component="box" mr={2}>
                                <BooleanInput label="Только логи трансляций" source="resource_table" />
                            </Box>
                            <Box component="span" mr={2} mb={1.5}>
                                <Button variant="outlined" color="primary" type="submit">
                                    Применить
                                </Button>
                            </Box>
                            <Box component="span" mb={1.5}>
                                <Button variant="outlined" onClick={resetFilter}>
                                    Сброс
                                </Button>
                            </Box>
                        </Box>
                    </form>
                )}
            </Form>
        </div>
    );
};

export { PostFilterForm };