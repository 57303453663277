import React from "react";
import { TabbedForm, Create, TextInput } from "react-admin";

import { Main, SEO, Content } from "../../../Layout/Tabs/Edit";

export function CreateView(props) {
    return (
        <Create {...props}>
            <TabbedForm>
                <Main showSortOrder={false}>
                    <TextInput
                        source={"appearance_date"}
                        label={"Дата появления"}
                        style={{ width: "100%" }}
                        resettable
                    />
                </Main>
                <SEO />
                <Content />
            </TabbedForm>
        </Create>
    );
}
