import React from "react";
import { Tab, ImageField, ArrayField, Datagrid, FunctionField } from "react-admin";
import * as Showdown from "showdown";

const converter = new Showdown.Converter({
	tables: true,
	simplifiedAutoLink: true,
	strikethrough: true,
	tasklists: true
});

export function Content(props) {
	return (
		<Tab label="Контент" {...props}>
			<ArrayField source="content_blocks" label={"Блоки контента"} style={{ width: "100%" }}>
				<Datagrid>
					<FunctionField label="Содержимое" render={record => <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(record.body) }} style={{ marginBottom: "1rem" }} />} />
					<ArrayField source="media.items" label={"Медиа"}>
						<Datagrid>
							<ImageField source="src" title="alt" label={"Изображения"} />
						</Datagrid>
					</ArrayField>
				</Datagrid>
			</ArrayField>
		</Tab>
	)
};
