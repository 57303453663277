import React from "react";
import {
    Show,
    TabbedShowLayout,
    Button,
    ReferenceManyField,
    ReferenceField,
    SingleFieldList,
    ChipField,
} from "react-admin";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";

import {
    SEO,
    Content,
    Main,
    Location,
    Connections,
} from "../../../Layout/Tabs/Show";

export function ShowView(props) {
    const PostTitle = ({ record }) => {
        return <span>Просмотр {record ? `"${record.title_full}"` : ""}</span>;
    };

    const AddButton = ({ redirect_to, record, table, label }) => {
        const recordObj = {};
        recordObj[props.resource + "_id"] = record.id;
        return (
            <Button
                variant="raised"
                component={Link}
                to={{
                    pathname: `/${table}_${props.resource}/create`,
                    state: {
                        record: recordObj,
                        redirect_to,
                        resource: props.resource + "_id",
                        table: props.resource,
                    },
                }}
                label={label}
                title={label}
            >
                <AddIcon />
            </Button>
        );
    };

    const AddReferenceButton = AddButton;

    return (
        <Show title={<PostTitle />} {...props}>
            <TabbedShowLayout>
                <Main />
                <Connections>
                    {/* <ReferenceManyField
						label='Города'
						reference={`cities_${props.resource}`}
						target={`${props.resource}_id`}
						sort={{ field: 'id', order: 'ASC' }}
					>
						<SingleFieldList>
							<ReferenceField source="city_id" reference="v_portal_admin_cities" link={false}>
								<ChipField source="title_full" />
							</ReferenceField>
						</SingleFieldList>
					</ReferenceManyField>
					<AddReferenceButton redirect_to={props.location.pathname} table={"v_portal_admin_cities"} label={"Прикрепить к городу"} /> */}

                    <ReferenceManyField
                        label="Монастыри"
                        reference={`monasteries_${props.resource}`}
                        target={`${props.resource}_id`}
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="monastery_id"
                                reference="monasteries"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"monasteries"}
                        label={"Прикрепить к монастырю"}
                    />

                    {/* <ReferenceManyField
						label='Церкви'
						reference={`churches_${props.resource}`}
						target={`${props.resource}_id`}
						sort={{ field: 'id', order: 'ASC' }}
					>
						<SingleFieldList>
							<ReferenceField source="churche_id" reference="churches" link={false}>
								<ChipField source="title_full" />
							</ReferenceField>
						</SingleFieldList>
					</ReferenceManyField> */}
                    {/* <AddReferenceButton redirect_to={props.location.pathname} table={"churches"} label={"Прикрепить к церкви"} /> */}
                </Connections>
                <Location />
                <SEO />
                <Content />
            </TabbedShowLayout>
        </Show>
    );
}
