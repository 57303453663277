import React, { useEffect, useState } from "react";
import { Button, TopToolbar, EditButton } from "react-admin";
import Looks from "@material-ui/icons/Looks";

export default function PostShowActions({ basePath, data }) {
    const [preview, setPreview] = useState(null);
    const [record, setRecord] = useState(data);
    const [tab, setTab] = useState(null);

    function connectedPreview({ data }) {
        const { previewLoaded = false } = data;

        setPreview(previewLoaded);
    }

    function openTab() {
        if (typeof window !== "undefined") {
            setTab(
                window.open(
                    `${process.env.REACT_APP_FRONT_HOST}/preview`,
                    "Превью"
                )
            );
        }
    }

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("message", connectedPreview);
            window.addEventListener("unload", () => {
                if (tab) {
                    tab.close();
                }
            });
        }

        return () => {
            if (typeof window !== "undefined") {
                window.removeEventListener("message", connectedPreview);
            }
        };
    }, [tab]);

    useEffect(() => {
        setRecord(data);
    }, [data]);

    useEffect(() => {
        if (preview && tab) {
            tab.postMessage(record, "*");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preview]);

    return (
        <TopToolbar>
            <Button
                title={"Предпросмотр"}
                label={"Предпросмотр"}
                onClick={() => openTab(data)}
                className={"preview"}
            >
                <Looks />
            </Button>
            <EditButton basePath={basePath} record={data} />
        </TopToolbar>
    );
}
