import React from "react";
import { TabbedForm, Create, FormTab, TextInput, SelectInput, required,
    ArrayInput, SimpleFormIterator, NumberInput, DateInput } from "react-admin";
import Grid from "@material-ui/core/Grid";

import { SEO } from "../../../Layout/Tabs/Edit";

import InputS3Uploader from "../../../../components/Form/InputS3Uploader";
import RichText from "../../../../components/Form/RichText"

export function CreateView(props) {
	return (
        <Create {...props}>
            <TabbedForm>
                <FormTab label={"Основное"} {...props}>
                    <div style={{ width: "100%" }}>
                        <Grid container>
                            <Grid
                                item
                                xs={8}
                                style={{ paddingLeft: 24, marginBottom: 24 }}
                            >
                                <TextInput
                                    source={"data.title"}
                                    label={"Наименование"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <TextInput
                                    source={"data.description"}
                                    label={"Описание"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                    multiline
                                />
                                <TextInput
                                    source={"slug"}
                                    label={"Слаг"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <TextInput
                                    source={"data.place"}
                                    label={"Расположение"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <TextInput
                                    source={"data.tags"}
                                    label={"Категории"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <SelectInput
                                    source={"kind"}
                                    label={"Тип проекта"}
                                    defaultValue={"crowdfunding"}
                                    style={{ width: "100%" }}
                                    choices={[
                                        {
                                            id: "crowdfunding",
                                            name: "Crowdfunding",
                                        },
                                        {
                                            id: "donate",
                                            name: "Donate",
                                        },
                                        {
                                            id: "treb",
                                            name: "Treb",
                                        },
                                    ]}
                                />
                                <NumberInput
                                    source={"data.required_amount"}
                                    label={"Необходимая сумма сбора"}
                                    style={{ width: "100%" }}
                                />
                                <TextInput
                                    source={"data.visible_in_site"}
                                    label={"Видимость в общем списке"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <TextInput
                                    source={"payment_receiver_id"}
                                    label={"Получетель платежа"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <DateInput
                                    source={"started_at"}
                                    label={"Дата начала"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <DateInput
                                    source={"ended_at"}
                                    label={"Дата окончания"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <TextInput
                                    source={"path"}
                                    label={"Ссылка до обьекта"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                style={{ paddingLeft: 24, marginBottom: 24 }}
                            >
                                <InputS3Uploader
                                    source={"main_image"}
                                    label={"Главное изображение"}
                                    validate={[required()]}
                                    options={
                                        {
                                            // TODO: generate category path s3path: `uploads-test/${category}`,
                                        }
                                    }
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                            />
                        </Grid>
                    </div>
                </FormTab>
                <SEO />
                <FormTab label="Контент" {...props}>
                    <div style={{ width: "100%" }}>
                        <Grid container spacing={3}>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    borderBottom:
                                        "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                            >
                                {props.before}
                            </Grid>
                            <Grid item xs={12}>
                                <ArrayInput
                                    source="data.content_blocks"
                                    label="Блоки на странице"
                                    style={{ width: "100%" }}
                                >
                                    <SimpleFormIterator>
                                        <SelectInput
                                            source={"type"}
                                            label={"Тип блока"}
                                            defaultValue={"default"}
                                            style={{ width: "100%" }}
                                            choices={[
                                                {
                                                    id: "default",
                                                    name: "По умолчанию",
                                                },
                                                {
                                                    id: "PhotoSwipe",
                                                    name: "Галерея",
                                                },
                                            ]}
                                        />
                                        <TextInput
                                            source="title"
                                            label="Заголовок блока"
                                            style={{ width: "100%" }}
                                        />
                                        <RichText
                                            source={"body"}
                                            label={"Содержимое блока"}
                                            style={{ width: "100%" }}
                                        />
                                        <SelectInput
                                            source={"media.type"}
                                            label={"Тип контента"}
                                            defaultValue={"default"}
                                            choices={[
                                                {
                                                    id: "default",
                                                    name: "По умолчанию",
                                                },
                                                {
                                                    id: "SlideNews",
                                                    name: "Новость",
                                                },
                                                {
                                                    id: "SlideGallery",
                                                    name: "Элемент галереи",
                                                },
                                                {
                                                    id: "SlideLink",
                                                    name: "Ссылка на ресурс",
                                                },
                                            ]}
                                        />
                                        <ArrayInput
                                            source="media.items"
                                            label="Элемент медиа"
                                        >
                                            <SimpleFormIterator>
                                                <InputS3Uploader
                                                    style={{ width: "100%" }}
                                                />
                                                <TextInput
                                                    source={"alt"}
                                                    label={
                                                        "Альтернативный текст"
                                                    }
                                                />
                                            </SimpleFormIterator>
                                        </ArrayInput>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Grid>
                            <Grid item xs={12}>
                                {props.children}
                            </Grid>
                        </Grid>
                    </div>
                </FormTab>
            </TabbedForm>
        </Create>
    );
}
