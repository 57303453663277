import React from "react";
import {
    Show,
    TabbedShowLayout,
    TextField,
    Tab,
    Button,
    ReferenceManyField,
    SingleFieldList,
    ReferenceField,
    ChipField,
} from "react-admin";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";

import {
    SEO,
    Main,
    Location,
    Connections,
    Content,
} from "../../../Layout/Tabs/Show";

export function ShowView(props) {
    const PostTitle = ({ record }) => {
        return <span>Просмотр {record ? `"${record.title_short}"` : ""}</span>;
    };

    const AddButton = ({ redirect_to, record, table, label }) => {
        return (
            <Button
                variant="raised"
                component={Link}
                to={{
                    pathname: `/${table}/create`,
                    state: {
                        record: { virtual_tour_id: record.id },
                        redirect_to,
                    },
                }}
                label={label}
                title={label}
            >
                <AddIcon />
            </Button>
        );
    };

    const AddReferenceButton = AddButton;

    return (
        <Show title={<PostTitle />} {...props}>
            <TabbedShowLayout>
                <Main />
                <Connections>
                    <ReferenceManyField
                        label="Города"
                        reference="cities_virtual_tours"
                        target="virtual_tour_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="city_id"
                                reference="v_portal_admin_cities"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"cities_virtual_tours"}
                        label="Прикрепить к городу"
                    />

                    <ReferenceManyField
                        label="Монастыри"
                        reference="monasteries_virtual_tours"
                        target="virtual_tour_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="monastery_id"
                                reference="monasteries"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"monasteries_virtual_tours"}
                        label="Прикрепить к монастырю"
                    />

                    <ReferenceManyField
                        label="Церкви"
                        reference="churches_virtual_tours"
                        target="virtual_tour_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="church_id"
                                reference="churches"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"churches_virtual_tours"}
                        label={"Прикрепить к церкви"}
                    />

                    <ReferenceManyField
                        label="Достопримечательности"
                        reference="city_objects_virtual_tours"
                        target="virtual_tour_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="city_object_id"
                                reference="city_objects"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"city_objects_virtual_tours"}
                        label={"Прикрепить к достопримечательности"}
                    />

                    <ReferenceManyField
                        label="Скиты"
                        reference="hermitages_virtual_tours"
                        target="virtual_tour_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="hermitage_id"
                                reference="hermitages"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"hermitages_virtual_tours"}
                        label={"Прикрепить к скиту"}
                    />

                    <ReferenceManyField
                        label="Святыни"
                        reference="shrines_virtual_tours"
                        target="virtual_tour_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="shrine_id"
                                reference="shrines"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"shrines_virtual_tours"}
                        label={"Прикрепить к святыне"}
                    />

                    <ReferenceManyField
                        label="Часовни"
                        reference="chapels_virtual_tours"
                        target="virtual_tour_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="chapel_id"
                                reference="chapels"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"chapels_virtual_tours"}
                        label={"Прикрепить к часовне"}
                    />

                    <ReferenceManyField
                        label="Источники"
                        reference="virtual_tours_wells"
                        target="virtual_tour_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="well_id"
                                reference="wells"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"virtual_tours_wells"}
                        label={"Прикрепить к источнику"}
                    />

                    <ReferenceManyField
                        label="Колокольни"
                        reference="belltowers_virtual_tours"
                        target="virtual_tour_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="belltower_id"
                                reference="belltowers"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"belltowers_virtual_tours"}
                        label={"Прикрепить к колокольне"}
                    />

                    <ReferenceManyField
                        label="Храмы"
                        reference="temples_virtual_tours"
                        target="virtual_tour_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="temple_id"
                                reference="temples"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"temples_virtual_tours"}
                        label={"Прикрепить к храму"}
                    />

                    <ReferenceManyField
                        label="Соборы"
                        reference="cathedrals_virtual_tours"
                        target="virtual_tour_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="cathedral_id"
                                reference="cathedrals"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"cathedrals_virtual_tours"}
                        label={"Прикрепить к собору"}
                    />

                    <ReferenceManyField
                        label="Музеи"
                        reference="museums_virtual_tours"
                        target="virtual_tour_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="museum_id"
                                reference="museums"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"museums_virtual_tours"}
                        label={"Прикрепить к музею"}
                    />
                </Connections>
                <Tab label={"Настройки"}>
                    <TextField
                        source={"settings.params.src"}
                        label={"Источник"}
                        style={{ width: "100%" }}
                    />
                    <TextField
                        source={"settings.params.base_path"}
                        label={"Основной урл"}
                        style={{ width: "100%" }}
                    />
                    <TextField
                        source={"settings.params.init_hotspot"}
                        label={"Начальное состояние"}
                        style={{ width: "100%" }}
                    />
                    <TextField
                        source={"settings.component"}
                        label={"Компонент"}
                        style={{ width: "100%" }}
                        defaultValue={"TourBlock"}
                    />
                </Tab>
                <Location />
                <SEO />
                <Content />
            </TabbedShowLayout>
        </Show>
    );
}
