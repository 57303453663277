import React from "react";
import {
    TabbedForm,
    Create,
    TextInput,
    FormTab,
    NumberInput,
    SelectInput,
    required,
    ArrayInput,
    SimpleFormIterator,
    BooleanInput,
} from "react-admin";
import Grid from "@material-ui/core/Grid";

import CustomDatePicker from "../../../Form/CustomDatePicker"; 
import { SEO, Content } from "../../../Layout/Tabs/Edit";
import InputS3Uploader from "../../../../components/Form/InputS3Uploader";
import RichText from "../../../Form/RichText";
import InputS3UploaderMulti from "../../../Form/InputS3UploaderMulti";

export function CreateView(props) {
    const transform = (data) => ({
        ...data
    });

    return (
        <Create {...props} transform={transform}>
            <TabbedForm>
                <FormTab label={"Основное"} {...props}>
                    <div style={{ width: "100%" }}>
                        <Grid container>
                            <Grid
                                item
                                xs={8}
                                style={{
                                    paddingRight: 24,
                                    borderRight:
                                        "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                            >
                                <TextInput
                                    source={"page_title_full"}
                                    label={"Наименование"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <TextInput
                                    source={"page_title_short"}
                                    label={"Краткое наименование"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                style={{ paddingLeft: 24, marginBottom: 24 }}
                            >
                                <TextInput
                                    source={"slug"}
                                    label={"Слаг"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <NumberInput
                                    source={"sort_order"}
                                    label={"Сортировка"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <InputS3Uploader
                                    source={"main_image"}
                                    label={"Главное изображение"}
                                    validate={[required()]}
                                    options={
                                        {
                                            // TODO: generate category path s3path: `uploads-test/${category}`,
                                        }
                                    }
                                />
                                <InputS3Uploader
                                    source={"main_image_preview"}
                                    label={"Превью изображение"}
                                    options={
                                        {
                                            // TODO: generate category path s3path: `uploads-test/${category}`,
                                        }
                                    }
                                />
                            </Grid>
                        </Grid>
                    </div>
                </FormTab>
                <Content />
                <FormTab label={"Этапы"} {...props}>
                    <div style={{ width: "100%" }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <ArrayInput
                                    source="stages"
                                    label="Этапы"
                                    style={{ width: "100%" }}
                                >
                                    <SimpleFormIterator>
                                        <TextInput
                                            source={"stage_name"}
                                            label={"Название этапа"}
                                            style={{ width: "100%" }}
                                            resettable
                                        />
                                        <CustomDatePicker
                                            source={"date_start"}
                                            label={"Дата начала этапа"}
                                            style={{ width: "100%" }}
                                            validate={[required()]}
                                            resettable
                                        />
                                        <CustomDatePicker
                                            source={"date_end"}
                                            label={"Дата окончания этапа"}
                                            style={{ width: "100%" }}
                                            validate={[required()]}
                                            resettable
                                        />
                                        <BooleanInput
                                            label="Отобразить жюри"
                                            source="jury"
                                        />
                                        <BooleanInput
                                            label="Отобразить проекты"
                                            source="projects_display"
                                        />
                                        <ArrayInput
                                            source="content_blocks"
                                            label="Контент блок этапа"
                                            style={{ width: "100%" }}
                                        >
                                            <SimpleFormIterator>
                                                <SelectInput
                                                    source={"type"}
                                                    label={"Тип блока"}
                                                    defaultValue={"default"}
                                                    style={{ width: "100%" }}
                                                    choices={[
                                                        {
                                                            id: "default",
                                                            name: "По умолчанию",
                                                        },
                                                        {
                                                            id: "Landscape",
                                                            name: "Альбомная",
                                                        },
                                                        {
                                                            id: "Portrait",
                                                            name: "Книжная",
                                                        },
                                                        {
                                                            id: "Square",
                                                            name: "Квадратная",
                                                        },
                                                    ]}
                                                />
                                                <TextInput
                                                    source="title"
                                                    label="Заголовок блока"
                                                    style={{ width: "100%" }}
                                                />
                                                <RichText
                                                    source={"body"}
                                                    label={"Содержимое блока"}
                                                    style={{ width: "100%" }}
                                                />
                                                <BooleanInput
                                                    label="No index"
                                                    source="no_index"
                                                />
                                                <SelectInput
                                                    source={"media.type"}
                                                    label={"Тип контента"}
                                                    defaultValue={"default"}
                                                    choices={[
                                                        {
                                                            id: "default",
                                                            name: "По умолчанию",
                                                        },
                                                        {
                                                            id: "SlideNews",
                                                            name: "Новость",
                                                        },
                                                        {
                                                            id: "Gallery",
                                                            name: "Галерея",
                                                        },
                                                        {
                                                            id: "SlideLink",
                                                            name: "Ссылка на ресурс",
                                                        },
                                                        {
                                                            id: "Video",
                                                            name: "Видео",
                                                        },
                                                        {
                                                            id: "WideImage",
                                                            name: "Изображение на всю ширину",
                                                        },
                                                        {
                                                            id: "ImportantToKnow",
                                                            name: "Важно знать",
                                                        },
                                                    ]}
                                                />
                                                <InputS3UploaderMulti
                                                    source="media.items"
                                                    style={{ width: "100%" }}
                                                />
                                            </SimpleFormIterator>
                                        </ArrayInput>
                                    </SimpleFormIterator>
                                </ArrayInput>

                            </Grid>
                        </Grid>
                    </div>
                </FormTab>
                <FormTab label={"Жюри"} {...props}>
                    <div style={{ width: "100%" }}>
                        <Grid container>
                            <Grid
                                item
                                xs={8}
                            >
                                <ArrayInput
                                    source="jury"
                                    label="Жюри"
                                >
                                    <SimpleFormIterator>
                                        <TextInput
                                            source={"name"}
                                            label={"Имя"}
                                            style={{ width: "100%" }}
                                            resettable
                                        />
                                        <TextInput
                                            source={"post"}
                                            label={"Должность"}
                                            style={{ width: "100%" }}
                                            resettable
                                        />

                                        <InputS3Uploader
                                            source={"main_image"}
                                            label={"Изображение"}
                                            validate={[required()]}
                                            options={
                                                {
                                                    // TODO: generate category path s3path: `uploads-test/${category}`,
                                                }
                                            }
                                        />
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Grid>
                        </Grid>
                    </div>
                </FormTab>
                <SEO />
            </TabbedForm>
        </Create>
    );
}
