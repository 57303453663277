import React from "react";
import { Show, TextField, TabbedShowLayout, ReferenceField } from "react-admin";

import { SEO, Content, Main, Location } from "../../../Layout/Tabs/Show";

export function ShowView(props) {
    const PostTitle = ({ record }) => {
        return <span>Просмотр {record ? `"${record.title_short}"` : ""}</span>;
    };

    return (
        <Show title={<PostTitle />} {...props}>
            <TabbedShowLayout>
                <Main>
                    <ReferenceField
                        source={"region_id"}
                        reference={"regions"}
                        label={"Регион"}
                    >
                        <TextField source="title_full" />
                    </ReferenceField>
                    <ReferenceField
                        source={"city_id"}
                        reference={"v_portal_admin_cities"}
                        label={"Город"}
                    >
                        <TextField source="title_full" />
                    </ReferenceField>
                </Main>
                <Location />
                <SEO />
                <Content />
            </TabbedShowLayout>
        </Show>
    );
}
