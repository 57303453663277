import React, { Component } from "react";
import { addField } from "ra-core";
import { TextInput, ArrayInput, SimpleFormIterator } from "react-admin";
class InputDraft extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: "",
            tab: "write"
        };
    }

    render() {
        return (
            <ArrayInput
                source="drafts"
                style={{ width: "100%" }}
                label={"История черновиков"}
            >
                <SimpleFormIterator disableRemove>
                    {/* <ArrayInput
                        source="data.content_blocks"
                        label="Блоки на странице"
                        style={{ width: "100%" }}
                    >
                        <SimpleFormIterator disableRemove>
                            <SelectInput
                                source={"type"}
                                label={"Тип блока"}
                                defaultValue={"default"}
                                style={{ width: "100%" }}
                                choices={[
                                    {
                                        id: "default",
                                        name: "По умолчанию"
                                    },
                                    {
                                        id: "PhotoSwipe",
                                        name: "Галерея"
                                    }
                                ]}
                            />
                            <TextInput
                                source="title"
                                label="Заголовок блока"
                                style={{ width: "100%" }}
                            />
                            <RichText
                                source={"body"}
                                label={"Содержимое блока"}
                                style={{ width: "100%" }}
                            />
                            <SelectInput
                                source={"media.type"}
                                label={"Тип контента"}
                                defaultValue={"default"}
                                choices={[
                                    {
                                        id: "default",
                                        name: "По умолчанию"
                                    },
                                    {
                                        id: "SlideNews",
                                        name: "Новость"
                                    },
                                    {
                                        id: "SlideGallery",
                                        name: "Элемент галереи"
                                    },
                                    {
                                        id: "SlideLink",
                                        name: "Ссылка на ресурс"
                                    }
                                ]}
                            />
                            <ArrayInput
                                source="media.items"
                                label="Элемент медиа"
                            >
                                <SimpleFormIterator disableRemove>
                                    <InputS3Uploader
                                        style={{ width: "100%" }}
                                    />
                                    <TextInput
                                        source={"alt"}
                                        label={"Альтернативный текст"}
                                    />
                                </SimpleFormIterator>
                            </ArrayInput>
                        </SimpleFormIterator>
                    </ArrayInput> */}
                    <TextInput
                        source="comment"
                        label="Комментарий"
                        style={{ width: "100%" }}
                    />
                </SimpleFormIterator>
            </ArrayInput>
        );
    }
}

export default addField(InputDraft);
