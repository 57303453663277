import React from "react";
import {
    TabbedForm,
    Create,
    SelectInput,
    ReferenceInput,
    required,
} from "react-admin";
import Grid from "@material-ui/core/Grid";

import {
    SEO,
    Content,
    Main,
    Location,
    BuildingAttributes,
} from "../../../Layout/Tabs/Edit";

import { TextInput } from "react-admin";
import InputS3Uploader from "../../../Form/InputS3Uploader";
import RichText from "../../../Form/RichText";

export const appeal_to_visitors = (
    <>
        <TextInput
            source={"appeal_to_visitors.title"}
            label="Заголовок блока"
            style={{ width: "100%" }}
        />
        <RichText
            source={"appeal_to_visitors.body"}
            label={"Содержимое блока"}
            style={{ width: "100%" }}
        />
        <Grid item xs={4}>
            <InputS3Uploader
                source={"appeal_to_visitors.image.src"}
                style={{ width: "100%" }}
            />
            <TextInput
                source={"appeal_to_visitors.image.alt"}
                label={"Альтернативный текст"}
            />
        </Grid>
    </>
);

export function CreateView(props) {
    return (
        <Create {...props}>
            <TabbedForm>
                <Main ShowThreeDModelField={true} showPublishedAtField={true}>
                    <Grid xs={2}>
                        <ReferenceInput
                            source={"region_id"}
                            reference={"regions"}
                            label={"Регион"}
                            validate={[required()]}
                            style={{
                                width: "100%",
                            }}
                        >
                            <SelectInput optionText="title_full" />
                        </ReferenceInput>
                        <ReferenceInput
                            source={"city_id"}
                            reference={"v_portal_admin_cities"}
                            label={"Город"}
                            allowEmpty
                            style={{
                                width: "100%",
                            }}
                        >
                            <SelectInput
                                optionText="title_full"
                                emptyValue={null}
                            />
                        </ReferenceInput>
                        <ReferenceInput
                            source={"monastery_id"}
                            reference={"monasteries"}
                            label={"Монастырь"}
                            allowEmpty
                            style={{
                                width: "100%",
                            }}
                        >
                            <SelectInput
                                optionText={"title_short"}
                                emptyValue={null}
                            />
                        </ReferenceInput>
                        <ReferenceInput
                            source={"cathedral_id"}
                            reference={"cathedrals"}
                            label={"Собор"}
                            allowEmpty
                            style={{
                                width: "100%",
                            }}
                        >
                            <SelectInput
                                optionText={"title_short"}
                                emptyValue={null}
                            />
                        </ReferenceInput>
                        <ReferenceInput
                            source={"temple_id"}
                            reference={"temples"}
                            label={"Храм"}
                            allowEmpty
                            style={{
                                width: "100%",
                            }}
                        >
                            <SelectInput
                                optionText={"title_short"}
                                emptyValue={null}
                            />
                        </ReferenceInput>
                        <ReferenceInput
                            source={"church_id"}
                            reference={"churches"}
                            label={"Церковь"}
                            allowEmpty
                            style={{
                                width: "100%",
                            }}
                        >
                            <SelectInput
                                optionText={"title_short"}
                                emptyValue={null}
                            />
                        </ReferenceInput>
                    </Grid>
                </Main>
                <Location />
                <SEO />
                <Content before={appeal_to_visitors} />
                <BuildingAttributes />
            </TabbedForm>
        </Create>
    );
}
