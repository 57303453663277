import React from "react";
import { TextField, ReferenceField } from "react-admin";
import {
    Create,
    ReferenceInput,
    SelectInput,
    TabbedShowLayout,
    Tab,
} from "react-admin";
import { Show, Edit } from "react-admin";

import { SimpleForm, required } from "react-admin";

import { get } from "lodash";

import AutocompleteField from "../../../Form/Autocomplete";

export const CreateView = (props) => {
    const redirect = get(props, "location.state.redirect_to", "") || false;

    return (
        <Create {...props}>
            <SimpleForm redirect={redirect}>
                <ReferenceInput
                    label={"Престольный праздник"}
                    source="holiday_id"
                    reference="holidays"
                    validate={required()}
                    perPage={100}
                >
                    <AutocompleteField title="Престольный праздник" />
                </ReferenceInput>
                <ReferenceInput
                    source="city_object_id"
                    reference="city_objects"
                    validate={required()}
                    style={{ display: "none" }}
                    perPage={100}
                >
                    <SelectInput optionText="id" />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};

export const EditView = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <ReferenceInput
                label={"Престольный праздник"}
                source="holiday_id"
                reference="holidays"
                validate={required()}
                perPage={100}
            >
                <AutocompleteField title="Престольный праздник" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

export const ShowView = (props) => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label={"Основное"} {...props}>
                <ReferenceField
                    label="Престольный праздник"
                    source="holiday_id"
                    reference="holidays"
                    perPage={100}
                >
                    <TextField source="title_full" />
                </ReferenceField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
