import React from "react";
import { Show, TabbedShowLayout } from "react-admin";

import { SEO, Content, Main, Location } from "../../../Layout/Tabs/Show";
import PostShowActions from "../../../Form/Preview";

export function ShowView(props) {
    const PostTitle = ({ record }) => {
        return <span>Просмотр {record ? `"${record.title_full}"` : ""}</span>;
    };

    return (
        <Show title={<PostTitle />} actions={<PostShowActions />} {...props}>
            <TabbedShowLayout>
                <Main />
                <Location />
                <SEO />
                <Content />
            </TabbedShowLayout>
        </Show>
    );
}
