import React, { useState, useEffect } from "react";
import {
    useCreateController,
    CreateContextProvider,
    useDataProvider,
    TabbedForm,
    FormTab,
    TextInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    BooleanInput,
    required,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import { get } from "lodash";
import PropTypes from "prop-types";
import { useDebounceFn } from "@umijs/hooks";
import { useHistory } from "react-router-dom";

import CustomDatePicker from "../../../Form/CustomDatePicker"; 
import { SEO, Content } from "../../../Layout/Tabs/Edit";
import InputS3Uploader from "../../../Form/InputS3Uploader";
import InputS3DragCropUploader from "../../../Form/InputS3DragCropUploader";
import { prepareUserLogsData } from "../../../../utils";
import withPermissions from "../../../../hoc/CheckPermissions";

const MyCreate = (props) => {
    let history = useHistory();
    const createControllerProps = useCreateController(props);

    const {
        basePath, // deduced from the location, useful for action buttons
        record, // empty object, unless some values were passed in the location state to prefill the form
        redirect, // the default redirection route. Defaults to 'edit', unless the resource has no edit view, in which case it's 'list'
        resource, // the resource name, deduced from the location. e.g. 'posts'
        saving, // boolean that becomes true when the dataProvider is called to create the record
        version, // integer used by the refresh feature
    } = createControllerProps;

    const dataProvider = useDataProvider();

    const [modal, setModal] = useState({ open: false, message: "" });

    const { run: showMessage } = useDebounceFn(() => {
        document.location.reload();
    }, 2000);

    useEffect(() => {
        if (get(modal, "open")) {
            showMessage();
        }
    }, [modal, showMessage]);

    function save(props) {
        dataProvider
            .create("news", {
                data: { ...props },
            })
            .then((response) => {
                const logData = prepareUserLogsData(
                    record,
                    response.data,
                    resource,
                    "create"
                );
                dataProvider
                    .create("admin_action_logs", {
                        data: logData,
                    })
                    .then(() => {
                        setModal({
                            open: true,
                            message: "Создание завершено!",
                        });
                        history.push("/news");
                    })
                    .catch((error) => {
                        console.error("Ошибка логирования: ", { error });
                    });
            })
            .catch((error) =>
                console.error("Ошибка создания новости: ", { error })
            );
    }

    return (
        <CreateContextProvider value={createControllerProps}>
            <div>
                <h1>Создать новость</h1>
                {React.cloneElement(props.children, {
                    basePath,
                    record,
                    redirect,
                    resource,
                    save,
                    saving,
                    version,
                })}
            </div>
        </CreateContextProvider>
    );
};

MyCreate.propTypes = {
    children: PropTypes.node,
};

function UnmappedCreateView(props) {
    return (
        <MyCreate {...props}>
            <TabbedForm>
                <FormTab label={"Основное"} {...props}>
                    <div style={{ width: "100%" }}>
                        <Grid container>
                            <Grid
                                item
                                xs={8}
                                style={{
                                    paddingRight: 24,
                                    borderRight:
                                        "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                            >
                                <TextInput
                                    source={"page_title_full"}
                                    label={"Наименование страницы"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <TextInput
                                    source={"page_title_short"}
                                    label={"Краткое наименование страницы"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <TextInput
                                    source={"teaser"}
                                    label={"Тизер новости"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <ReferenceInput
                                    source={"author_id"}
                                    reference={"authors"}
                                    label={"Автор новости"}
                                    allowEmpty
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <SelectInput
                                        optionText="name"
                                        emptyValue={null}
                                    />
                                </ReferenceInput>
                                <SelectInput
                                    source={"age_rating"}
                                    label={"Возрастной рейтинг"}
                                    style={{ width: "100%" }}
                                    allowEmpty
                                    emptyValue={null}
                                    choices={[
                                        {
                                            id: "0+",
                                            name: "0+",
                                        },
                                        {
                                            id: "6+",
                                            name: "6+",
                                        },
                                        {
                                            id: "12+",
                                            name: "12+",
                                        },
                                        {
                                            id: "16+",
                                            name: "16+",
                                        },
                                        {
                                            id: "18+",
                                            name: "18+",
                                        },
                                    ]}
                                />
                                <ReferenceInput
                                    source={"category_id"}
                                    reference={"categories"}
                                    label={"Категория"}
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <SelectInput
                                        emptyValue={null}
                                        optionText="title_full"
                                        validate={[required()]}
                                    />
                                </ReferenceInput>
                                <BooleanInput
                                    label="Публикация в vk"
                                    source="data.social_posting.vk"
                                    defaultValue={false}
                                />
                                <BooleanInput
                                    label="Публикация в facebook"
                                    source="data.social_posting.fb"
                                    defaultValue={false}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                style={{ paddingLeft: 24, marginBottom: 24 }}
                            >
                                <TextInput
                                    source={"slug"}
                                    label={"Слаг"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <CustomDatePicker
                                    source={"issued_at"}
                                    label={"Дата новости"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <CustomDatePicker
                                    source={"published_at"}
                                    label={"Дата публикации новости"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <NumberInput
                                    source={"sort_order"}
                                    label={"Сортировка"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <BooleanInput
                                    label="Главная новость"
                                    source="settings.is_main"
                                    defaultValue={false}
                                />
                                <BooleanInput
                                    label="Скрыть дату"
                                    source="settings.hide_published_at"
                                    defaultValue={false}
                                />
                                <InputS3Uploader
                                    source={"main_image"}
                                    label={"Главное изображение"}
                                    validate={[required()]}
                                />
                                <InputS3DragCropUploader
                                    source={"main_image_preview"}
                                    label={"Превью изображение"}
                                    needCrop
                                />
                            </Grid>
                        </Grid>
                    </div>
                </FormTab>
                <SEO />
                <Content />
            </TabbedForm>
        </MyCreate>
    );
}

const CreateView = withPermissions(UnmappedCreateView);

export { CreateView };
