import React, { useEffect } from "react";
import { AppBar } from "react-admin";
import { Typography } from "@material-ui/core/";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import Search from "./Search";

const mapDispatchToProps = (dispatch) => {
    return {
        resetAutoRefresh: () =>
            dispatch({
                type: "RA/SET_AUTOMATIC_REFRESH",
                payload: false,
            }),
    };
};

function CustomAppBar(props) {
    let interval = null;

    const { resetAutoRefresh = () => Function } = props;

    useEffect(() => {
        // eslint-disable-next-line
        interval = setInterval(() => resetAutoRefresh(), 60000 * 4);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <AppBar {...props}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        flex: 1,
                        padding: "4px 0",
                    }}
                >
                    <NavLink to="/">
                        <i
                            className="sl-logo_norm"
                            style={{
                                color: "#fff",
                                fontSize: 50,
                                marginRight: 16,
                            }}
                        />
                    </NavLink>
                    <Typography
                        id="react-admin-title"
                        variant="subtitle1"
                        color="inherit"
                    />
                    <Search />
                </div>
            </AppBar>
        </>
    );
}

export default connect(null, mapDispatchToProps)(CustomAppBar);
