import React, { useState, useEffect } from "react";
import { addField } from "ra-core";
import get from "lodash/get";
import { Tweet } from 'react-twitter-widgets';
import TextField from '@material-ui/core/TextField';

function TwitterPostInput({ input: { onChange, value } }) {
    const [url, setUrl] = useState("");
    const [params, setParams] = useState(value)

    function handleChange(event) {
        setUrl(event.target.value);
    }

    useEffect(() => {
        const array = url.split("/");
        setParams({
            url: url,
            tweetId: array[array.length - 1]
        })
    }, [url]);

    useEffect(() => {
        onChange(params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    const tweetId = get(value, "tweetId", "");

    return (
        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
            <div style={{ width: "100%", display: "flex" }}>
                <div style={{ flex: "1" }}>
                    <TextField
                        id="filled-basic"
                        label="Ссылка на пост из Twitter"
                        variant="filled"
                        name="Twitter"
                        onChange={handleChange}
                        style={{ width: "96%" }}
                        value={value.url}
                    />
                </div>
                <div style={{ flex: "1" }}>
                    {
                        tweetId && (
                            <Tweet tweetId={tweetId} />
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default addField(TwitterPostInput);
