import React from "react";
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    ImageField,
    DateField,
    Pagination,
    usePermissions,
} from "react-admin";
import { get } from "lodash";
import PropTypes from "prop-types";

import AgreementActions from "../../Form/Button/Approve";
import "./index.scss";

export default function UnmappedListView(props) {
    const { loaded, permissions: role } = usePermissions();
    const { permissions, roles } = props;

    const PostPagination = (props) => (
        <Pagination
            rowsPerPageOptions={[
                10,
                25,
                50,
                100,
                300,
                500,
                700,
                900,
                1100,
                1300,
                1500,
                1700,
            ]}
            {...props}
        />
    );

    return (
        loaded && (
            <List {...props} pagination={<PostPagination />}>
                <Datagrid
                    rowClick="show"
                    sort={{ field: "sort_order", order: "ASC" }}
                    isRowSelectable={() => role === "admin"}
                >
                    <TextField source="id" label={"Id"} />
                    {props.showSortOrder && (
                        <TextField source="sort_order" label={"Сортировка"} />
                    )}
                    {props.children}
                    <ImageField
                        source={"main_image.src"}
                        label={"Главное изображение"}
                        className={"image-cell"}
                    />
                    <TextField source="title_full" label={"Название"} />
                    {props.showPublishedAtField && (
                        <DateField source={"published_at"} label={"Опубликовано"} />
                    )}
                    <AgreementActions
                        actions={get(permissions, "actions.agreement", [])}
                        role={roles[0]}
                    />
                    <EditButton />
                </Datagrid>
            </List>
        )
    );
}

UnmappedListView.propTypes = {
    showSortOrder: PropTypes.bool,
    showPublishedAtField: PropTypes.bool,
};

UnmappedListView.defaultProps = {
    showSortOrder: true,
    showPublishedAtField: false,
};
