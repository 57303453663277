import React from "react";
import Grid from "@material-ui/core/Grid";
import {
    TextInput,
    FormTab,
    ArrayInput,
    SimpleFormIterator,
    ReferenceInput,
    SelectInput,
} from "react-admin";
import { get } from "lodash";

export function BuildingAttributes(props) {
    const { edit: { attributes } = { attributes: true }, edit } = get(
        props,
        "permissions",
        {}
    );

    const showAttributes = (typeof edit === "boolean" && edit) || attributes;

    return showAttributes ? (
        <FormTab label={"Атрибуты"} {...props}>
            <div style={{ width: "100%" }}>
                <Grid container>
                    <Grid
                        item
                        xs={8}
                        style={{
                            paddingRight: 24,
                            borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                        }}
                    >
                        <TextInput
                            source={"consecrated_date"}
                            label={"Дата освящения"}
                            style={{ width: "100%" }}
                            resettable
                        />
                        <TextInput
                            source={"foundation_date"}
                            label={"Дата основания"}
                            style={{ width: "100%" }}
                            resettable
                        />
                        <ReferenceInput
                            source={"founder_id"}
                            reference={"persons"}
                            label={"Основатель"}
                            allowEmpty
                            style={{
                                width: "100%",
                            }}
                        >
                            <SelectInput optionText="title" emptyValue={null} />
                        </ReferenceInput>
                        <ReferenceInput
                            source={"author_id"}
                            reference={"persons"}
                            label={"Автор проекта"}
                            allowEmpty
                            style={{
                                width: "100%",
                            }}
                        >
                            <SelectInput optionText="title" emptyValue={null} />
                        </ReferenceInput>
                        <ArrayInput
                            source="building_dates"
                            label="Даты строительства:"
                        >
                            <SimpleFormIterator>
                                <TextInput
                                    source={"name"}
                                    label={"Заголовок"}
                                    style={{ width: "100%" }}
                                />
                            </SimpleFormIterator>
                        </ArrayInput>
                        <ReferenceInput
                            source={"architectural_style_id"}
                            reference={"architectural_styles"}
                            label={"Архитектурный стиль"}
                            allowEmpty
                            style={{
                                width: "100%",
                            }}
                        >
                            <SelectInput
                                optionText="title_full"
                                emptyValue={null}
                            />
                        </ReferenceInput>
                        <SelectInput
                            source={"state"}
                            label={"Статус"}
                            style={{ width: "100%" }}
                            allowEmpty
                            emptyValue={null}
                            choices={[
                                {
                                    id: "active",
                                    name: "Действующий",
                                },
                                {
                                    id: "closed",
                                    name: "Закрытый",
                                },
                                {
                                    id: "restoration",
                                    name: "На реставрации",
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
            </div>
        </FormTab>
    ) : (
        <></>
    );
}
