import React, { useState, useEffect, useRef } from "react";
import { addField } from "ra-core";
import { Post } from "../../../contexts";
import get from "lodash/get";

function VkPostinput({ input: { onChange, value } }) {
    const node = useRef(null);
    const [__html, setHtml] = useState("");

    function handleChange(event) {
        setHtml(event.target.value);
    }

    useEffect(() => {
        if(!!__html) {
            const script = get(node, "current.children[2]", <></>);
            const oldVK = window.VK;

            if (script) {
                window.VK = {
                    Widgets: {
                        Post() {
                            const [elementId, ownerId, postId, hash, options] = [...arguments];
                            onChange({
                                elementId,
                                ownerId,
                                postId,
                                hash,
                                options,
                                __html
                            })
                        }
                    }
                }
                // eslint-disable-next-line no-eval
                eval(script.innerText);
                delete node.current.children;
                window.VK = oldVK;
            }
        } else {
            onChange(__html)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [__html]);

    const elementId = get(value, "elementId", "");
    const ownerId = get(value, "ownerId", "");
    const postId = get(value, "postId", "");
    const hash = get(value, "hash", "");
    const options = get(value, "options", {});

    return (
        <div style={{ paddingTop: 48, paddingBottom: 48 }}>
            <div style={{ width: "100%", display: "flex" }}>
                <div style={{ flex: "1" }}>
                    <textarea
                        name="VK"
                        placeholder="Код для вставки поста из ВК"
                        id="vkSrcPost"
                        cols="80"
                        rows="10"
                        onChange={handleChange}
                        style={{
                            resize: "vertical",
                            maxHeight: "300px",
                            minHeight: "155px",
                            padding: "1rem",
                            borderColor: "rgb(133, 133, 133)",
                            marginRight: "20px",
                            background: "rgb(240 240 240)",
                            width: "90%",
                            borderRadius: "5px",
                            fontSize: "16px"
                        }}
                    >
                            {value.__html}
                    </textarea>
                </div>
                <div style={{ flex: "1" }}>
                <div ref={node} dangerouslySetInnerHTML={{ __html }} />
                    {
                        elementId && ownerId && postId && hash && (
                            <Post
                                elementId={elementId}
                                ownerId={ownerId}
                                postId={postId}
                                hash={hash}
                                options={options}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default addField(VkPostinput);
