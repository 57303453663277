import decodeJwt from "jwt-decode";
import { get } from "lodash";

export default {
    login({ username: login, password }) {
        const headers = new Headers();

        headers.append("Content-Type", "application/json");

        const request = new Request(
            `${process.env.REACT_APP_BASE_API}/api/auth/signin`,
            {
                headers,
                method: "POST",
                body: JSON.stringify({
                    login,
                    password,
                }),
                mode: "cors",
                credentials: "include",
                redirect: "follow",
            }
        );

        return fetch(request)
            .then((response) => {
                const { ok, status } = response;

                if (ok) {
                    return response.json();
                }
                console.error("Auth response error: ", { status });

                return {};
            })
            .then((data) => {
                if (data.status === "200") {
                    const token = data.data.token;
                    const decodedToken = decodeJwt(token);
                    const role = get(
                        decodedToken,
                        "['https://hasura.io/jwt/claims'].x-hasura-default-role",
                        "editor"
                    );

                    localStorage.setItem("serafim_token", token);
                    localStorage.setItem("permission", role);

                    document.location.reload();
                } else {
                    console.log("Auth rejected");
                }
            })
            .catch((error) => {
                console.error("Auth response catch error", { error });
            });
    },
    logout() {
        localStorage.removeItem("serafim_token");
        localStorage.removeItem("permission");

        return Promise.resolve();
    },
    checkError(error) {
        console.error("Auth error", { error });
    },
    checkAuth() {
        return localStorage.getItem("serafim_token")
            ? Promise.resolve()
            : Promise.reject();
    },
    getPermissions() {
        const permission = localStorage.getItem("permission");

        return permission
            ? Promise.resolve(permission)
            : Promise.reject("Permissions not allowed");
    },
};
