import React, { useState, useEffect } from "react";
import {
    Show,
    TabbedShowLayout,
    Button,
    ReferenceManyField,
    SingleFieldList,
    ReferenceField,
    ChipField,
    TopToolbar,
    EditButton,
} from "react-admin";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";
import Looks from "@material-ui/icons/Looks";

import {
    SEO,
    Content,
    Main,
    Location,
    Connections,
} from "../../../Layout/Tabs/Show";

function PostShowActions({ basePath, data }) {
    const [preview, setPreview] = useState(null);
    const [record, setRecord] = useState(data);
    const [tab, setTab] = useState(null);

    function connectedPreview({ data }) {
        const { previewLoaded = false } = data;

        setPreview(previewLoaded);
    }

    function openTab() {
        if (typeof window !== "undefined") {
            setTab(
                window.open(
                    `${process.env.REACT_APP_FRONT_HOST}/preview`,
                    "Превью"
                )
            );
        }
    }

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("message", connectedPreview);
            window.addEventListener("unload", () => {
                if (tab) {
                    tab.close();
                }
            });
        }

        return () => {
            if (typeof window !== "undefined") {
                window.removeEventListener("message", connectedPreview);
            }
        };
    }, [tab]);

    useEffect(() => {
        setRecord(data);
    }, [data]);

    useEffect(() => {
        console.log({ record });
        if (preview && tab) {
            tab.postMessage(record, "*");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preview]);

    return (
        <TopToolbar>
            <Button
                title={"Предпросмотр"}
                label={"Предпросмотр"}
                onClick={() => openTab(data)}
                className={"preview"}
            >
                <Looks />
            </Button>
            <EditButton basePath={basePath} record={data} />
        </TopToolbar>
    );
}

export function ShowView(props) {
    const PostTitle = ({ record }) => {
        return <span>Просмотр {record ? `"${record.title_short}"` : ""}</span>;
    };

    const AddButton = ({ redirect_to, record, table, label }) => (
        <Button
            variant="raised"
            component={Link}
            to={{
                pathname: `/${table}/create`,
                state: { record: { media_article_id: record.id }, redirect_to },
            }}
            label={label}
            title={label}
        >
            <AddIcon />
        </Button>
    );

    const AddReferenceButton = AddButton;

    return (
        <Show title={<PostTitle />} actions={<PostShowActions />} {...props}>
            <TabbedShowLayout>
                <Main />
                <Connections>
                    <ReferenceManyField
                        label="Таргетинг"
                        reference="media_articles_targetings"
                        target="media_article_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="targeting_id"
                                reference="targetings"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"media_articles_targetings"}
                        label={"Прикрепить к таргету"}
                    />

                    <ReferenceManyField
                        label="Рубрика"
                        reference="categories_media_articles"
                        target="media_article_id"
                        sort={{ field: "media_article_id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="category_id"
                                reference="categories"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"categories_media_articles"}
                        label={"Рубрики"}
                    />

                    <ReferenceManyField
                        label="Статьи связанные вперед"
                        reference="linked_media_articles"
                        target="media_article_id"
                        sort={{ field: "media_article_id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="linked_media_article_id"
                                reference="media_articles"
                                link={false}
                            >
                                <ChipField source="page_title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"linked_media_articles"}
                        label={"Прикрепить статью"}
                    />

                    <ReferenceManyField
                        label="Статьи связанные назад"
                        reference="linked_media_articles"
                        target="linked_media_article_id"
                        sort={{ field: "media_article_id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="media_article_id"
                                reference="media_articles"
                                link={false}
                            >
                                <ChipField source="page_title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"linked_media_articles"}
                        label={"Прикрепить статью"}
                    />

                    <ReferenceManyField
                        label="Города"
                        reference="cities_media_articles"
                        target="media_article_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="city_id"
                                reference="v_portal_admin_cities"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"cities_media_articles"}
                        label={"Прикрепить к городу"}
                    />

                    <ReferenceManyField
                        label="Монастыри"
                        reference="monasteries_media_articles"
                        target="media_article_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="monastery_id"
                                reference="monasteries"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"monasteries_media_articles"}
                        label={"Прикрепить к монастырю"}
                    />

                    <ReferenceManyField
                        label="Церкви"
                        reference="churches_media_articles"
                        target="media_article_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="church_id"
                                reference="churches"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"churches_media_articles"}
                        label={"Прикрепить к церкви"}
                    />

                    <ReferenceManyField
                        label="Соборы"
                        reference="cathedrals_media_articles"
                        target="media_article_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="cathedral_id"
                                reference="cathedrals"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"cathedrals_media_articles"}
                        label={"Прикрепить к собору"}
                    />

                    <ReferenceManyField
                        label="Храмы"
                        reference="media_articles_temples"
                        target="media_article_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="temple_id"
                                reference="temples"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"media_articles_temples"}
                        label={"Прикрепить к храму"}
                    />

                    <ReferenceManyField
                        label="Колокольни"
                        reference="belltowers_media_articles"
                        target="media_article_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="belltower_id"
                                reference="belltowers"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"belltowers_media_articles"}
                        label={"Прикрепить к колокольне"}
                    />

                    <ReferenceManyField
                        label="Часовни"
                        reference="chapels_media_articles"
                        target="media_article_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="chapel_id"
                                reference="chapels"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"chapels_media_articles"}
                        label={"Прикрепить к часовне"}
                    />

                    <ReferenceManyField
                        label="Скиты"
                        reference="hermitages_media_articles"
                        target="media_article_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="hermitage_id"
                                reference="hermitages"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"hermitages_media_articles"}
                        label={"Прикрепить к скиту"}
                    />

                    <ReferenceManyField
                        label="Источники"
                        reference="media_articles_wells"
                        target="media_article_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="well_id"
                                reference="wells"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"media_articles_wells"}
                        label={"Прикрепить к источнику"}
                    />

                    <ReferenceManyField
                        label="Музеи"
                        reference="media_articles_museums"
                        target="media_article_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="museum_id"
                                reference="museums"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"media_articles_museums"}
                        label={"Прикрепить к музею"}
                    />

                    <ReferenceManyField
                        label="Городские объекты"
                        reference="city_objects_media_articles"
                        target="media_article_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="city_object_id"
                                reference="city_objects"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"city_objects_media_articles"}
                        label={"Прикрепить к городскому объекту"}
                    />

                    <ReferenceManyField
                        label="Новости"
                        reference="v_media_articles_news"
                        target="media_article_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="news_id"
                                reference="news"
                                link={false}
                            >
                                <ChipField source="page_title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"v_media_articles_news"}
                        label={"Прикрепить к новости"}
                    />

                    <ReferenceManyField
                        label="Текстовые страницы"
                        reference="media_articles_text_pages"
                        target="media_article_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="text_page_id"
                                reference="text_pages"
                                link={false}
                            >
                                <ChipField source="page_title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"media_articles_text_pages"}
                        label={"Прикрепить к текстовой странице"}
                    />

                    <ReferenceManyField
                        label="Гуманитарные проекты"
                        reference="media_articles_serafim_projects"
                        target="media_article_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="serafim_project_id"
                                reference="serafim_projects"
                                link={false}
                            >
                                <ChipField source="page_title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"media_articles_serafim_projects"}
                        label={"Прикрепить к гуманитарному проекту"}
                    />
                </Connections>
                <Location />
                <SEO />
                <Content />
            </TabbedShowLayout>
        </Show>
    );
}
