import React from "react";
import { Show, TextField, TabbedShowLayout, Tab } from "react-admin";

export function ShowView(props) {
	const PostTitle = ({ record }) => {
		return <span>Просмотр {record ? `"${record.id}"` : ""}</span>;
	}

	return (
		<Show title={<PostTitle />} {...props}>
			<TabbedShowLayout>
				<Tab label="Основное" {...props}>
					<TextField id="id" label={"id"} />
					<TextField
						source="subject"
						label={"Тема письма"}
					/>
					<TextField
						source={"body_text"}
						label={"Текст"}
						style={{ width: "100%" }}
					/>
					<TextField
						source={"body_html"}
						label={"HTML"}
						style={{ width: "100%" }}
					/>
				</Tab>
			</TabbedShowLayout>
		</Show>
	)
};
