import React from "react";

import CheckPermissions from "../../../../hoc/CheckPermissions";
import { TextField } from "react-admin";
import { List, Datagrid } from "react-admin";

function UnmappedListView(props) {
    return (
        <List sort={{ field: "id", order: "DESC" }} {...props}>
            <Datagrid rowClick="show">
                <TextField source="id" label={"Id"} />
                <TextField source="name" label={"Название"} />
                <TextField source="slug" label={"Слаг"} />
            </Datagrid>
        </List>
    );
}

const ListView = CheckPermissions(UnmappedListView, "city");

export { ListView };
