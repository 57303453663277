import React from "react";
import { Tab } from "react-admin";

import DraftField from "../../../../Show/DraftField";

export function Draft(props) {
    return (
        <Tab label="Черновик" {...props}>
            <DraftField source="drafts" label="Черновик" />
        </Tab>
    );
}
