import React from "react";
import { TextField, ReferenceField } from "react-admin";

import List from "../../../Layout/List";
import CheckPermissions from "../../../../hoc/CheckPermissions";
import ListFilter from "./ListFilter";

function UnmappedListView(props) {
    return (
        <List
            filters={<ListFilter />}
            sort={{ field: "id", order: "DESC" }}
            {...props}
            showPublishedAtField={true}
        >
            <ReferenceField
                source="region_id"
                reference="regions"
                link={false}
                label="Регион"
            >
                <TextField source="title_full" />
            </ReferenceField>
            <ReferenceField
                source="city_id"
                reference="v_portal_admin_cities"
                link={false}
                label="Город"
            >
                <TextField source="title_full" />
            </ReferenceField>
        </List>
    );
}

const ListView = CheckPermissions(UnmappedListView, "city");

export { ListView };
