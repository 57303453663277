import React from "react";
import { TabbedForm, Create, TextInput, FormTab, required, SelectInput } from "react-admin";
import Grid from "@material-ui/core/Grid";

import { SEO, Content, Location } from "../../../Layout/Tabs/Edit";
import InputS3Uploader from "../../../../components/Form/InputS3Uploader";


export function CreateView(props) {
	return (
		<Create {...props}>
			<TabbedForm>
                <FormTab label={"Основное"} {...props}>
                    <div style={{ width: "100%" }}>
                        <Grid container>
                            <Grid item xs={8} style={{ paddingRight: 24, borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                                <TextInput
                                    source={"title_full"}
                                    label={"Наименование"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <TextInput 
                                    source={"page_title_full"}
                                    label={"Наименование страницы"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <TextInput 
                                    source={"page_title_short"}
                                    label={"Краткое наименование страницы"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                            </Grid>
                            <Grid item xs={4} style={{ paddingLeft: 24, marginBottom: 24 }}>
                                <TextInput 
                                    source={"slug"}
                                    label={"Слаг"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <SelectInput 
									source={"template"}
									label={"Шаблон"}
									defaultValue={"Default"}
									style={{ width: "100%" }}
									choices={[
										{
											id: "Default", 
											name: "По умолчанию",
										},
										{
											id: "SerafimProject", 
											name: "Серафимовские проекты",
										},
										{
											id: "Accordion",
											name: "Аккордеон",
										},
										{
											id: "Trebs",
											name: "Требы",
										},
										{
											id: "Donate",
											name: "Пожертвования",
										},
									]} 
								/>
                                <InputS3Uploader
                                    source={"main_image"}
                                    label={"Главное изображение"}
                                    validate={[required()]}
                                    options={
                                        {
                                            // TODO: generate category path s3path: `uploads-test/${category}`,
                                        }
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}/>
                        </Grid>
                    </div>
                </FormTab>
				<Location />
				<SEO />
				<Content />
			</TabbedForm>
		</Create>
	);
}
