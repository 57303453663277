import React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    ReferenceInput,
    SelectInput,
} from "react-admin";

import { EditActions } from "../../../Layout/Actions";

export function EditView(props) {
    const PostTitle = ({ record }) => {
        return <span>Редактируется {record ? `"${record.title}"` : ""}</span>;
    };

    return (
        <Edit title={<PostTitle />} actions={<EditActions />} {...props}>
            <SimpleForm>
                <TextInput
                    source={"title"}
                    label={"Название дня"}
                    style={{ width: "100%" }}
                />
                <DateInput
                    source={"date"}
                    label={"Дата"}
                    style={{ width: "100%" }}
                />
                <TextInput
                    source={"name"}
                    label={"День недели"}
                    style={{ width: "100%" }}
                />
                <TextInput
                    source={"new_date_style"}
                    label={"Дата по новому стилю"}
                    style={{ width: "100%" }}
                />
                <TextInput
                    source={"old_date_style"}
                    label={"Дата по старому стилю"}
                    style={{ width: "100%" }}
                />
                <TextInput
                    source={"glas_name"}
                    label={"Наименование гласа"}
                    style={{ width: "100%" }}
                />
                <ReferenceInput
                    source={"week_id"}
                    reference={"weeks"}
                    label={"Неделя"}
                    allowEmpty
                    perPage={1000}
                    style={{
                        width: "100%",
                    }}
                >
                    <SelectInput optionText="title" emptyValue={null} />
                </ReferenceInput>
                <ReferenceInput
                    source={"food_id"}
                    reference={"foods"}
                    label={"Еда"}
                    allowEmpty
                    perPage={1000}
                    style={{
                        width: "100%",
                    }}
                >
                    <SelectInput optionText="name" emptyValue={null} />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
}
