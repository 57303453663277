import React from "react";
import {
    Show,
    TabbedShowLayout,
    ReferenceManyField,
    SingleFieldList,
    ReferenceField,
    ChipField,
    Button,
    Tab,
    TextField,
} from "react-admin";

import { Connections } from "../../../Layout/Tabs/Show";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";

export function ShowView(props) {
    const PostTitle = ({ record }) => {
        return <span>Просмотр {record ? `"${record.title_short}"` : ""}</span>;
    };

    const AddButton = ({ redirect_to, record, table, label }) => (
        <Button
            variant="raised"
            component={Link}
            to={{
                pathname: `/${table}/create`,
                state: { record: { day_id: record.id }, redirect_to },
            }}
            label={label}
            title={label}
        >
            <AddIcon />
        </Button>
    );

    const AddReferenceButton = AddButton;

    return (
        <Show title={<PostTitle />} {...props}>
            <TabbedShowLayout>
                <Tab label="Основное" {...props}>
                    <TextField
                        source={"title"}
                        label={"Название дня"}
                        style={{ width: "100%" }}
                    />
                    <TextField source={"date"} label={"Дата"} />
                    <TextField source="name" label={"День недели"} />
                    <TextField
                        source="glas_name"
                        label={"Наименование гласа"}
                    />
                    {props.children}
                </Tab>
                <Connections>
                    <ReferenceManyField
                        label="Посты"
                        reference="days_posts"
                        target="day_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="post_id"
                                reference="posts"
                                link={false}
                            >
                                <ChipField source="title" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"days_posts"}
                        label={"Прикрепить пост"}
                    />

                    <ReferenceManyField
                        label="Праздники"
                        reference="days_holidays"
                        target="day_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="holiday_id"
                                reference="holidays"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"days_holidays"}
                        label={"Прикрепить праздник"}
                    />
                </Connections>
            </TabbedShowLayout>
        </Show>
    );
}
