import React from "react";
import { get, has } from "lodash";
import {
    Datagrid,
    DateField,
    Pagination,
    TextField,
    List,
    ShowButton,
    FunctionField,
    useRecordContext
} from "react-admin";
import { withRouter } from "react-router";
import { Box } from '@material-ui/core';

import CheckPermissions from "../../../../hoc/CheckPermissions";
import { PostFilterForm } from "./filters";

const ListActions = () => (
    <Box width="100%">
        <PostFilterForm />
    </Box>
);

function UnmappedListView(props) {
    const PostPagination = (props) => (
        <Pagination
            rowsPerPageOptions={[
                10,
                25,
                50,
                100,
                300,
                500,
                700,
                900,
                1100,
                1300,
                1500,
                1700,
            ]}
            {...props}
        />
    );

    const resourseMap = {
        news: "Новость",
        media_articles: "Статья",
        v_media_videos: "Видео",
        broadcasts: "Трансляция",
    };

    return (
        <List
            sort={{ field: "id", order: "DESC" }}
            pagination={<PostPagination />}
            actions={<ListActions />}
            {...props}
        >
            <Datagrid isRowSelectable={() => false}>
                <TextField source="id" label={"Id"} />
                <TextField source="user" label={"Пользователь"} />
                <DateField
                    source="created_at"
                    label="Дата/время действия"
                    showTime={true}
                />
                <TextField source="action_cyrillic" label={"Действие"} />
                <FunctionField
                    label={"Таблица"}
                    render={(record) => resourseMap[record.resource_table]}
                />
                <ResourseLink
                    source="resource_id"
                    label={"ID-записи"}
                    {...props}
                />
                <TextField source="prev_value" label={"Предыдущее значение"} />
                <TextField source="current_value" label={"Текущее значение"} />
                <ActionValue label={"Действие администратора"} />
                <ShowButton />
            </Datagrid>
        </List>
    );
}

const ListView = withRouter(
    CheckPermissions(UnmappedListView, "admin_action_logs")
);

export { ListView };

function ResourseLink(props) {
    const { resource_id, resource_table } = get(props, "record", {});

    function navigate(event) {
        event.preventDefault();

        props.history.push(`/${resource_table}/${resource_id}/show`);
    }

    return (
        <a href={"/"} onClick={navigate}>
            {resource_id}
        </a>
    );
}

const ActionValue = (props) => {
    const record = useRecordContext(props);

    let actionValue = ""; 
                            
    if(has(record, "current_value.enabled")) { 
        actionValue = record["current_value"].enabled ? "Вкл" : "Выкл";
    }

    if(has(record, "current_value.begins_at")) { 
        actionValue = `Обратный отчет изменён на: ${get(record, "current_value.begins_at").split("T").join(" ")}`;
    }

    return <span>{actionValue}</span>;
}