import React from "react";
import {
    TabbedForm,
    Edit,
    ReferenceInput,
    SelectInput,
    TopToolbar,
    ListButton,
    ShowButton,
    TextInput,
    FormTab,
    required,
    NumberInput,
    usePermissions,
} from "react-admin";
import Grid from "@material-ui/core/Grid";

import { SEO } from "../../../Layout/Tabs/Edit";
import withPermissions from "../../../../hoc/CheckPermissions";
import InputS3Uploader from "../../../Form/InputS3Uploader";
import { tabListByRole, transformDataByRole } from "../../../../utils";
import { BottomToolbar } from "../../../Layout/Actions";

function UnmappedEditView(props) {
    const { loaded, permissions: role } = usePermissions();

    const PostTitle = ({ record }) => {
        return (
            <span>
                Редактируется {record ? `"${record.page_title_full}"` : ""}
            </span>
        );
    };
    const PostEditActions = ({ basePath, data }) => (
        <TopToolbar>
            <ShowButton basePath={basePath} record={data} />
            <ListButton basePath={basePath} />
        </TopToolbar>
    );

    const main = (
        <FormTab label={"Основное"} {...props}>
            <div style={{ width: "100%" }}>
                <Grid container>
                    <Grid
                        item
                        xs={8}
                        style={{
                            paddingRight: 24,
                            borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                        }}
                    >
                        <TextInput
                            source={"page_title_full"}
                            label={"Наименование"}
                            style={{ width: "100%" }}
                            validate={[required()]}
                            resettable
                        />
                        <TextInput
                            source={"page_title_short"}
                            label={"Краткое наименование"}
                            style={{ width: "100%" }}
                            resettable
                        />
                        <TextInput
                            source={"teaser"}
                            label={"Тизер новости"}
                            style={{ width: "100%" }}
                            validate={[required()]}
                            resettable
                        />
                        <TextInput
                            source={"author"}
                            label={"Автор"}
                            style={{ width: "100%" }}
                            validate={[required()]}
                            resettable
                        />
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        style={{ paddingLeft: 24, marginBottom: 24 }}
                    >
                        <NumberInput
                            source={"sort_order"}
                            label={"Сортировка"}
                            style={{ width: "100%" }}
                            validate={[required()]}
                            resettable
                        />
                        <InputS3Uploader
                            source={"main_image"}
                            label={"Главное изображение"}
                            validate={[required()]}
                        />
                        <InputS3Uploader
                            source={"main_image_preview"}
                            label={"Превью изображение"}
                        />
                    </Grid>
                </Grid>
            </div>
            <Grid xs={6}>
                <ReferenceInput
                    source={"voting_id"}
                    reference={"votings"}
                    label={"Голосование"}
                    validate={[required()]}
                    style={{
                        width: "100%",
                    }}
                >
                    <SelectInput optionText="page_title_full" />
                </ReferenceInput>
            </Grid>
        </FormTab>
    );
    const seo = <SEO />;

    return (
        loaded && (
            <Edit
                title={<PostTitle />}
                actions={<PostEditActions />}
                transform={(data) => transformDataByRole(role, data)}
                {...props}
            >
                <TabbedForm toolbar={<BottomToolbar role={role} {...props} />}>
                    {tabListByRole({
                        seo,
                        admin: [main, seo],
                    }).get(role)}
                </TabbedForm>
            </Edit>
        )
    );
}

const EditView = withPermissions(UnmappedEditView);

export { EditView };
