import React from "react";

import List from "../../../Layout/List";
import CheckPermissions from "../../../../hoc/CheckPermissions";

function UnmappedListView(props) {
    return <List {...props} sort={{ field: "id", order: "DESC" }} />;
}

const ListView = CheckPermissions(UnmappedListView, "city");

export { ListView };
