import React from "react";
import {
    TabbedForm,
    Edit,
    ReferenceInput,
    SelectInput,
    TopToolbar,
    ListButton,
    ShowButton,
    TextInput,
    usePermissions,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

import {
    SEO,
    Content,
    Main,
    Location,
    BuildingAttributes,
} from "../../../Layout/Tabs/Edit";
import { appeal_to_visitors } from "../CreateView";
import withPermissions from "../../../../hoc/CheckPermissions";
import { tabListByRole, transformDataByRole } from "../../../../utils";
import { BottomToolbar } from "../../../Layout/Actions/Edit";

function UnmappedEditView(props) {
    const { loaded, permissions: role } = usePermissions();

    const PostTitle = ({ record }) => {
        return (
            <span>Редактируется {record ? `"${record.title_short}"` : ""}</span>
        );
    };
    const PostEditActions = ({
        basePath,
        className,
        data,
        hasList,
        hasShow,
        resource,
    }) => (
        <TopToolbar>
            <ShowButton basePath={basePath} record={data} />
            <ListButton basePath={basePath} />
        </TopToolbar>
    );

    const main = (
        <Main ShowThreeDModelField={true} showPublishedAtField={true}>
            <TextInput
                source={"address"}
                label={"Адрес"}
                style={{ width: "100%" }}
                resettable
            />
            <TextInput
                source={"telephone"}
                label={"Телефон"}
                style={{ width: "100%" }}
                resettable
            />
            <TextInput
                source={"openning_hours"}
                label={"Часы работы "}
                style={{ width: "50%" }}
                resettable
            />
            <Tooltip
                disableFocusListener
                placement={"top"}
                title="Дни указываются с использованием следующих двухбуквенных
                        комбинаций: Mo, Tu, We, Th, Fr, Sa, Su. Время указывается с
                        помощью 24:00 часового фромата. Пример: Tu,Th
                        16:00-20:00. Если строение открыто 7 дней в неделю, то
                        Часы работы может быть определено как: Mo-Su"
            >
                <Button>?</Button>
            </Tooltip>
            <Grid xs={6}>
                <ReferenceInput
                    source={"region_id"}
                    reference={"regions"}
                    label={"Регион"}
                    allowEmpty
                    style={{
                        width: "100%",
                    }}
                >
                    <SelectInput optionText="title_full" emptyValue={null} />
                </ReferenceInput>
                <ReferenceInput
                    source={"city_id"}
                    reference={"v_portal_admin_cities"}
                    label={"Город"}
                    allowEmpty
                    style={{
                        width: "100%",
                    }}
                >
                    <SelectInput optionText="title_full" emptyValue={null} />
                </ReferenceInput>
            </Grid>
        </Main>
    );
    const location = <Location />;
    const seo = <SEO />;
    const content = <Content before={appeal_to_visitors} />;
    const buildingAttributes = <BuildingAttributes />;

    return (
        loaded && (
            <Edit
                title={<PostTitle />}
                actions={<PostEditActions />}
                transform={(data) => transformDataByRole(role, data)}
                {...props}
            >
                <TabbedForm toolbar={<BottomToolbar role={role} {...props} />}>
                    {tabListByRole({
                        seo,
                        admin: [
                            main,
                            location,
                            seo,
                            content,
                            buildingAttributes,
                        ],
                    }).get(role)}
                </TabbedForm>
            </Edit>
        )
    );
}

const EditView = withPermissions(UnmappedEditView, "monasteries");

export { EditView };
