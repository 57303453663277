import React, { Component } from "react";
import { addField } from "ra-core";
import { SnackbarContent, Button } from "@material-ui/core";
import uuid from "uuid";
import L from "leaflet";
// eslint-disable-next-line no-unused-vars
import Draw from "leaflet-draw";

import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";

import markerIcon from "./marker.svg";

class InputMap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: `leaflet-map-input-${uuid()}`,
            center: [56.326887, 44.005986],
            zoom: 8,
            icon: new L.Icon({
                iconUrl: markerIcon,
                iconAnchor: [37, 70],
            }),
            markers: [],
            warning: false,
        };
    }

    componentDidMount() {
        const { input, onChange = () => Function } = this.props;
        const map = new L.Map(this.state.id, {
            center: new L.LatLng(...this.state.center),
            zoom: this.state.zoom,
        });
        const mainLayer = L.featureGroup().addTo(map);

        L.Icon.Default.imagePath = this.state.icon;

        this.setState({ map, mainLayer }, () => {
            L.drawLocal = {
                draw: {
                    toolbar: {
                        // #TODO: this should be reorganized where actions are nested in actions
                        // ex: actions.undo  or actions.cancel
                        actions: {
                            title: "Отменить рисование",
                            text: "Отменить",
                        },
                        finish: {
                            title: "Завершить рисование",
                            text: "Завершить",
                        },
                        undo: {
                            title: "Удалить последнюю нарисованную точку",
                            text: "Удалить последнюю точку",
                        },
                        buttons: {
                            polyline: "Нарисовать ломаную",
                            polygon: "Добавить произвольную область",
                            rectangle: "Нарисовать многоугольник",
                            circle: "Нарисовать круг",
                            marker: "Установить точку на карте",
                            circlemarker: "Draw a circlemarker",
                        },
                    },
                    handlers: {
                        circle: {
                            tooltip: {
                                start: "Click and drag to draw circle.",
                            },
                            radius: "Радиус",
                        },
                        circlemarker: {
                            tooltip: {
                                start: "Click map to place circle marker.",
                            },
                        },
                        marker: {
                            tooltip: {
                                start:
                                    "Кликните по карте для установки маркера",
                            },
                        },
                        polygon: {
                            tooltip: {
                                start: "Добавить точку",
                                cont: "Добавить промежуточную точку",
                                end: "Добавить закрывающу точку",
                            },
                        },
                        polyline: {
                            error:
                                "<strong>Error:</strong> shape edges cannot cross!",
                            tooltip: {
                                start: "Click to start drawing line.",
                                cont: "Click to continue drawing line.",
                                end: "Click last point to finish line.",
                            },
                        },
                        rectangle: {
                            tooltip: {
                                start: "Click and drag to draw rectangle.",
                            },
                        },
                        simpleshape: {
                            tooltip: {
                                end: "Release mouse to finish drawing.",
                            },
                        },
                    },
                },
                edit: {
                    toolbar: {
                        actions: {
                            save: {
                                title: "Сохранить измененния",
                                text: "Сохранить",
                            },
                            cancel: {
                                title:
                                    "Отменить редактирование и сбросить все изменения",
                                text: "Отменить",
                            },
                            clearAll: {
                                title: "Очистить все поля",
                                text: "Очистить всё",
                            },
                        },
                        buttons: {
                            edit: "Редактировать элементы",
                            editDisabled: "No layers to edit",
                            remove: "Удалить элементы",
                            removeDisabled: "Нет слоёв для удаления",
                        },
                    },
                    handlers: {
                        edit: {
                            tooltip: {
                                text:
                                    "Перетащите точки на карте или измените геометрию объектов",
                                subtext:
                                    "Нажмите отмена, чтобы сбросить изменения",
                            },
                        },
                        remove: {
                            tooltip: {
                                text:
                                    "Кликните по объекту который нужно удалить",
                            },
                        },
                    },
                },
            };

            L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
                maxZoom: 18,
            }).addTo(this.state.map);

            L.geoJSON(input.value, {
                pointToLayer: (layer, latlng) => {
                    map.setView(latlng);
                    return L.marker(latlng, {
                        icon: this.state.icon,
                    });
                },
                onEachFeature: (feature, layer) => {
                    this.state.mainLayer.addLayer(layer);
                },
            });

            const drawControl = new L.Control.Draw({
                position: "topright",
                draw: {
                    polyline: false,
                    circlemarker: false,
                    polygon: true,
                    circle: false,
                    marker: {
                        icon: this.state.icon,
                    },
                    rectangle: false,
                },
                edit: {
                    featureGroup: this.state.mainLayer,
                    remove: true,
                },
            });
            this.state.map.addControl(drawControl);

            this.state.map
                .on(L.Draw.Event.CREATED, ({ layer, layers, layerType }) => {
                    if (layerType === "marker") {
                        let markerCount = 0;

                        this.state.mainLayer.eachLayer((item) => {
                            markerCount += !!(item instanceof L.Marker);
                        });

                        if (markerCount < 1) {
                            this.state.mainLayer.addLayer(layer);
                        } else {
                            this.setState({
                                warning: "Можно добавить только один маркер!",
                            });
                        }

                        input.onChange(this.serializeData);
                    }
                    if (layerType === "polygon") {
                        /* let polygonCount = 0;

						this.state.mainLayer.eachLayer(item => {
							polygonCount += !!(item instanceof L.Polygon);
                        }); */

                        this.state.mainLayer.addLayer(layer);

                        /* if(polygonCount < 1) { // Условие если больше одного то не добавлять
							this.state.mainLayer.addLayer(layer);
						} else {
							this.setState({ warning: "Можно добавить только один полигон!"});
						} */

                        onChange(this.serializeData);
                        input.onChange(this.serializeData);
                    }
                })
                .on(L.Draw.Event.EDITED, () => {
                    onChange(this.serializeData);
                    input.onChange(this.serializeData);
                })
                .on(L.Draw.Event.DELETED, () => {
                    onChange(this.serializeData);
                    input.onChange(this.serializeData);
                });
        });
    }

    componentDidUpdate() {
        if (this.state.map) {
            this.state.map.invalidateSize(true);
        }
    }

    get serializeData() {
        const collection = {
            type: "FeatureCollection",
            features: [],
        };

        this.state.mainLayer.eachLayer((layer) =>
            collection.features.push(layer.toGeoJSON())
        );

        return collection;
    }

    render() {
        return (
            <>
                <style>
                    {`
							.leaflet-control-attribution {
								display: none !important;
							}
						`}
                </style>
                <div
                    id={this.state.id}
                    style={{ minHeight: 600, marginTop: "1.5rem" }}
                />
                {this.state.warning && (
                    <SnackbarContent
                        message={this.state.warning}
                        style={{
                            backgroundColor: "#d32f2f",
                            padding: "6px 16px",
                            position: "fixed",
                            right: "2rem",
                            bottom: "2rem",
                            font: "400 16px system-ui",
                        }}
                        action={
                            <Button
                                style={{ color: "#ffffff" }}
                                onClick={() =>
                                    this.setState({ warning: false })
                                }
                            >
                                Закрыть
                            </Button>
                        }
                    />
                )}
            </>
        );
    }
}

export default addField(InputMap);
