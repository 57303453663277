import React from "react";
import { Admin, Resource } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import raLocaleRu from "ra-language-russian";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { get, capitalize } from "lodash";
import { VKProvider } from "./contexts";
import {
    LocalMovies,
    Receipt,
    Map,
    PhotoAlbum,
    PictureAsPdf,
    Flag,
    PhotoCamera,
    Book,
    Videocam,
    Audiotrack,
    LibraryBooks,
    Timer,
    Subway,
    DateRange,
    Home,
    Nature,
    PictureInPicture,
    ThreeDRotation,
    FilterBAndW,
    LocationCity,
    Pages as PageIcon,
    Radio,
    Tv,
    FiberNew,
    BubbleChart,
    MailOutline,
    GpsFixed,
    Notifications,
    People,
    Block,
    Build as BuildIcon,
    ViewCarousel,
    Settings,
    Category,
    Museum,
    ThumbsUpDown,
    SpeakerGroup,
    Edit,
} from "@material-ui/icons";

import {
    ArchitecturaStyles,
    AudioguideCategories,
    BelltowerHoludays,
    BelltowerSaints,
    Building,
    Build,
    Monastery,
    CathedralsHolidays,
    CathedralsSaints,
    CategoriesNews,
    CategoriesArticles,
    CategoriesTextPages,
    City,
    CitiesTours,
    CityObjectsHolidays,
    CityObjectsSaints,
    ChapelHolidays,
    ChapelSaints,
    ChurchHolidays,
    ChurchSaints,
    CompetitionProject,
    HermitageHolidays,
    HermitageSaints,
    MonasteryHolidays,
    MonasterySaints,
    MuseumHolidays,
    MuseumSaints,
    ShrineHolidays,
    ShrineSaints,
    TempleHolidays,
    TempleSaint,
    Video,
    TvObraz,
    News,
    Region,
    Library,
    Temple,
    Shrine,
    Calendar,
    Pages,
    CrowdfundingProjects,
    Radios,
    Article,
    Categories,
    Main,
    Tour,
    Authors,
    FixedContent,
    CitiesCrowdfundingProjects,
    MonasteriesCrowdfundingProjects,
    CathedralsCrowdfundingProjects,
    Names,
    NewsBelltowers,
    NewsChapels,
    NewsCathedrals,
    NewsChurches,
    NewsCities,
    NewsCityObjects,
    NewsHermitages,
    NewsMonasteries,
    NewsMuseums,
    NewsTemples,
    NewsWells,
    NewsSerafimProjects,
    NewsTextPages,
    Mails,
    Targetings,
    NewsTargetings,
    ArticlesTargetings,
    ArticlesBelltowers,
    ArticlesChapels,
    ArticlesCathedrals,
    ArticlesChurches,
    ArticlesCities,
    ArticlesCityObjects,
    ArticlesHermitages,
    ArticlesMonasteries,
    ArticlesMuseums,
    ArticlesTemples,
    ArticlesWells,
    LibraryCities,
    LibraryChurches,
    LibraryMonasteries,
    SerafimProjectsMediaVideos,
    MediaVideoCategories,
    FixedContentTargetings,
    VirtualTours,
    BelltowersVirtualTours,
    CathedralsVirtualTours,
    ChapelsVirtualTours,
    ChurchesVirtualTours,
    CitiesVirtualTours,
    CityObjectsVirtualTours,
    HermitagesVirtualTours,
    MonasteriesVirtualTours,
    MuseumsVirtualTours,
    ShrinesVirtualTours,
    TemplesVirtualTours,
    WellsVirtualTours,
    SerafimProjects,
    Events,
    Banners,
    VideoCaegories,
    DaysPosts,
    DaysHolidays,
    Posts,
    Ikons,
    SaintBook,
    AbTest,
    Broadcasts,
    BroadcastSchedules,
    Menus,
    LinkedNews,
    AudioGuides,
    AudioGuidesBelltowers,
    AudioGuidesCathedrals,
    AudioGuidesChapels,
    AudioGuidesChurches,
    AudioGuidesCities,
    AudioGuidesCityObjects,
    AudioGuidesHermitages,
    AudioGuidesMonasteries,
    AudioGuidesMuseums,
    AudioGuidesShrines,
    AudioGuidesTemples,
    AudioGuidesWells,
    StopWords,
    Votings,
    Holidays,
    PageSettings,
    LinkedMediaArticles,
    AdminActionLogs,
    RadioRecords,
    NewsMediaArticles,
    MediaArticlesNews,
    MediaArticlesTextPages,
    MediaArticlesSerafimProjects
} from "./components/Models";
import Layout from "./components/Layout";
import { DataProvider, AuthProvider, PermissionProvider } from "./providers";
import { FacebookProvider } from "react-facebook";

import "../src/styles/common.scss";
import permissions from "./providers/Permission/permissions";

const messages = {
    ru: raLocaleRu,
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "ru");

function App() {
    function getPermissions() {
        let current_permissions = null;
        try {
            current_permissions =
                permissions[localStorage.getItem("permission")];
        } catch (error) {
            console.warn("Invalid permissions object", error);
        }
        return current_permissions;
    }

    function allowAction(action) {
        const permissions = getPermissions();

        return get(permissions, `allow.models.common.${action}`, {});
    }

    function allowModelAction(model_object, action) {
        if (allowAction(action)) {
            return model_object[`${capitalize(action)}View`];
        }
        return false;
    }

    function allowedActions(model_object) {
        return {
            list: allowModelAction(model_object, "list"),
            create: allowModelAction(model_object, "create"),
            edit: allowModelAction(model_object, "edit"),
            show: allowModelAction(model_object, "show"),
        };
    }

    function resourceProps(model_object) {
        const res = {
            ...allowedActions(model_object),
            // ...authPermanentFilter(model_object),
        };
        return res;
    }

    function allowResources() {
        const permissions = getPermissions();

        return {
            all: get(permissions, "allowResourcesAll", false),
            filter: get(permissions, "allowResources", []),
        };
    }

    const allowsConfig = allowResources();

    const theme = createTheme({
        palette: {
            secondary: {
                main: "#2196f3",
            },
        },
    });

    return (
        <VKProvider>
            <FacebookProvider appId={process.env.REACT_APP_FACEBOOK_API_KEY}>
                <MuiThemeProvider theme={theme}>
                    <PermissionProvider>
                    <Admin
                        /* loginPage={Login} */
                        dataProvider={DataProvider}
                        authProvider={AuthProvider}
                        i18nProvider={i18nProvider}
                        layout={Layout}
                    >
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("front_build_requests") ? (
                            <Resource
                                name="v_front_build_requests"
                                {...resourceProps(Build)}
                                options={{ label: "Сборка" }}
                                icon={BuildIcon}
                            />
                        ) : (
                            <></>
                        )}
                            {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("main_page") ? (
                            <Resource
                                name="main_page"
                                {...resourceProps(Main)}
                                options={{ label: "Основные настройки" }}
                                icon={Settings}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("banners") ? (
                            <Resource
                                name="banners"
                                {...resourceProps(Banners)}
                                options={{ label: "Баннеры" }}
                                icon={ViewCarousel}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes(
                            "main_page_fixed_contents"
                        ) ? (
                            <Resource
                                name="main_page_fixed_contents"
                                options={{ label: "Контент для главной" }}
                                {...resourceProps(FixedContent)}
                                icon={Flag}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("news") ? (
                            <Resource
                                name="news"
                                {...resourceProps(News)}
                                filter={{ state: "copyrighting" }}
                                options={{ label: "Новости" }}
                                icon={FiberNew}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("categories") ? (
                            <Resource
                                name="categories"
                                {...resourceProps(Categories)}
                                options={{ label: "Категории" }}
                                icon={Category}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("cities") ? (
                            <Resource
                                name="v_portal_admin_cities"
                                {...resourceProps(City)}
                                options={{ label: "Города" }}
                                icon={LocationCity}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("votings") ? (
                            <Resource
                                name="votings"
                                {...resourceProps(Votings)}
                                options={{ label: "Голосования" }}
                                icon={ThumbsUpDown}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("competition_projects") ? (
                            <Resource
                                name="competition_projects"
                                {...resourceProps(CompetitionProject)}
                                options={{ label: "Проекты для голосования" }}
                                icon={BubbleChart}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("regions") ? (
                            <Resource
                                name="regions"
                                {...resourceProps(Region)}
                                options={{ label: "Регионы" }}
                                icon={Map}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("virtual_tours") ? (
                            <Resource
                                name="virtual_tours"
                                {...resourceProps(VirtualTours)}
                                options={{ label: "Виртуальные туры" }}
                                icon={ThreeDRotation}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("monasteries") ? (
                            <Resource
                                name="monasteries"
                                {...resourceProps(Monastery)}
                                options={{ label: "Монастыри" }}
                                icon={FilterBAndW}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("cathedrals") ? (
                            <Resource
                                name="cathedrals"
                                {...resourceProps(Temple)}
                                options={{ label: "Соборы" }}
                                icon={FilterBAndW}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("temples") ? (
                            <Resource
                                name="temples"
                                {...resourceProps(Temple)}
                                options={{ label: "Храмы" }}
                                icon={FilterBAndW}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("churches") ? (
                            <Resource
                                name="churches"
                                {...resourceProps(Temple)}
                                options={{ label: "Церкви" }}
                                icon={FilterBAndW}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("belltowers") ? (
                            <Resource
                                name="belltowers"
                                {...resourceProps(Temple)}
                                options={{ label: "Колокольни" }}
                                icon={Notifications}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("wells") ? (
                            <Resource
                                name="wells"
                                {...resourceProps(Temple)}
                                options={{ label: "Источники" }}
                                icon={Nature}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("chapels") ? (
                            <Resource
                                name="chapels"
                                {...resourceProps(Temple)}
                                options={{ label: "Часовни" }}
                                icon={Timer}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("shrines") ? (
                            <Resource
                                name="shrines"
                                {...resourceProps(Shrine)}
                                options={{ label: "Святыни" }}
                                icon={PhotoAlbum}
                            />
                        ) : (
                            <></>
                        )}
                        {/* <Resource name="p" {...resourceProps(City)} options={{ label: 'Богослужения' }} />
                    <Resource name="pp" {...resourceProps(City)} options={{ label: 'Молитвы' }} /> */}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("days") ? (
                            <Resource
                                name="days"
                                {...resourceProps(Calendar)}
                                options={{ label: "Календарь" }}
                                icon={DateRange}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("holidays") ? (
                            <Resource
                                name="holidays"
                                {...resourceProps(Holidays)}
                                options={{ label: "Праздники" }}
                                icon={LocationCity}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("tours") ? (
                            <Resource
                                name="tours"
                                {...resourceProps(Tour)}
                                options={{ label: "Туры" }}
                                icon={Subway}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("hermitages") ? (
                            <Resource
                                name="hermitages"
                                {...resourceProps(Temple)}
                                options={{ label: "Скиты" }}
                                icon={Home}
                            />
                        ) : (
                            <></>
                        )}
                        <Resource
                            name="cities_tours"
                            {...resourceProps(CitiesTours)}
                        />
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("media_books") ? (
                            <Resource
                                name="media_books"
                                {...resourceProps(Library)}
                                options={{ label: "Библиотека" }}
                                icon={LibraryBooks}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("media_audios") ? (
                            <Resource
                                name="v_media_audios"
                                {...resourceProps(Library)}
                                options={{ label: "Аудио" }}
                                icon={Audiotrack}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("media_audios") ? (
                            <Resource
                                name="v_media_audio_guides"
                                key={"media_audios_guides"}
                                {...resourceProps(AudioGuides)}
                                options={{ label: "Аудио экскурсии" }}
                                icon={SpeakerGroup}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("audio_categories") ? (
                            <Resource
                                name="audio_categories"
                                options={{ label: "Категории аудиогидов" }}
                                icon={Receipt}
                                {...resourceProps(AudioguideCategories)}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("media_videos") ? (
                            <Resource
                                name="v_media_videos"
                                {...resourceProps(Video)}
                                options={{ label: "Серафимовские видео" }}
                                icon={LocalMovies}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("media_videos") ? (
                            <Resource
                                name="v_tv_obraz_videos"
                                {...resourceProps(TvObraz)}
                                options={{ label: "Видеотека тв образа" }}
                                icon={LocalMovies}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("media_photos") ? (
                            <Resource
                                name="media_photos"
                                {...resourceProps(Library)}
                                options={{ label: "Фото" }}
                                icon={PhotoCamera}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("media_articles") ? (
                            <Resource
                                name="media_articles"
                                {...resourceProps(Article)}
                                options={{ label: "Статьи" }}
                                icon={PictureAsPdf}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("persons") ? (
                            <Resource
                                name="persons"
                                {...resourceProps(Authors)}
                                options={{ label: "Персоны" }}
                                icon={People}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("authors") ? (
                            <Resource
                                name="authors"
                                {...resourceProps(Authors)}
                                options={{ label: "Авторы" }}
                                icon={People}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("media_periodics") ? (
                            <Resource
                                name="media_periodics"
                                {...resourceProps(Library)}
                                options={{ label: "Журналы" }}
                                icon={PictureInPicture}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("books") ? (
                            <Resource
                                name="books"
                                {...resourceProps(Library)}
                                options={{ label: "Книги" }}
                                icon={Book}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("museums") ? (
                            <Resource
                                name="museums"
                                {...resourceProps(Building)}
                                options={{ label: "Музеи" }}
                                icon={Museum}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("city_objects") ? (
                            <Resource
                                name="city_objects"
                                {...resourceProps(Building)}
                                options={{ label: "Городские объекты" }}
                                icon={LocationCity}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("text_pages") ? (
                            <Resource
                                name="text_pages"
                                {...resourceProps(Pages)}
                                options={{ label: "Текстовые страницы" }}
                                icon={PageIcon}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("crowdfunding_projects") ? (
                            <Resource
                                name="crowdfunding_projects"
                                {...resourceProps(CrowdfundingProjects)}
                                options={{ label: "Краундфандинг проекты" }}
                                icon={Flag}
                            />
                        ) : (
                            <></>
                        )}
                        <style>
                            {`
                                    a[href="#/components"] {
                                        display: none;
                                    }
                                `}
                        </style>
                        <Resource name="components" {...resourceProps(Authors)} />
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("radio") ? (
                            <Resource
                                name="radio"
                                {...resourceProps(Radios)}
                                options={{ label: "Радиостанции" }}
                                icon={Radio}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("v_media_radio_records") ? (
                            <Resource
                                name="v_media_radio_records"
                                key={"media_radio_records"}
                                {...resourceProps(RadioRecords)}
                                options={{ label: "Радиоэфиры" }}
                                icon={SpeakerGroup}
                            />
                        ) : (
                            <></>
                        )}
                        <style>
                            {`a[href="#/cities_crowdfunding_projects"] {
                                display: none;
                            }
                            `}
                        </style>
                        <Resource
                            name="cities_crowdfunding_projects"
                            {...resourceProps(CitiesCrowdfundingProjects)}
                        />
                        <style>
                            {`a[href="#/crowdfunding_projects_monasteries"] {
                                display: none;
                            }
                            `}
                        </style>
                        <Resource
                            name="crowdfunding_projects_monasteries"
                            {...resourceProps(MonasteriesCrowdfundingProjects)}
                        />
                        <style>
                            {`a[href="#/cathedrals_crowdfunding_projects"] {
                                display: none;
                            }
                            `}
                        </style>
                        <Resource
                            name="cathedrals_crowdfunding_projects"
                            {...resourceProps(CathedralsCrowdfundingProjects)}
                        />
                        <Resource
                            name="belltowers_news"
                            {...resourceProps(NewsBelltowers)}
                        />
                        <Resource
                            name="chapels_news"
                            {...resourceProps(NewsChapels)}
                        />
                        <Resource
                            name="cathedrals_news"
                            {...resourceProps(NewsCathedrals)}
                        />
                        <Resource
                            name="churches_news"
                            {...resourceProps(NewsChurches)}
                        />
                        <Resource
                            name="cities_news"
                            {...resourceProps(NewsCities)}
                        />
                        <Resource
                            name="city_objects_news"
                            {...resourceProps(NewsCityObjects)}
                        />
                        <Resource
                            name="hermitages_news"
                            {...resourceProps(NewsHermitages)}
                        />
                        <Resource
                            name="monasteries_news"
                            {...resourceProps(NewsMonasteries)}
                        />
                        <Resource
                            name="museums_news"
                            {...resourceProps(NewsMuseums)}
                        />
                        <Resource
                            name="news_temples"
                            {...resourceProps(NewsTemples)}
                        />
                        <Resource name="news_wells" {...resourceProps(NewsWells)} />
                        <Resource
                            name="news_serafim_projects"
                            {...resourceProps(NewsSerafimProjects)}
                        />
                        <Resource
                            name="news_text_pages"
                            {...resourceProps(NewsTextPages)}
                        />
                        <Resource
                            name="belltowers_media_audios"
                            {...resourceProps(AudioGuidesBelltowers)}
                        />
                        <Resource
                            name="cathedrals_media_audios"
                            {...resourceProps(AudioGuidesCathedrals)}
                        />
                        <Resource
                            name="chapels_media_audios"
                            {...resourceProps(AudioGuidesChapels)}
                        />
                        <Resource
                            name="churches_media_audios"
                            {...resourceProps(AudioGuidesChurches)}
                        />
                        <Resource
                            name="cities_media_audios"
                            {...resourceProps(AudioGuidesCities)}
                        />
                        <Resource
                            name="city_objects_media_audios"
                            {...resourceProps(AudioGuidesCityObjects)}
                        />
                        <Resource
                            name="hermitages_media_audios"
                            {...resourceProps(AudioGuidesHermitages)}
                        />
                        <Resource
                            name="monasteries_media_audios"
                            {...resourceProps(AudioGuidesMonasteries)}
                        />
                        <Resource
                            name="media_audios_museums"
                            {...resourceProps(AudioGuidesMuseums)}
                        />
                        <Resource
                            name="media_audios_shrines"
                            {...resourceProps(AudioGuidesShrines)}
                        />
                        <Resource
                            name="media_audios_temples"
                            {...resourceProps(AudioGuidesTemples)}
                        />
                        <Resource
                            name="media_audios_wells"
                            {...resourceProps(AudioGuidesWells)}
                        />
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("mail_templates") ? (
                            <Resource
                                name="mail_templates"
                                {...resourceProps(Mails)}
                                options={{ label: "Шаблоны email" }}
                                icon={MailOutline}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("names_for_trebs") ? (
                            <Resource
                                name="names_for_trebs"
                                {...resourceProps(Names)}
                                options={{ label: "Православные имена" }}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("targetings") ? (
                            <Resource
                                name="targetings"
                                {...resourceProps(Targetings)}
                                options={{ label: "Таргетинг" }}
                                icon={GpsFixed}
                            />
                        ) : (
                            <></>
                        )}
                        <Resource
                            name="news_targetings"
                            {...resourceProps(NewsTargetings)}
                        />
                        <Resource
                            name="media_articles_targetings"
                            {...resourceProps(ArticlesTargetings)}
                        />
                        <Resource
                            name="belltowers_media_articles"
                            {...resourceProps(ArticlesBelltowers)}
                        />
                        <Resource
                            name="chapels_media_articles"
                            {...resourceProps(ArticlesChapels)}
                        />
                        <Resource
                            name="cathedrals_media_articles"
                            {...resourceProps(ArticlesCathedrals)}
                        />
                        <Resource
                            name="churches_media_articles"
                            {...resourceProps(ArticlesChurches)}
                        />
                        <Resource
                            name="cities_media_articles"
                            {...resourceProps(ArticlesCities)}
                        />
                        <Resource
                            name="city_objects_media_articles"
                            {...resourceProps(ArticlesCityObjects)}
                        />
                        <Resource
                            name="hermitages_media_articles"
                            {...resourceProps(ArticlesHermitages)}
                        />
                        <Resource
                            name="monasteries_media_articles"
                            {...resourceProps(ArticlesMonasteries)}
                        />
                        <Resource
                            name="media_articles_museums"
                            {...resourceProps(ArticlesMuseums)}
                        />
                        <Resource
                            name="media_articles_temples"
                            {...resourceProps(ArticlesTemples)}
                        />
                        <Resource
                            name="media_articles_wells"
                            {...resourceProps(ArticlesWells)}
                        />
                        <Resource
                            name="media_articles_text_pages"
                            {...resourceProps(MediaArticlesTextPages)}
                        />
                        <Resource
                            name="media_articles_serafim_projects"
                            {...resourceProps(MediaArticlesSerafimProjects)}
                        />
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("serafim_projects") ? (
                            <Resource
                                name="serafim_projects"
                                {...resourceProps(SerafimProjects)}
                                options={{ label: "Серафимовские проекты" }}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("page_settings") ? (
                            <Resource
                                name="page_settings"
                                {...resourceProps(PageSettings)}
                                options={{ label: "Настройка страниц списков" }}
                            />
                        ) : (
                            <></>
                        )}
                        <Resource
                            name="cities_media_audios"
                            {...resourceProps(LibraryCities)}
                        />
                        <Resource
                            name="cities_media_books"
                            {...resourceProps(LibraryCities)}
                        />
                        <Resource
                            name="cities_media_periodics"
                            {...resourceProps(LibraryCities)}
                        />
                        <Resource
                            name="cities_media_photos"
                            {...resourceProps(LibraryCities)}
                        />
                        <Resource
                            name="cities_media_videos"
                            {...resourceProps(LibraryCities)}
                        />
                        <Resource
                            name="churches_media_audios"
                            {...resourceProps(LibraryChurches)}
                        />
                        <Resource
                            name="churches_media_books"
                            {...resourceProps(LibraryChurches)}
                        />
                        <Resource
                            name="churches_media_periodics"
                            {...resourceProps(LibraryChurches)}
                        />
                        <Resource
                            name="churches_media_photos"
                            {...resourceProps(LibraryChurches)}
                        />
                        <Resource
                            name="churches_media_videos"
                            {...resourceProps(LibraryChurches)}
                        />
                        <Resource
                            name="monasteries_media_audios"
                            {...resourceProps(LibraryMonasteries)}
                        />
                        <Resource
                            name="monasteries_media_books"
                            {...resourceProps(LibraryMonasteries)}
                        />
                        <Resource
                            name="monasteries_media_periodics"
                            {...resourceProps(LibraryMonasteries)}
                        />
                        <Resource
                            name="monasteries_media_photos"
                            {...resourceProps(LibraryMonasteries)}
                        />
                        <Resource
                            name="monasteries_media_videos"
                            {...resourceProps(LibraryMonasteries)}
                        />
                        <Resource
                            name="serafim_projects_media_videos"
                            {...resourceProps(SerafimProjectsMediaVideos)}
                        />
                        <Resource
                            name="targetings_main_page_fixed_contents"
                            {...resourceProps(FixedContentTargetings)}
                        />
                        <Resource
                            name="belltowers_virtual_tours"
                            {...resourceProps(BelltowersVirtualTours)}
                        />
                        <Resource
                            name="cathedrals_virtual_tours"
                            {...resourceProps(CathedralsVirtualTours)}
                        />
                        <Resource
                            name="chapels_virtual_tours"
                            {...resourceProps(ChapelsVirtualTours)}
                        />
                        <Resource
                            name="churches_virtual_tours"
                            {...resourceProps(ChurchesVirtualTours)}
                        />
                        <Resource
                            name="cities_virtual_tours"
                            {...resourceProps(CitiesVirtualTours)}
                        />
                        <Resource
                            name="city_objects_virtual_tours"
                            {...resourceProps(CityObjectsVirtualTours)}
                        />
                        <Resource
                            name="hermitages_virtual_tours"
                            {...resourceProps(HermitagesVirtualTours)}
                        />
                        <Resource
                            name="monasteries_virtual_tours"
                            {...resourceProps(MonasteriesVirtualTours)}
                        />
                        <Resource
                            name="museums_virtual_tours"
                            {...resourceProps(MuseumsVirtualTours)}
                        />
                        <Resource
                            name="shrines_virtual_tours"
                            {...resourceProps(ShrinesVirtualTours)}
                        />
                        <Resource
                            name="temples_virtual_tours"
                            {...resourceProps(TemplesVirtualTours)}
                        />
                        <Resource
                            name="virtual_tours_wells"
                            {...resourceProps(WellsVirtualTours)}
                        />
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("events") ? (
                            <Resource
                                name="events"
                                {...resourceProps(Events)}
                                options={{ label: "Богослужения" }}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes(
                            "media_video_categories"
                        ) ? (
                            <Resource
                                name="media_video_categories"
                                {...resourceProps(VideoCaegories)}
                                options={{ label: "Категории видео" }}
                                icon={Tv}
                            />
                        ) : (
                            <></>
                        )}
                        <Resource
                            name="media_video_categories_media_videos"
                            {...resourceProps(MediaVideoCategories)}
                        />
                        <Resource name="weeks" />
                        <Resource name="days_posts" {...resourceProps(DaysPosts)} />
                        <Resource
                            name="days_holidays"
                            {...resourceProps(DaysHolidays)}
                        />
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("posts") ? (
                            <Resource
                                name="posts"
                                {...resourceProps(Posts)}
                                options={{ label: "Посты" }}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("ikons") ? (
                            <Resource
                                name="ikons"
                                {...resourceProps(Ikons)}
                                options={{ label: "Иконы" }}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("saint_books") ? (
                            <Resource
                                name="saint_books"
                                {...resourceProps(SaintBook)}
                                options={{ label: "Священные книги" }}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("ab_test") ? (
                            <Resource
                                name="ab_test"
                                {...resourceProps(AbTest)}
                                options={{ label: "Настройки аб тестирования" }}
                                icon={BubbleChart}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("menus") ? (
                            <Resource
                                name="menus"
                                {...resourceProps(Menus)}
                                options={{ label: "Меню" }}
                            />
                        ) : (
                            <></>
                        )}
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("broadcasts") ? (
                            <Resource
                                name="broadcasts"
                                {...resourceProps(Broadcasts)}
                                options={{ label: "Трансляции" }}
                                icon={Videocam}
                            />
                        ) : (
                            <></>
                        )}
                        <style>
                            {`a[href="#/broadcast_schedules"] {
                                display: none;
                            }
                            `}
                        </style>
                        <Resource
                            name="broadcast_schedules"
                            {...resourceProps(BroadcastSchedules)}
                        />
                        <Resource
                            name="categories_news"
                            {...resourceProps(CategoriesNews)}
                        />
                        <Resource
                            name="categories_media_articles"
                            {...resourceProps(CategoriesArticles)}
                        />
                        <Resource
                            name="categories_text_pages"
                            {...resourceProps(CategoriesTextPages)}
                        />
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("architectural_styles") ? (
                            <Resource
                                name="architectural_styles"
                                {...resourceProps(ArchitecturaStyles)}
                                options={{ label: "Архитектурные стили" }}
                            />
                        ) : (
                            <></>
                        )}
                        <Resource
                            name="belltowers_holidays"
                            {...resourceProps(BelltowerHoludays)}
                        />
                        <Resource
                            name="belltowers_saints"
                            {...resourceProps(BelltowerSaints)}
                        />
                        <Resource
                            name="cathedrals_holidays"
                            {...resourceProps(CathedralsHolidays)}
                        />
                        <Resource
                            name="cathedrals_saints"
                            {...resourceProps(CathedralsSaints)}
                        />
                        <Resource
                            name="chapels_holidays"
                            {...resourceProps(ChapelHolidays)}
                        />
                        <Resource
                            name="chapels_saints"
                            {...resourceProps(ChapelSaints)}
                        />
                        <Resource
                            name="churches_holidays"
                            {...resourceProps(ChurchHolidays)}
                        />
                        <Resource
                            name="churches_saints"
                            {...resourceProps(ChurchSaints)}
                        />
                        <Resource
                            name="city_objects_holidays"
                            {...resourceProps(CityObjectsHolidays)}
                        />
                        <Resource
                            name="city_objects_saints"
                            {...resourceProps(CityObjectsSaints)}
                        />
                        <Resource
                            name="hermitages_holidays"
                            {...resourceProps(HermitageHolidays)}
                        />
                        <Resource
                            name="hermitages_saints"
                            {...resourceProps(HermitageSaints)}
                        />
                        <Resource
                            name="monasteries_holidays"
                            {...resourceProps(MonasteryHolidays)}
                        />
                        <Resource
                            name="monasteries_saints"
                            {...resourceProps(MonasterySaints)}
                        />
                        <Resource
                            name="museums_holidays"
                            {...resourceProps(MuseumHolidays)}
                        />
                        <Resource
                            name="museums_saints"
                            {...resourceProps(MuseumSaints)}
                        />
                        <Resource
                            name="shrines_holidays"
                            {...resourceProps(ShrineHolidays)}
                        />
                        <Resource
                            name="shrines_saints"
                            {...resourceProps(ShrineSaints)}
                        />
                        <Resource
                            name="temples_holidays"
                            {...resourceProps(TempleHolidays)}
                        />
                        <Resource
                            name="temples_saints"
                            {...resourceProps(TempleSaint)}
                        />
                        <Resource name="saints" />
                        <Resource
                            name="linked_news"
                            {...resourceProps(LinkedNews)}
                        />
                        <Resource
                            name="linked_media_articles"
                            {...resourceProps(LinkedMediaArticles)}
                        />
                        <Resource name="media_videos" />
                        <Resource name="tv_obraz" />
                        <Resource name="news_ratings" />
                        {allowsConfig["all"] ||
                        allowsConfig["filter"].includes("stop_words") ? (
                            <Resource
                                name="stop_words"
                                {...resourceProps(StopWords)}
                                options={{ label: "Стоп слова" }}
                                icon={Block}
                            />
                        ) : (
                            <></>
                        )}
                        <Resource name={"foods"} />
                        <Resource
                            name="v_admin_action_logs"
                            {...resourceProps(AdminActionLogs)}
                            options={{ label: "Действия пользователей" }}
                            icon={Edit}
                            />
                        <Resource
                            name="v_admin_action_users"
                        />
                        <Resource
                            name="v_news_media_articles"
                            {...resourceProps(NewsMediaArticles)}
                        />
                        <Resource
                            name="v_media_articles_news"
                            {...resourceProps(MediaArticlesNews)}
                        />
                        <Resource name="v_crowdfunding_projects_addresses" />
                    </Admin>
                </PermissionProvider>
                </MuiThemeProvider>
            </FacebookProvider>
        </VKProvider>
    );
}

export default App;
