import { get, transform, isEqual, isObject } from "lodash";
import decodeJwt from "jwt-decode";

import { REACT_APP_GRAPHQL_ENDPOINT } from "../config";

export function search(
    query,
    onSucces = () => Function,
    onError = () => Function
) {
    const headers = new Headers();

    headers.append(
        "Authorization",
        `Bearer ${localStorage.getItem("serafim_token")}`
    );
    headers.append("Content-Type", "application/json");

    const graphqlQuery = {
        query:
            "query SearchQuery($query: String!) {\n adminSearcheableContents(query: $query) {\n id \n type \n pageTitleShort \n} \n}",
    };

    const requestOptions = {
        method: "POST",
        headers,
        redirect: "follow",
    };

    fetch(
        `${REACT_APP_GRAPHQL_ENDPOINT}/v1/graphql`,
        Object.assign({}, requestOptions, {
            body: JSON.stringify(
                Object.assign({}, graphqlQuery, {
                    variables: {
                        query,
                    },
                })
            ),
        })
    )
        .then((response) => response.json())
        .then((data) => onSucces(data))
        .catch((error) => onError(error));
}

export function tabListByRole(sourceHashMap = {}) {
    const hashMap = {
        Default: null,
        get(role) {
            return this[role] || this.Default;
        },
    };

    for (let [key, value] of Object.entries(sourceHashMap)) {
        Object.assign(hashMap, { [key]: value });
    }

    return hashMap;
}

export function transformDataByRole(role, data) {
    return role === "seo" ? { seo: data.seo } : data;
}

export function prepareUserLogsData(currentData, nextData, resource, action) {
    const { user_id } = decodeJwt(localStorage.getItem("serafim_token"));
    const resource_id = get(currentData, "id", get(nextData, "id", ""));

    let prev_value = {};
    let current_value = {};

    if (!!Object.keys(currentData).length) {
        current_value = difference(nextData, currentData);

        for (let key in current_value) {
            prev_value[key] = get(currentData, key, "");
        }
    } else {
        current_value = nextData;
    }

    return {
        user_id,
        action,
        resource_table: resource,
        resource_id: String(resource_id),
        prev_value,
        current_value,
    };
}

function difference(object, base) {
    function changes(object, base) {
        return transform(object, function (result, value, key) {
            if (!isEqual(value, base[key])) {
                result[key] =
                    isObject(value) && isObject(base[key])
                        ? changes(value, base[key])
                        : value;
            }
        });
    }
    return changes(object, base);
}
