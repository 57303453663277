import React, { useState, useEffect } from "react";
import { addField } from "ra-core";
import { TextField } from "@material-ui/core";

function InputTranslit(props) {
    const { input, meta, label, translitValue, validate, isRequired } = props;
    const { value = "", onChange = Function, onBlur = Function } = input;

    const [valuee, setValuee] = useState(value);
    const [error, setError] = useState(false);

    function handleChange(value = "") {
        setValuee(value);
        if (value) {
            setError(false);
        } else {
            setError(meta.error);
        }
    }

    useEffect(() => {
        onChange(valuee);
    }, [onChange, valuee]);

    useEffect(() => {
        translitValue && setValuee(translitValue);
    }, [translitValue]);

    return (
        <TextField
            error={error}
            required={isRequired}
            label={label}
            onChange={event => handleChange(event.currentTarget.value)}
            onBlur={() => onBlur(valuee)}
            value={valuee}
            fullWidth
            style={{ marginTop: 16 }}
            validate={validate}
            helperText={error}
        />
    );
}

export default addField(InputTranslit);
