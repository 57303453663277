import React from "react";
import { TextField, ReferenceField } from "react-admin";
import { Create, ReferenceInput, TabbedShowLayout, Tab } from "react-admin";
import { Show, Edit } from "react-admin";

import { SimpleForm, required } from "react-admin";

import { parse } from "query-string";
import { get } from "lodash";

import AutocompleteField from "../../../Form/Autocomplete";

export const CreateView = (props) => {
    const { virtual_tour_id } = parse(props.location.search);
    const redirect = get(props, "location.state.redirect_to", "") || false;

    return (
        <Create {...props}>
            <SimpleForm initialValues={{ virtual_tour_id }} redirect={redirect}>
                <ReferenceInput
                    label={"Источник"}
                    source="well_id"
                    reference="wells"
                    validate={required()}
                    perPage={100}
                >
                    <AutocompleteField title="Источник" />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};

export const EditView = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <ReferenceInput
                label={"Источник"}
                source="well_id"
                reference="wells"
                validate={required()}
                perPage={100}
            >
                <AutocompleteField title="Источник" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

export const ShowView = (props) => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label={"Основное"} {...props}>
                <ReferenceField
                    label="Источник"
                    source="well_id"
                    reference="wells"
                    perPage={100}
                >
                    <TextField source="title_full" />
                </ReferenceField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
