/* eslint-disable no-undef */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { VKContextProvider } from "../context";

export default function VKProvider({ apiId, options, children }){
    const [isInit, setIsInit] = useState(false)
    
    useEffect(() => {
        const script = document.createElement("script");

        script.type = "text/javascript";
        script.src = "https://vk.com/js/api/openapi.js?168";

        script.onload = function () {
            setIsInit(true);
        }

        document.head.appendChild(script);
    }, []);

    function Post(elementId, ownerId, postId, hash, options) {
        if (isInit) {
            VK.Widgets.Post(elementId, ownerId, postId, hash, options);
        }
    }

    function Article(elementId, articleUrl) {
        if (isInit) {
            VK.Widgets.Article(elementId, articleUrl);
        }
    }

	return (
        <VKContextProvider
            value={
                {
                    isInit,
                    Post,
                    Article
                }
            }
        >
            {children}
        </VKContextProvider>
	);
};

VKProvider.propTypes = {
	apiId: PropTypes.number,
	options: PropTypes.shape({
		version: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		onlyWidgets: PropTypes.bool,
	}),
	onApiAvailable: PropTypes.func,
};

VKProvider.defaultProps = {
	apiId: null,
	options: {
		onlyWidgets: true,
	},
	onApiAvailable: () => Function,
};

