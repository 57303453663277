import React from "react";
import {
	Show, 
	TabbedShowLayout, 
	TextField, 
	Tab,
	ArrayField,
	ImageField,
	Datagrid,
} from "react-admin";

import { SEO } from "../../../Layout/Tabs/Show";

export function ShowView(props) {
	const PostTitle = ({ record }) => {
		return <span>Просмотр {record ? `"${record.page_title_full}"` : ""}</span>;
	}

	return (
		<Show title={<PostTitle />} {...props}>
			<TabbedShowLayout>
				<Tab label="Основное" {...props}>
					<TextField
						source="page_title_full"
						label={"Наименование"}
					/>
				</Tab>
				<SEO />
				<Tab label="Баннеры" {...props}>
					<ArrayField source="banner" style={{ width: "100%" }}>
						<Datagrid>
							<TextField
								source="type"
								label={"Тип баннера"}
								style={{ width: "100%" }}
							/>
							
							<TextField
								source="targeting_keys"
								label={"Тип таргетинга"}
								style={{ width: "100%" }}
							/>
							<TextField 
								source="slug" 
								label="Слаг"
								style={{ width: "100%" }}
							/>
							<TextField
								source="text"
								label={"Заголовок баннера"}
								style={{ width: "100%" }}
							/>
							<ImageField source="image.src" title="alt" label={"Изображениe"} />
						</Datagrid>
					</ArrayField>
				</Tab>
			</TabbedShowLayout>
		</Show>
	)
};
