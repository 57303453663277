import React from "react";

import List from "../../../Layout/List";
import CheckPermissions from "../../../../hoc/CheckPermissions";
import { TextField } from "react-admin";

function UnmappedListView(props) {
    return (
        <List {...props} showSortOrder={false}>
            <TextField source="name" label={"Имя"} />
            <TextField source="slug" label={"Слаг"} />
        </List>
    );
}

const ListView = CheckPermissions(UnmappedListView, "city");

export { ListView };
