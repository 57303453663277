import React, { useState, useEffect } from "react";
import { addField } from "ra-core";
import Select from "react-select";
import { get } from "lodash";

import "./autocomplete.scss";

function AutocompleteField(props) {
    const { input, choices, title, placeholder= "Выберите значение"} = props;
    const { value = "", onChange = Function } = input;

    const [options, setOptions] = useState([]);

    useEffect(() => {
        const choicesOptions = choices.map((item) => ({
            value: get(item, "id", ""),
            label: get(item, "page_title_full") || get(item, "title_full") || get(item, "title"),
        }))

        setOptions(choicesOptions);
    }, [choices]);

    let defaultValue;

    options.forEach((item, idx) => {
        if (+get(item, "value", 0) === +value) {
            defaultValue = idx;
            return;
        }
    });

    return (
        options.length > 1 && (
            <>
                {title && <p>{title}</p>}
                <Select
                    className="autocomplete-select"
                    defaultValue={options[defaultValue]}
                    isClearable={true}
                    isSearchable={true}
                    name={"name"}
                    options={options}
                    onChange={(event) => onChange(event && event.value)}
                    placeholder={placeholder}
                />
            </>
        )
    );
}

export default addField(AutocompleteField);
