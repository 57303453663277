import React from "react";

export default function CheckPermissions(Component, model_key = "news") {

    class CheckPermissions extends React.Component {
        render() {
            return (
                <Component
                    {...this.props}
                    permissions={{}}
                    roles={[]}
                    // filter={permanentFilter(model_key)}
                />
            );
        }
    }

    CheckPermissions.displayName = `CheckPermissions(${
        Component.displayName || Component.name || "Component"
    })`;

    return CheckPermissions;
}
