import React, {useState} from "react";
import {
    TabbedForm,
    Edit,
    FormTab,
    TextInput,
    BooleanInput,
    required,
    ArrayInput,
    SimpleFormIterator,
    SelectInput
} from "react-admin";
import Grid from "@material-ui/core/Grid";

import InputS3Uploader from "../../../Form/InputS3Uploader";
import { EditActions } from "../../../Layout/Actions";
import Icon from "../../../Icon";
import icons from "../../../../fonts/serafim-land/config";

export function EditView(props) {

    const { glyphs } = icons;

    const PostTitle = ({ record }) => {
        return <span>Редактируется {record ? `"${record.id}"` : ""}</span>;
    };

    const [itemprops, setItemprops] = useState(false);

    function handleChange(value) {
        setItemprops(value);
    };

    return (
        <Edit title={<PostTitle />} actions={<EditActions />} {...props}>
            <TabbedForm>
                <FormTab label={"Основное"} {...props}>
                    <div style={{ width: "100%" }}>
                        <Grid container>
                            <Grid
                                item
                                xs={8}
                                style={{
                                    paddingRight: 24,
                                    borderRight: "1px solid rgba(0, 0, 0, 0.12)"
                                }}
                            >
                                <TextInput
                                    source={"title"}
                                    label={"Заголовок"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <SelectInput
                                    source={"component"}
                                    label={"Название компонета"}
                                    allowEmpty
                                    emptyValue={null}
                                    style={{ width: "100%" }}
                                    choices={[
                                        {
                                            id: "InfoMenu",
                                            name: "Информационное меню с картой"
                                        },
                                        {
                                            id: "ImageMenu",
                                            name: "Меню с изображениями"
                                        },
                                        {
                                            id: "CalendarMenu",
                                            name: "Календарь"
                                        },
                                        {
                                            id: "ContentMenu",
                                            name: "Контент меню"
                                        },
                                    ]}
                                />
                                <SelectInput
                                    label={"Иконка"}
                                    source={"icon"}
                                    allowEmpty
                                    style={{ width: "100%" }}
                                    choices={
                                        glyphs.map(({ css }, idx) => ({
                                            id: css,
                                            name: <><Icon key={idx} id={css} /> <span style={{ marginLeft: 8 }}>{css}</span></>,
                                        }))
                                    }
                                />
                                <TextInput
                                    source={"url"}
                                    label={"Путь"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <BooleanInput
                                    source={"mobile"}
                                    label={"Показывать м мобильной версии"}
                                    style={{ width: "100%" }}
                                />
                                <TextInput
                                    source={"mobile_component"}
                                    label={
                                        "Название компонета для мобильной версии"
                                    }
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <BooleanInput
                                    source={"mobile_only"}
                                    label={
                                        "Показывать только в мобильной версии"
                                    }
                                    style={{ width: "100%" }}
                                />
                                <SelectInput
                                    source={"kind"}
                                    label={"Тип меню"}
                                    defaultValue={"sidebBar"}
                                    style={{ width: "100%" }}
                                    choices={[
                                        {
                                            id: "sidebBar",
                                            name: "Боковое меню"
                                        },
                                        {
                                            id: "topMenu",
                                            name: "Верхнее меню"
                                        },
                                        {
                                            id: "footerMenu",
                                            name: "Футер меню"
                                        }
                                    ]}
                                />
                                <TextInput
                                    source={"sort_order"}
                                    label={"Сортировка"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <TextInput
                                    source={"classname"}
                                    label={"Название класса"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                            </Grid>
                        </Grid>
                    </div>
                </FormTab>
                <FormTab label={"Подменю"} {...props}>
                    <div style={{ width: "100%" }}>
                        <Grid container>
                            <Grid
                                item
                                xs={8}
                                style={{
                                    paddingRight: 24,
                                    borderRight: "1px solid rgba(0, 0, 0, 0.12)"
                                }}
                            >
                                <TextInput
                                    source={"sub_title"}
                                    label={"Заголовок"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <TextInput
                                    source={"sub_desciption"}
                                    label={"Описание"}
                                    style={{ width: "100%" }}
                                    resettable
                                />

                                <ArrayInput
                                    source="sub_json"
                                    label="Блоки в меню"
                                    style={{ width: "30%" }}
                                >
                                    <SimpleFormIterator>
                                        <TextInput
                                            source="title"
                                            label="Заголовок"
                                            style={{ width: "100%" }}
                                        />
                                        <BooleanInput
                                            label="Опубликовано"
                                            defaultValue={true}
                                            source="is_public"
                                            onChange={(e, value) => handleChange(value)}
                                        />
                                        <BooleanInput
                                            label="Добавить микроразметку"
                                            source="itemprops"
                                            onChange={(e, value) => handleChange(value)}
                                        />
                                        {itemprops &&
                                        <>
                                            <TextInput
                                                source="telephone"
                                                label="Телефон"
                                                style={{ width: "100%" }}
                                            />
                                            <TextInput
                                                source="address"
                                                label="Адрес"
                                                style={{ width: "100%" }}
                                            />
                                            <TextInput
                                                source="organization"
                                                label="Наименование организации"
                                                style={{ width: "100%" }}
                                            />
                                        </>
                                        }
                                        <TextInput
                                            source={"url"}
                                            label={"Путь"}
                                            style={{ width: "100%" }}
                                        />
                                        <InputS3Uploader
                                            source={"img"}
                                            style={{ width: "30%" }}
                                        />
                                        <TextInput
                                            source={"sort_order"}
                                            label={"Сортировка"}
                                            style={{ width: "100%" }}
                                        />
                                        <TextInput
                                            source={"classNameSub"}
                                            label={"Название класса"}
                                            style={{ width: "100%" }}
                                        />
                                        <TextInput
                                            source={"style"}
                                            label={"Стиль"}
                                            style={{ width: "100%" }}
                                        />
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Grid>
                        </Grid>
                    </div>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
}
