import React from "react";
import {
    TabbedForm,
    Edit,
    TextInput,
    required,
    NumberInput,
    FormTab,
    usePermissions,
} from "react-admin";
import Grid from "@material-ui/core/Grid";

import { SEO, Content, Location } from "../../../Layout/Tabs/Edit";
import InputS3Uploader from "../../../Form/InputS3Uploader";
import withPermissions from "../../../../hoc/CheckPermissions";
import { tabListByRole, transformDataByRole } from "../../../../utils";
import { BottomToolbar } from "../../../Layout/Actions";

function UnmappedEditView(props) {
    const { loaded, permissions: role } = usePermissions();

    const PostTitle = ({ record }) => {
        return (
            <span>Редактируется {record ? `"${record.title_full}"` : ""}</span>
        );
    };

    const main = (
        <FormTab label={"Основное"} {...props}>
            <div style={{ width: "100%" }}>
                <Grid container>
                    <Grid
                        item
                        xs={8}
                        style={{
                            paddingRight: 24,
                            borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                        }}
                    >
                        <TextInput
                            source={"title_full"}
                            label={"Наименование"}
                            style={{ width: "100%" }}
                            validate={[required()]}
                            resettable
                        />
                        <TextInput
                            source={"title_short"}
                            label={"Краткое наименование"}
                            style={{ width: "100%" }}
                            resettable
                        />
                        <TextInput
                            source={"page_title_full"}
                            label={"Наименование страницы"}
                            style={{ width: "100%" }}
                            validate={[required()]}
                            resettable
                        />
                        <TextInput
                            source={"page_title_short"}
                            label={"Краткое наименование страницы"}
                            style={{ width: "100%" }}
                            resettable
                        />
                        <TextInput
                            source={"teaser"}
                            label={"Тизер"}
                            style={{ width: "100%" }}
                            validate={[required()]}
                            resettable
                        />
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        style={{
                            paddingLeft: 24,
                            marginBottom: 24,
                        }}
                    >
                        <TextInput
                            source={"slug"}
                            label={"Слаг"}
                            style={{ width: "100%" }}
                            validate={[required()]}
                            resettable
                        />
                        <NumberInput
                            source={"sort_order"}
                            label={"Сортировка"}
                            style={{ width: "100%" }}
                            validate={[required()]}
                            resettable
                        />
                        <InputS3Uploader
                            source={"main_image"}
                            label={"Главное изображение"}
                            validate={[required()]}
                        />
                        <InputS3Uploader
                            source={"main_image_mobile"}
                            label={"Главное изображение для мобильной версии"}
                        />
                        <InputS3Uploader
                            source={"main_image_preview"}
                            label={"Превью изображение"}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{
                            borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                        }}
                    >
                        {props.children}
                    </Grid>
                </Grid>
            </div>
        </FormTab>
    );
    const location = <Location />;
    const seo = <SEO />;
    const content = <Content />;

    return (
        loaded && (
            <Edit
                title={<PostTitle />}
                transform={(data) => transformDataByRole(role, data)}
                {...props}
            >
                <TabbedForm toolbar={<BottomToolbar role={role} {...props} />}>
                    {tabListByRole({
                        seo,
                        admin: [main, location, seo, content],
                    }).get(role)}
                </TabbedForm>
            </Edit>
        )
    );
}

const EditView = withPermissions(UnmappedEditView, "tours");

export { EditView };
