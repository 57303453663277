import React from "react";
import { Filter, ReferenceInput, SelectInput, TextInput } from "react-admin";

export default function ListFilter(props) {
    return (
        <Filter {...props}>
            <TextInput label={"Поиск"} source="q" alwaysOn />
            <ReferenceInput
                label={"Город"}
                source={"city_id"}
                reference={"v_portal_admin_cities"}
                allowEmpty
            >
                <SelectInput optionText={"title_full"} />
            </ReferenceInput>
            <ReferenceInput
                label={"Монастырь"}
                source={"monastery_id"}
                reference={"monasteries"}
                allowEmpty
            >
                <SelectInput optionText={"title_short"} />
            </ReferenceInput>
            <ReferenceInput
                label={"Собор"}
                source={"cathedral_id"}
                reference={"cathedrals"}
                allowEmpty
            >
                <SelectInput optionText={"title_short"} />
            </ReferenceInput>
            <ReferenceInput
                label={"Храм"}
                source={"temple_id"}
                reference={"temples"}
                allowEmpty
            >
                <SelectInput optionText={"title_short"} />
            </ReferenceInput>
            <ReferenceInput
                label={"Церковь"}
                source={"church_id"}
                reference={"churches"}
                allowEmpty
            >
                <SelectInput optionText={"title_short"} />
            </ReferenceInput>
        </Filter>
    );
}
