import React from "react";

import List from "../../../Layout/List";
import CheckPermissions from "../../../../hoc/CheckPermissions";
import { TextField } from "react-admin";

function UnmappedListView(props) {
    return (
        <List sort={{ field: "id", order: "DESC" }} {...props}>
            <TextField source="title" label={"Заголовок"} />
        </List>
    );
}

const ListView = CheckPermissions(UnmappedListView, "city");

export { ListView };
