import React from "react";
import {
    TabbedForm,
    Create,
    FormTab,
    TextInput,
    required,
    ReferenceInput,
    SelectInput,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import AutocompleteField from "../../../Form/Autocomplete";

export function CreateView(props) {
    return (
        <Create {...props}>
            <TabbedForm>
                <FormTab label={"Основное"} {...props}>
                    <div style={{ width: "100%" }}>
                        <Grid container>
                            <Grid item xs={8} style={{ paddingRight: 24 }}>
                                <TextInput
                                    source={"position"}
                                    label={" Позиция (начиная с 0)"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <ReferenceInput
                                    source={"component_id"}
                                    reference={"components"}
                                    label={"Выбрать тип блока"}
                                    validate={[required()]}
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <SelectInput optionText="name" />
                                </ReferenceInput>
                                <ReferenceInput
                                    source={"news_id"}
                                    reference={"news"}
                                    label={"Новость"}
                                    allowEmpty
                                    style={{
                                        width: "100%",
                                    }}
                                    perPage={10000}
                                >
                                    <AutocompleteField title={"Новость"} />
                                </ReferenceInput>
                                <ReferenceInput
                                    source={"media_article_id"}
                                    reference={"media_articles"}
                                    label={"Статья"}
                                    allowEmpty
                                    style={{
                                        width: "100%",
                                    }}
                                    perPage={10000}
                                >
                                    <AutocompleteField title={"Статья"} />
                                </ReferenceInput>
                                <ReferenceInput
                                    source={"media_video_id"}
                                    reference={"media_videos"}
                                    label={"Видео"}
                                    allowEmpty
                                    style={{
                                        width: "100%",
                                    }}
                                    perPage={10000}
                                >
                                    <AutocompleteField title={"Видео"} />
                                </ReferenceInput>
                                <ReferenceInput
                                    source={"city_objects_id"}
                                    reference={"city_objects"}
                                    label={"Городской объект"}
                                    allowEmpty
                                    style={{
                                        width: "100%",
                                    }}
                                    perPage={100}
                                >
                                    <AutocompleteField title={"Городской объект"} />
                                </ReferenceInput>
                                <ReferenceInput
                                    source={"tours_id"}
                                    reference={"tours"}
                                    label={"Тур"}
                                    allowEmpty
                                    style={{
                                        width: "100%",
                                    }}
                                    perPage={100}
                                >
                                    <AutocompleteField title={"Тур"} />
                                </ReferenceInput>
                            </Grid>
                        </Grid>
                    </div>
                </FormTab>
            </TabbedForm>
        </Create>
    );
}
