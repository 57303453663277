import React from "react";
import {
    useEditController,
    EditContextProvider,
    useDataProvider,
    usePermissions,
    TabbedForm,
    FormTab,
    TextInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    BooleanInput,
    required,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import get from "lodash/get";

import { SEO, Content } from "../../../Layout/Tabs/Edit";
import { BottomToolbar } from "../../../Layout/Actions/Edit";
import InputS3Uploader from "../../../Form/InputS3Uploader";
import InputS3DragCropUploader from "../../../Form/InputS3DragCropUploader";
import {
    tabListByRole,
    prepareUserLogsData,
} from "../../../../utils";
import withPermissions from "../../../../hoc/CheckPermissions";
import CustomDatePicker from "../../../Form/CustomDatePicker"; 

const MyEdit = (props) => {
    let history = useHistory();
    const controllerProps = useEditController(props);
    const dataProvider = useDataProvider();

    const {
        basePath, // deduced from the location, useful for action buttons
        record, // record fetched via dataProvider.getOne() based on the id from the location
        redirect, // the default redirection route. Defaults to 'list'
        resource, // the resource name, deduced from the location. e.g. 'posts'
        saving, // boolean that becomes true when the dataProvider is called to update the record
        version, // integer used by the refresh feature
    } = controllerProps;

    // Save edited news
    function save(changedDate) {
        const preparedData = changedDate


        delete preparedData.id;

        const logData = prepareUserLogsData(
            record,
            changedDate,
            resource,
            "update"
        );

        dataProvider
            .create("admin_action_logs", { data: logData })
            .then(() => {
                dataProvider
                    .update("news", {
                        id: get(record, "id", ""),
                        data: { ...preparedData },
                    })
                    .then(() => history.push("/news"))
                    .catch((error) =>
                        console.error("Ошибка сохранения новости: ", { error })
                    );
            })
            .catch((error) => console.error("Ошибка логирования: ", { error }));
    }

    return (
        <EditContextProvider value={controllerProps}>
            <div>
                <h1>
                    Редактируется {record ? `"${record.page_title_short}"` : ""}
                </h1>
                {React.cloneElement(props.children, {
                    basePath,
                    record,
                    redirect,
                    resource,
                    save,
                    saving,
                    version,
                })}
            </div>
        </EditContextProvider>
    );
};

MyEdit.propTypes = {
    children: PropTypes.node,
};

const UnmountedEditView = (props) => {
    const { loaded, permissions: role } = usePermissions();

    const main = (
        <FormTab label={"Основное"} {...props}>
            <div style={{ width: "100%" }}>
                <Grid container>
                    <Grid
                        item
                        xs={8}
                        style={{
                            paddingRight: 24,
                            borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                        }}
                    >
                        <TextInput
                            source={"page_title_full"}
                            label={"Наименование страницы"}
                            style={{ width: "100%" }}
                            validate={[required()]}
                            resettable
                        />
                        <TextInput
                            source={"page_title_short"}
                            label={"Краткое наименование страницы"}
                            style={{ width: "100%" }}
                            resettable
                        />

                        <TextInput
                            source={"teaser"}
                            label={"Тизер новости"}
                            style={{ width: "100%" }}
                            validate={[required()]}
                            resettable
                        />
                        <ReferenceInput
                            source={"author_id"}
                            reference={"authors"}
                            label={"Автор новости"}
                            allowEmpty
                            style={{
                                width: "100%",
                            }}
                        >
                            <SelectInput optionText="name" emptyValue={null} />
                        </ReferenceInput>
                        <SelectInput
                            source={"age_rating"}
                            label={"Возрастной рейтинг"}
                            style={{ width: "100%" }}
                            allowEmpty
                            emptyValue={null}
                            choices={[
                                {
                                    id: "0+",
                                    name: "0+",
                                },
                                {
                                    id: "6+",
                                    name: "6+",
                                },
                                {
                                    id: "12+",
                                    name: "12+",
                                },
                                {
                                    id: "16+",
                                    name: "16+",
                                },
                                {
                                    id: "18+",
                                    name: "18+",
                                },
                            ]}
                        />
                        <ReferenceInput
                            source={"category_id"}
                            reference={"categories"}
                            label={"Категория"}
                            style={{
                                width: "100%",
                            }}
                        >
                            <SelectInput
                                emptyValue={null}
                                optionText="title_full"
                                validate={[required()]}
                            />
                        </ReferenceInput>
                        <BooleanInput
                            label="Публикация в vk"
                            source="data.social_posting.vk"
                            defaultValue={false}
                        />
                        <BooleanInput
                            label="Публикация в facebook"
                            source="data.social_posting.fb"
                            defaultValue={false}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        style={{
                            paddingLeft: 24,
                            marginBottom: 24,
                        }}
                    >
                        <TextInput
                            source={"slug"}
                            label={"Слаг"}
                            style={{ width: "100%" }}
                            validate={[required()]}
                            resettable
                        />
                        <CustomDatePicker
                            source={"issued_at"}
                            label={"Дата новости"}
                            style={{ width: "100%" }}
                            validate={[required()]}
                            resettable
                        />
                        <CustomDatePicker
                            source={"published_at"}
                            label={"Дата публикации новости"}
                            style={{ width: "100%" }}
                            resettable
                        />
                        <NumberInput
                            source={"sort_order"}
                            label={"Сортировка"}
                            style={{ width: "100%" }}
                            validate={[required()]}
                            resettable
                        />
                        <BooleanInput
                            label="Главная новость"
                            source="settings.is_main"
                            defaultValue={false}
                        />
                        <BooleanInput
                            label="Скрыть дату"
                            source="settings.hide_published_at"
                            defaultValue={false}
                        />
                        <InputS3Uploader
                            source={"main_image"}
                            label={"Главное изображение"}
                            validate={[required()]}
                        />
                        <InputS3DragCropUploader
                            source={"main_image_preview"}
                            label={"Превью изображение"}
                            needCrop
                        />
                    </Grid>
                </Grid>
            </div>
        </FormTab>
    );
    const seo = <SEO />;
    const content = <Content />;

    return (
        loaded && (
            <MyEdit role={role} {...props}>
                <TabbedForm toolbar={<BottomToolbar role={role} {...props} />}>
                    {tabListByRole({
                        seo: [main, seo, content],
                        admin: [main, seo, content],
                    }).get(role)}
                </TabbedForm>
            </MyEdit>
        )
    );
};

const EditView = withPermissions(UnmountedEditView, "news");

export { EditView };
