import React from "react";
import {
    TabbedForm,
    Edit,
    TextInput,
    required,
    FormTab,
    usePermissions,
} from "react-admin";
import Grid from "@material-ui/core/Grid";

import { SEO, Content } from "../../../Layout/Tabs/Edit";
import { tabListByRole, transformDataByRole } from "../../../../utils";
import { BottomToolbar } from "../../../Layout/Actions";

export function EditView(props) {
    const { loaded, permissions: role } = usePermissions();

    const PostTitle = ({ record }) => {
        return <span>Редактируется {record ? `"${record.name}"` : ""}</span>;
    };

    const main = (
        <FormTab label={"Основное"} {...props}>
            <div style={{ width: "100%" }}>
                <Grid container>
                    <Grid
                        item
                        xs={8}
                        style={{
                            paddingRight: 24,
                            borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                        }}
                    >
                        <TextInput
                            source={"slug"}
                            label={"Слаг"}
                            style={{ width: "100%" }}
                            resettable
                            multiline
                        />
                        <TextInput
                            source={"title"}
                            label={"Заголовок"}
                            style={{ width: "100%" }}
                            validate={[required()]}
                            resettable
                        />
                        <TextInput
                            source={"description"}
                            label={"Описание"}
                            style={{ width: "100%" }}
                            resettable
                            multiline
                        />
                    </Grid>
                </Grid>
            </div>
        </FormTab>
    );
    const seo = <SEO />;
    const content = <Content />;

    return (
        loaded && (
            <Edit
                title={<PostTitle />}
                transform={(data) => transformDataByRole(role, data)}
                {...props}
            >
                <TabbedForm toolbar={<BottomToolbar role={role} {...props} />}>
                    {tabListByRole({
                        seo,
                        admin: [main, seo, content],
                    }).get(role)}
                </TabbedForm>
            </Edit>
        )
    );
}
