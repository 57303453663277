import React from "react";
import { Show, TabbedShowLayout, TextField, Tab, DateField } from "react-admin";

export function ShowView(props) {
    const PostTitle = ({ record }) => {
        return <span>Просмотр {record ? `"${record.title_full}"` : ""}</span>;
    };

    return (
        <Show title={<PostTitle />} {...props}>
            <TabbedShowLayout>
                <Tab label="Основное" {...props}>
                    <DateField
                        label={"Дата и время службы"}
                        source="begins_at"
                    />
                    <TextField
                        source="data.holiday"
                        label={"Название проаздника"}
                    />
                    <TextField source="data.title" label={"Название службы"} />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
}
