export const buttons = [
    {
        type: "size",
        title: "По размеру файла"
    },
    {
        type: "title",
        title: "По подписи к фото"
    },
    {
        type: "lastModified",
        title: "По дате модификации"
    },
    {
        type: "fileName",
        title: "По имени файла"
    },
    {
        type: "custom",
        title: "Ручная",
        action: false
    },
];