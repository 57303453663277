import React, { Component } from "react";
import uuid from "uuid";
import L  from "leaflet";
// eslint-disable-next-line no-unused-vars
import Draw from "leaflet-draw";

import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css"

import markerIcon from "./marker.svg";

export default class FieldMap extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: `leaflet-map-input-${uuid()}`,
			center: [56.326887, 44.005986],
			zoom: 8,
			icon: new L.Icon({
				iconUrl: markerIcon,
				iconAnchor: [37, 70]
			}),
			markers: [],
		}
	}

	componentDidMount() {
		const { record: { location_object = {} } } = this.props;
		const map = new L.Map(this.state.id, { center: new L.LatLng(...this.state.center), zoom: this.state.zoom });
		const mainLayer = L.featureGroup().addTo(map);
		const markerLayer = L.featureGroup().addTo(map);
		const polygonLayer = L.featureGroup().addTo(map);

		L.Icon.Default.imagePath = this.state.icon;

		this.setState({ map, mainLayer, markerLayer, polygonLayer }, () => {
			L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", { maxZoom: 18  }).addTo(this.state.map);

			L.geoJSON(
				location_object,
				{
					pointToLayer: (layer, latlng) => {
						map.setView(latlng);
						return L.marker(latlng, {
							icon: this.state.icon
						});
					},
					onEachFeature: (feature, layer) => {
						this.state.mainLayer.addLayer(layer);
					}
				});
		})
	}

	render() {
		return (
			<div id={this.state.id} style={{ minHeight: 600, marginTop: "1.5rem" }} />
		)
	}
}
