import React from "react";
import {
    TabbedForm,
    Create,
    FormTab,
    TextInput,
    required,
    ArrayInput,
    SimpleFormIterator,
    SelectInput,
    CheckboxGroupInput,
} from "react-admin";
import Grid from "@material-ui/core/Grid";

import { SEO } from "../../../Layout/Tabs/Edit";
import InputS3Uploader from "../../../Form/InputS3Uploader";

export function CreateView(props) {
	return (
		<Create {...props}>
			<TabbedForm>
                <FormTab label={"Основное"} {...props}>
                    <div style={{ width: "100%" }}>
                        <Grid container>
                            <Grid item xs={12} style={{ paddingRight: 24, borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                                <TextInput 
                                    source={"page_title_full"}
                                    label={"Наименование страницы"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                            </Grid>
                        </Grid>
                    </div>
                </FormTab>
				<SEO />
                <FormTab label={"Баннеры"} {...props}>
                    <div style={{ width: "100%" }}>
                        <Grid container>
                            <Grid item xs={12} style={{ paddingLeft: 24, marginBottom: 24 }}>
                                <ArrayInput 
                                    source="banner" 
                                    label="Баннеры" 
                                    style={{ width: "100%" }}
                                >
                                    <SimpleFormIterator>
                                        <SelectInput
                                            source={"type"}
                                            label={"Тип баннера"}
                                            defaultValue={"BannerImg"}
                                            style={{ width: "100%" }}
                                            choices={[
                                                {
                                                    id: "BannerImg", 
                                                    name: "По умолчанию",
                                                },
                                                {
                                                    id: "BannerText", 
                                                    name: "Текстовый баннер",
                                                },
                                                {
                                                    id: "CombinedBanner",
                                                    name: "Составной баннер",
                                                }
                                            ]} 
                                        />
                                        <CheckboxGroupInput source={"targeting_keys"}
                                            label={"Типы таргетингов"}
                                            choices={[
                                                { id: 'default', name: 'По умолчанию' },
                                                { id: 'tourist', name: 'Турист' },
                                                { id: 'palomnik', name: 'Паломник' },
                                                { id: 'trudnik', name: 'Трудник' },
                                            ]}
                                        />
                                        <TextInput 
                                            source="slug" 
                                            label="Слаг"
                                            style={{ width: "100%" }}
                                        />
                                        <TextInput 
                                            source="title" 
                                            label="Текст баннера"
                                            style={{ width: "100%" }}
                                            multiline
                                        />
                                        <InputS3Uploader
                                            source={"image"}
                                            label={"Баннер"}
                                        />
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Grid>
                        </Grid>
                    </div>
                </FormTab>
			</TabbedForm>
		</Create>
	);
}
