import React, { Component } from "react";
import PropTypes from "prop-types";
import { showNotification, UPDATE, withDataProvider } from "react-admin";
import {
    Icon,
    Typography,
    TextField,
    // withStyles,
    // makeStyles,
    Modal,
    Button,
} from "@material-ui/core";
import { Send } from "@material-ui/icons";
import { get } from "lodash";
import { useDispatch } from "react-redux";

// const styles = (theme) => ({
//     paper: {
//         position: "absolute",
//         width: theme.spacing.unit * 50,
//         backgroundColor: theme.palette.background.paper,
//         boxShadow: theme.shadows[5],
//         padding: theme.spacing.unit * 4,
//     },
// });

class AgreementActions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            isOpen: false,
            comment: "",
            action: "",
        };

        this.config = {
            send_task_to_writer: {
                transitions: {
                    created: "copyrighting",
                    published: "copyrighting",
                },
                title: "Писателю",
                icon: "rate_review_icon",
                color: "green",
                notification: "отправлена писателю",
                comment: true,
            },
            send_task_to_manager: {
                transitions: {
                    copyrighting: "copyrighted",
                },
                title: "Готово",
                icon: "check_icon",
                color: "red",
                notification: "отправлена контент-менеджеру",
                comment: true,
            },
            send_feedback_to_writer: {
                transitions: {
                    copyrighted: "copyrighting",
                    declined: "copyrighting",
                },
                title: "На доработку",
                icon: "rate_review_icon",
                color: "green",
                notification: "отправлена на доработку контент-менеджеру",
                comment: true,
            },
            send_task_to_moderator: {
                transitions: {
                    copyrighted: "moderating",
                    copyrighted_again: "moderating",
                },
                title: "На согласование",
                icon: "check_box_icon",
                color: "purple",
                notification: "отправлена на модерацию",
                comment: true,
            },
            decline: {
                transitions: {
                    moderating: "declined",
                },
                title: "Отклонить",
                icon: "close_icon",
                color: "red",
                notification: "отклонена",
                comment: true,
            },
            accept: {
                transitions: {
                    moderating: "accepted",
                },
                title: "Согласовать",
                icon: "check_circle_icon",
                color: "green",
                notification: "отправлена на публикацию",
                comment: true,
            },
            publish: {
                transitions: {
                    accepted: "published",
                },
                title: "Опубликовать",
                icon: "done_all_icon",
                color: "green",
                notification: "опубликована",
                comment: true,
            },
        };

        this.handleAction = this.handleAction.bind(this);
        this.getActionButton = this.getActionButton.bind(this);
    }

    handleAction(event, data, notification) {
        event.stopPropagation();

        const { dataProvider, record, resource } = this.props;
        const dispatch = useDispatch();

        showNotification(`Comment approved ${record.title}`, "info");

        this.setState({ loading: true }, () => {
            dataProvider(UPDATE, resource, {
                id: record.id,
                data: Object.assign({}, record, data),
            })
                .then(() => {
                    this.setState({ loading: false, isOpen: false }, () => {
                        dispatch(
                            showNotification(
                                `Запись «${record.page_title_full}» ${notification}`
                            )
                        );
                    });
                })
                .catch((e) => {
                    dispatch(
                        showNotification(
                            `Error: ${resource} not approved`,
                            "warning"
                        )
                    );
                });
        });
    }

    getActionButton(action, idx) {
        const { loading } = this.state;
        const { record } = this.props;
        const state = get(
            this,
            `config[${action}].transitions[${record.state}]`,
            null
        );
        const icon = get(this, `config[${action}].icon`, "");
        const color = get(this, `config[${action}].color`, "");
        const notification = get(this, `config[${action}].notification`, "");

        return (
            state && (
                <Button
                    key={`${action}-${idx}`}
                    color={"primary"}
                    size={"small"}
                    onClick={(event) => {
                        event.stopPropagation();

                        if (get(this.config, `[${action}].comment`, false)) {
                            this.setState({ isOpen: true, state });
                        } else {
                            this.handleAction(
                                event,
                                {
                                    state,
                                },
                                notification
                            );
                        }
                    }}
                    style={{ marginRight: "1rem", color }}
                    disabled={loading}
                >
                    {get(this.config, `[${action}].title`, "Отправить")}
                    {icon && (
                        <Icon
                            style={{
                                marginLeft: 16,
                                height: 20,
                                width: 20,
                                fontSize: 20,
                                color,
                            }}
                        >
                            {icon}
                        </Icon>
                    )}
                </Button>
            )
        );
    }

    render() {
        const {
            actions,
            record: { content_blocks = [], drafts = [], main_image = {} },
            role,
        } = this.props;
        const { isOpen, state, comment, action } = this.state;
        const color = get(this, `config[${action}].color`, "");

        return (
            <>
                {actions.map((action, idx) =>
                    this.getActionButton(action, idx)
                )}
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={isOpen}
                    onClose={(event) => {
                        event.stopPropagation();
                        this.setState({ isOpen: false });
                    }}
                >
                    <div
                        style={{
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            position: "absolute",
                        }}
                        onClick={(event) => event.stopPropagation()}
                    >
                        <Typography
                            variant="title"
                            id="modal-title"
                            style={{ marginBottom: 16 }}
                        >
                            Оставить комментарий
                        </Typography>
                        <div>
                            <TextField
                                multiline={true}
                                fullWidth={true}
                                rows={5}
                                style={{ marginBottom: 16 }}
                                onChange={(event) =>
                                    this.setState({
                                        comment: event.target.value,
                                    })
                                }
                            />
                        </div>
                        <Button
                            color={"primary"}
                            variant={"contained"}
                            style={{
                                marginRight: "1rem",
                                width: "100%",
                                color,
                            }}
                            onClick={(event) => {
                                this.handleAction(event, {
                                    state,
                                    drafts: [
                                        ...drafts,
                                        {
                                            data: {
                                                content_blocks,
                                                main_image,
                                            },
                                            comment: `${role}: ${comment}`,
                                        },
                                    ],
                                });
                            }}
                        >
                            Отправить
                            <Send style={{ marginLeft: 16, color }} />
                        </Button>
                    </div>
                </Modal>
            </>
        );
    }
}

AgreementActions.propTypes = {
    dataProvider: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    record: PropTypes.object,
    actions: PropTypes.array,
};

AgreementActions.defaultProps = {
    record: {},
    actions: [],
};

export default withDataProvider(AgreementActions);
