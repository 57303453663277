import React from "react";
import {
    Show,
    TabbedShowLayout,
    Button,
    ReferenceManyField,
    SingleFieldList,
    ReferenceField,
    ChipField,
    TextField,
} from "react-admin";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";

import { SEO, Content, Main, Connections } from "../../../Layout/Tabs/Show";

export function ShowView(props) {
    const PostTitle = ({ record }) => {
        return <span>Просмотр {record ? `"${record.title_short}"` : ""}</span>;
    };

    const AddButton = ({ redirect_to, record, table, label }) => (
        <Button
            variant="raised"
            component={Link}
            to={{
                pathname: `/${table}/create`,
                state: { record: { media_audio_id: record.id }, redirect_to },
            }}
            label={label}
            title={label}
        >
            <AddIcon />
        </Button>
    );

    const AddReferenceButton = AddButton;

    return (
        <Show title={<PostTitle />} {...props}>
            <TabbedShowLayout>
                <Main>
                    <ReferenceField
                        source={"audio_category_id"}
                        reference={"audio_categories"}
                        label={"Категория"}
                    >
                        <TextField source="title" />
                    </ReferenceField>
                </Main>
                <Connections>
                    <ReferenceManyField
                        label="Города"
                        reference="cities_media_audios"
                        target="media_audio_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="city_id"
                                reference="v_portal_admin_cities"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"cities_media_audios"}
                        label="Прикрепить к городу"
                    />

                    <ReferenceManyField
                        label="Монастыри"
                        reference="monasteries_media_audios"
                        target="media_audio_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="monastery_id"
                                reference="monasteries"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"monasteries_media_audios"}
                        label="Прикрепить к монастырю"
                    />

                    <ReferenceManyField
                        label="Церкви"
                        reference="churches_media_audios"
                        target="media_audio_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="church_id"
                                reference="churches"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"churches_media_audios"}
                        label={"Прикрепить к церкви"}
                    />

                    <ReferenceManyField
                        label="Достопримечательности"
                        reference="city_objects_media_audios"
                        target="media_audio_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="city_object_id"
                                reference="city_objects"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"city_objects_media_audios"}
                        label={"Прикрепить к достопримечательности"}
                    />

                    <ReferenceManyField
                        label="Скиты"
                        reference="hermitages_media_audios"
                        target="media_audio_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="hermitage_id"
                                reference="hermitages"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"hermitages_media_audios"}
                        label={"Прикрепить к скиту"}
                    />

                    <ReferenceManyField
                        label="Святыни"
                        reference="media_audios_shrines"
                        target="media_audio_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="shrine_id"
                                reference="shrines"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"media_audios_shrines"}
                        label={"Прикрепить к святыне"}
                    />

                    <ReferenceManyField
                        label="Часовни"
                        reference="chapels_media_audios"
                        target="media_audio_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="chapel_id"
                                reference="chapels"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"chapels_media_audios"}
                        label={"Прикрепить к часовне"}
                    />

                    <ReferenceManyField
                        label="Источники"
                        reference="media_audios_wells"
                        target="media_audio_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="well_id"
                                reference="wells"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"media_audios_wells"}
                        label={"Прикрепить к источнику"}
                    />

                    <ReferenceManyField
                        label="Колокольни"
                        reference="belltowers_media_audios"
                        target="media_audio_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="belltower_id"
                                reference="belltowers"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"belltowers_media_audios"}
                        label={"Прикрепить к колокольне"}
                    />

                    <ReferenceManyField
                        label="Храмы"
                        reference="media_audios_temples"
                        target="media_audio_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="temple_id"
                                reference="temples"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"media_audios_temples"}
                        label={"Прикрепить к храму"}
                    />

                    <ReferenceManyField
                        label="Соборы"
                        reference="cathedrals_media_audios"
                        target="media_audio_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="cathedral_id"
                                reference="cathedrals"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"cathedrals_media_audios"}
                        label={"Прикрепить к собору"}
                    />

                    <ReferenceManyField
                        label="Музеи"
                        reference="media_audios_museums"
                        target="media_audio_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="museum_id"
                                reference="museums"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"media_audios_museums"}
                        label={"Прикрепить к музею"}
                    />
                </Connections>
                <SEO />
                <Content />
            </TabbedShowLayout>
        </Show>
    );
}
