import React from "react";
import {
    TabbedForm,
    FormTab,
    Create,
    TextInput,
    ArrayInput,
    SimpleFormIterator,
    required
} from "react-admin";
import Grid from "@material-ui/core/Grid";

export function CreateView(props) {
    return (
        <Create {...props}>
            <TabbedForm>
                <FormTab label={"Основное"} {...props}>
                    <div style={{ width: "100%" }}>
                        <Grid container>
                            <Grid
                                item
                                xs={8}
                                style={{
                                    paddingRight: 24,
                                    borderRight: "1px solid rgba(0, 0, 0, 0.12)"
                                }}
                            >
                                <TextInput
                                    source={"name"}
                                    label={"Название"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <TextInput
                                    source={"target_key"}
                                    label={"Ключ"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <ArrayInput
                                    source="variants"
                                    label="Варианты"
                                    style={{ width: "100%" }}
                                >
                                    <SimpleFormIterator>
                                        <TextInput
                                            source="name"
                                            label="Название варианта"
                                            style={{ width: "100%" }}
                                        />
                                        <TextInput
                                            source="weight"
                                            label="Вес"
                                            style={{ width: "100%" }}
                                        />
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Grid>
                        </Grid>
                    </div>
                </FormTab>
            </TabbedForm>
        </Create>
    );
}
