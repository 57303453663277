import React from "react";
import {
    Show,
    TabbedShowLayout,
    TextField,
    Button,
    ReferenceManyField,
    SingleFieldList,
    ReferenceField,
    ChipField,
} from "react-admin";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";

import {
    SEO,
    Content,
    Main,
    Location,
    Connections,
} from "../../../Layout/Tabs/Show";

export function ShowView(props) {
    const PostTitle = ({ record }) => {
        return <span>Просмотр {record ? `"${record.title_short}"` : ""}</span>;
    };

    const AddButton = ({ redirect_to, record, table, label, resourse }) => {
        const recordObj = {};
        recordObj[resourse] = record.id;
        return (
            <Button
                variant="raised"
                component={Link}
                to={{
                    pathname: `/${table}_media_videos/create`,
                    state: {
                        record: recordObj,
                        redirect_to,
                        resource: "media_video_id",
                        table,
                    },
                }}
                label={label}
                title={label}
            >
                <AddIcon />
            </Button>
        );
    };

    const AddReferenceButton = AddButton;

    return (
        <Show title={<PostTitle />} {...props}>
            <TabbedShowLayout>
                <Main>
                    <TextField source="src" label={"Видео"} />
                </Main>
                <Connections>
                    <ReferenceManyField
                        label="Категории видео"
                        reference={`media_video_categories_media_videos`}
                        target={`media_video_id`}
                        sort={{ field: "media_video_id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="media_video_category_id"
                                reference="media_video_categories"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"media_video_categories"}
                        label={"Прикрепить к категории"}
                        resourse={"media_video_id"}
                    />

                    <ReferenceManyField
                        label="Города"
                        reference={`cities_media_videos`}
                        target={`media_videos_id`}
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="city_id"
                                reference="v_portal_admin_cities"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"v_portal_admin_cities"}
                        label={"Прикрепить к городу"}
                        resourse={"media_videos_id"}
                    />

                    <ReferenceManyField
                        label="Монастыри"
                        reference={`monasteries_media_videos`}
                        target={`media_videos_id`}
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="monastery_id"
                                reference="monasteries"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"monasteries"}
                        label={"Прикрепить к монастырю"}
                        resourse={"media_videos_id"}
                    />

                    <ReferenceManyField
                        label="Серафимовские проеты"
                        reference={`serafim_projects_media_videos`}
                        target={`media_videos_id`}
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="serafim_project_id"
                                reference="serafim_projects"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddReferenceButton
                        redirect_to={props.location.pathname}
                        table={"serafim_projects"}
                        label={"Прикрепить к серафимовскому проекту"}
                        resourse={"media_videos_id"}
                    />
                </Connections>
                <Location />
                <SEO />
                <Content />
            </TabbedShowLayout>
        </Show>
    );
}
