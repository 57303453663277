import React from "react";
import { Filter, ReferenceInput, SelectInput, TextInput } from "react-admin";

export default function ListFilter(props) {
    return (
        <Filter {...props}>
            <TextInput label={"Поиск"} source="q" alwaysOn />
            <ReferenceInput
                label={"Город"}
                source={"city_id"}
                reference={"v_portal_admin_cities"}
                allowEmpty
            >
                <SelectInput optionText={"title_full"} />
            </ReferenceInput>
        </Filter>
    );
}
