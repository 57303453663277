import React from "react";
import {
    SimpleForm,
    DateInput,
    TextInput,
    ReferenceInput,
    SelectInput,
    ReferenceArrayInput,
    SelectArrayInput,
    useCreateController,
    useDataProvider,
} from "react-admin";
import get from "lodash/get";
import PropTypes from "prop-types";

const MyCreate = (props) => {
    const {
        basePath,
        record,
        redirect,
        resource,
        saving,
        version,
    } = useCreateController(props);
    const dataProvider = useDataProvider();

    function save(data) {
        const allData = Object.assign({}, data);

        const dayData = data;
        delete dayData.holiday_ids;
        delete dayData.post_ids;

        dataProvider
            .create("days", {
                data: dayData,
            })
            .then((response) => {
                const day_id = get(response, "data.id");
                get(allData, "holiday_ids", []).forEach((holiday_id) => {
                    const data = { day_id, holiday_id };
                    dataProvider
                        .create("days_holidays", { data })
                        .then(() => {})
                        .catch((error) => console.error(error));
                });
                get(allData, "post_ids", []).forEach((post_id) => {
                    const data = { day_id, post_id };
                    dataProvider
                        .create("days_posts", { data })
                        .then(() => {})
                        .catch((error) => console.error(error));
                });
                document.location.reload();
            })
            .catch((error) => console.error(error));
    }

    return (
        <div>
            <h1>Создать календарь</h1>
            {React.cloneElement(props.children, {
                basePath,
                record,
                redirect,
                resource,
                save,
                saving,
                version,
            })}
        </div>
    );
};

MyCreate.propTypes = {
    children: PropTypes.node,
};

export function CreateView(props) {
    return (
        <MyCreate {...props}>
            <SimpleForm>
                <TextInput
                    source={"title"}
                    label={"Название дня"}
                    style={{ width: "100%" }}
                />
                <DateInput
                    source={"date"}
                    label={"Дата"}
                    style={{ width: "100%" }}
                />
                <TextInput
                    source={"name"}
                    label={"День недели"}
                    style={{ width: "100%" }}
                />
                <TextInput
                    source={"new_date_style"}
                    label={"Дата по новому стилю: (7 апреля)"}
                    style={{ width: "100%" }}
                />
                <TextInput
                    source={"old_date_style"}
                    label={"Дата по старому стилю: (25 марта)"}
                    style={{ width: "100%" }}
                />
                <TextInput
                    source={"glas_name"}
                    label={"Наименование гласа"}
                    style={{ width: "100%" }}
                />
                <ReferenceInput
                    source={"week_id"}
                    reference={"weeks"}
                    label={"Неделя"}
                    allowEmpty
                    perPage={1000}
                    style={{
                        width: "100%",
                    }}
                >
                    <SelectInput optionText="title" emptyValue={null} />
                </ReferenceInput>
                <ReferenceInput
                    source={"food_id"}
                    reference={"foods"}
                    label={"Еда"}
                    allowEmpty
                    perPage={1000}
                    style={{
                        width: "100%",
                    }}
                >
                    <SelectInput optionText="name" emptyValue={null} />
                </ReferenceInput>
                <ReferenceArrayInput
                    label={"Праздники"}
                    source="holiday_ids"
                    reference="holidays"
                >
                    <SelectArrayInput
                        optionText="title_full"
                        allowEmpty
                        perPage={1000}
                        style={{
                            width: "100%",
                        }}
                    />
                </ReferenceArrayInput>
                <ReferenceArrayInput
                    label={"Посты"}
                    source="post_ids"
                    reference="posts"
                >
                    <SelectArrayInput
                        optionText="title"
                        allowEmpty
                        perPage={100}
                        style={{
                            width: "100%",
                        }}
                    />
                </ReferenceArrayInput>
            </SimpleForm>
        </MyCreate>
    );
}
