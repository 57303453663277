import React from "react";
import CheckPermissions from "../../../../hoc/CheckPermissions";
import { List, Datagrid, TextField, EditButton } from "react-admin";

function UnmappedListView(props) {
    return (
        <List sort={{ field: "id", order: "DESC" }} {...props}>
            <Datagrid rowClick="show">
                <TextField source="id" label={"Id"} />
                <TextField source="name" label={"Название"} />
                <TextField source="target_key" label={"Ключ"} />
                <EditButton />
            </Datagrid>
        </List>
    );
}

const ListView = CheckPermissions(UnmappedListView, "ab_test");

export { ListView };
