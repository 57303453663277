import React, { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";
import { get } from "lodash";

export default function VoteCount({ record }) {
    const dataProvider = useDataProvider();

    const [count, setCount] = useState(0);

    useEffect(() => {
        const id = get(record, "id", null);

        dataProvider
            .getManyReference("news_ratings", {
                target: "news_id",
                id,
                pagination: { perPage: Infinity },
                sort: { field: "id", order: "desc" },
            })
            .then(({ data }) => {
                setCount(data.length);
            })
            .catch((ratingError) =>
                console.log("Vote count load error", { ratingError })
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        count && (
            <span className={"MuiTypography-root-82 MuiTypography-body2-83"}>
                {count}
            </span>
        )
    );
}
