import React from "react";
import { List, TextField, EditButton, Datagrid } from "react-admin";
import { get } from "lodash";

import AgreementActions from "../../../Form/Button/Approve";
import CheckPermissions from "../../../../hoc/CheckPermissions";

function UnmappedListView(props) {
    const { permissions, roles } = props;

    return (
        <List sort={{ field: "sort_order", order: "ASC" }} {...props}>
            <Datagrid rowClick="show">
                <TextField source="id" label={"id"} />
                <TextField source="kind" label={"Тип меню"} />
                <TextField source="title" label={"Заголовок"} />
                <TextField source="sort_order" label={"Сортировка"} />
                <TextField
                    source="mobile"
                    label={"Показывать в мобильной версии"}
                />
                <TextField
                    source="mobile_only"
                    label={"Показывать только в мобильной версии"}
                />
                <AgreementActions
                    actions={get(permissions, "actions.agreement", [])}
                    role={roles[0]}
                />
                <EditButton />
            </Datagrid>
        </List>
    );
}

const ListView = CheckPermissions(UnmappedListView, "menus");

export { ListView };
