import React from "react";
import {
    TextField,
    DateField,
    List,
    ImageField,
    EditButton,
    Datagrid,
} from "react-admin";

import CheckPermissions from "../../../../hoc/CheckPermissions";

function UnmappedListView(props) {
    return (
        <List {...props} sort={{ field: "id", order: "DESC" }}>
            <Datagrid rowClick="show">
                <TextField source="id" />
                <DateField source="issued_at" label="Дата новости" />
                <TextField source="sort_order" label={"Сортировка"} />
                <DateField source="published_at" label="Показывать после" />
                <ImageField
                    source={"main_image.src"}
                    label={"Главное изображение"}
                    className={"image-cell"}
                />
                <TextField
                    source="page_title_short"
                    width={"50%"}
                    label={"Название"}
                />
                <EditButton />
            </Datagrid>
        </List>
    )
}

const ListView = CheckPermissions(UnmappedListView, "city");

export { ListView };
