import React from "react";
import { Show, TabbedShowLayout, Tab, TextField } from "react-admin";

export function ShowView(props) {
    const PostTitle = ({ record }) => {
        return <span>Просмотр {record ? `"${record.name}"` : ""}</span>;
    };

    return (
        <Show title={<PostTitle />} {...props}>
            <TabbedShowLayout>
                <Tab label="Основное" {...props}>
                    <TextField source="name" label={"Название"} />
                    <TextField source={"target_key"} label={"Ключ"} />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
}
