import React from "react";
import {
    TabbedForm,
    Edit,
    TextInput,
    required,
    FormTab,
    NumberInput,
    usePermissions,
} from "react-admin";
import Grid from "@material-ui/core/Grid";

import InputS3Uploader from "../../../Form/InputS3Uploader";
import { SEO, Content } from "../../../Layout/Tabs/Edit";
import { tabListByRole, transformDataByRole } from "../../../../utils";
import { BottomToolbar } from "../../../Layout/Actions/Edit";

export function EditView(props) {
    const { loaded, permissions: role } = usePermissions();

    const PostTitle = ({ record }) => {
        return (
            <span>Редактируется {record ? `"${record.title_full}"` : ""}</span>
        );
    };

    const main = (
        <FormTab label={"Основное"} {...props}>
            <div style={{ width: "100%" }}>
                <Grid container>
                    <Grid
                        item
                        xs={8}
                        style={{
                            paddingRight: 24,
                            borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                        }}
                    >
                        <TextInput
                            source={"title"}
                            label={"Наименование категории аудиогидов"}
                            style={{ width: "100%" }}
                            validate={[required()]}
                            resettable
                        />
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        style={{ paddingLeft: 24, marginBottom: 24 }}
                    >
                        <NumberInput
                            source={"sort_order"}
                            label={"Сортировка"}
                            style={{ width: "100%" }}
                            validate={[required()]}
                            resettable
                        />
                        <TextInput
                            source={"slug"}
                            label={"Слаг"}
                            style={{ width: "100%" }}
                            validate={[required()]}
                            resettable
                        />
                        <InputS3Uploader
                            source={"main_image"}
                            label={"Главное изображение"}
                            validate={[required()]}
                        />
                    </Grid>
                </Grid>
            </div>
        </FormTab>
    );
    const seo = <SEO />;
    const content = <Content />;

    return (
        loaded && (
            <Edit
                title={<PostTitle />}
                transform={(data) => transformDataByRole(role, data)}
                {...props}
            >
                <TabbedForm toolbar={<BottomToolbar role={role} {...props} />}>
                    {tabListByRole({
                        seo,
                        admin: [main, seo, content],
                    }).get(role)}
                </TabbedForm>
            </Edit>
        )
    );
}
