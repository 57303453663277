import React from "react";
import {
    TabbedForm,
    Edit,
    FormTab,
    TextInput,
    required,
    SelectInput,
    usePermissions,
} from "react-admin";
import Grid from "@material-ui/core/Grid";

import { SEO, Content, Location } from "../../../Layout/Tabs/Edit";
import { EditActions, BottomToolbar } from "../../../Layout/Actions";
import InputS3Uploader from "../../../../components/Form/InputS3Uploader";
import withPermissions from "../../../../hoc/CheckPermissions";
import { tabListByRole } from "../../../../utils";

function UnmappedEditView(props) {
    const { loaded, permissions: role } = usePermissions();

    const PostTitle = ({ record }) => {
        return (
            <span>Редактируется {record ? `"${record.title_full}"` : ""}</span>
        );
    };

    const main = (
        <FormTab label={"Основное"} {...props}>
            <div style={{ width: "100%" }}>
                <Grid container>
                    <Grid
                        item
                        xs={8}
                        style={{
                            paddingRight: 24,
                            borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                        }}
                    >
                        <TextInput
                            source={"title_full"}
                            label={"Наименование"}
                            style={{ width: "100%" }}
                            validate={[required()]}
                            resettable
                        />
                        <TextInput
                            source={"page_title_full"}
                            label={"Наименование страницы"}
                            style={{ width: "100%" }}
                            validate={[required()]}
                            resettable
                        />
                        <TextInput
                            source={"page_title_short"}
                            label={"Краткое наименование страницы"}
                            style={{ width: "100%" }}
                            resettable
                        />
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        style={{
                            paddingLeft: 24,
                            marginBottom: 24,
                        }}
                    >
                        <TextInput
                            source={"slug"}
                            label={"Слаг"}
                            style={{ width: "100%" }}
                            validate={[required()]}
                            resettable
                        />
                        <SelectInput
                            source={"template"}
                            label={"Шаблон"}
                            defaultValue={"Default"}
                            style={{ width: "100%" }}
                            choices={[
                                {
                                    id: "Default",
                                    name: "По умолчанию",
                                },
                                {
                                    id: "SerafimProject",
                                    name: "Серафимовские проекты",
                                },
                                {
                                    id: "Accordion",
                                    name: "Аккордеон",
                                },
                                {
                                    id: "Trebs",
                                    name: "Требы",
                                },
                                {
                                    id: "Donate",
                                    name: "Пожертвования",
                                },
                            ]}
                        />
                        <InputS3Uploader
                            source={"main_image"}
                            label={"Главное изображение"}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{
                            borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                        }}
                    />
                </Grid>
            </div>
        </FormTab>
    );
    const location = <Location />;
    const seo = <SEO />;
    const content = <Content />;

    return (
        loaded && (
            <Edit
                title={<PostTitle />}
                actions={<EditActions />}
                {...props}
            >
                <TabbedForm toolbar={<BottomToolbar role={role} {...props} />}>
                    {tabListByRole({
                        seo: [main, location, seo, content],
                        admin: [main, location, seo, content],
                    }).get(role)}
                </TabbedForm>
            </Edit>
        )
    );
}

const EditView = withPermissions(UnmappedEditView, "text_pages");

export { EditView };
