import React from "react";
import { Show, Tab, TabbedShowLayout, TextField } from "react-admin";
import { SEO, Content } from "../../../Layout/Tabs/Show";

export function ShowView(props) {
    const PostTitle = ({ record }) => {
        return <span>Просмотр {record ? `"${record.title}"` : ""}</span>;
    };

    return (
        <Show title={<PostTitle />} {...props}>
            <TabbedShowLayout>
                <Tab label="Основное" {...props}>
                    <TextField source="title" label={"Заголовок"} />
                    <TextField
                        source={"description"}
                        label={"Описание"}
                        style={{ width: "100%" }}
                    />
                </Tab>
                <SEO />
                <Content />
            </TabbedShowLayout>
        </Show>
    );
}
