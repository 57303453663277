import React from "react";

import {
    List,
    Datagrid,
    TextField,
    EditButton,
    usePermissions,
} from "react-admin";
import { get } from "lodash";

import CheckPermissions from "../../../../hoc/CheckPermissions";
import AgreementActions from "../../../Form/Button/Approve";

function UnmappedListView(props) {
    const { loaded, permissions: role } = usePermissions();

    const { permissions, roles } = props;

    return (
        loaded && (
            <List sort={{ field: "id", order: "DESC" }} {...props}>
                <Datagrid
                    rowClick="show"
                    isRowSelectable={() => role === "admin"}
                >
                    <TextField source="id" label={"Id"} />
                    <TextField source="slug" label={"Slug"} />
                    <TextField source="title" label={"Название"} />
                    <AgreementActions
                        actions={get(permissions, "actions.agreement", [])}
                        role={roles[0]}
                    />
                    <EditButton />
                </Datagrid>
            </List>
        )
    );
}

const ListView = CheckPermissions(UnmappedListView, "city");

export { ListView };
