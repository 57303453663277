import React from "react";
import { Show, TextField, TabbedShowLayout } from "react-admin";

import { SEO, Content, Main } from "../../../Layout/Tabs/Show";

export function ShowView(props) {
    const PostTitle = ({ record }) => {
        return <span>Просмотр {record ? `"${record.page_title_full}"` : ""}</span>;
    };

    return (
        <Show title={<PostTitle />} {...props}>
            <TabbedShowLayout>
                <Main>
                    <TextField source="page_title_full" />
                </Main>
                <Content />
                <SEO />
            </TabbedShowLayout>
        </Show>
    );
}
