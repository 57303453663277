import React from "react";
import {
    TabbedForm,
    Edit,
    FormTab,
    TextInput,
    required,
    usePermissions,
} from "react-admin";
import Grid from "@material-ui/core/Grid";

import { SEO } from "../../../Layout/Tabs/Edit";
import { EditActions, BottomToolbar } from "../../../Layout/Actions";
import withPermissions from "../../../../hoc/CheckPermissions";
import { tabListByRole, transformDataByRole } from "../../../../utils";

function UnmappedEditView(props) {
    const { loaded, permissions: role } = usePermissions();

    const PostTitle = ({ record }) => {
        return <span>Редактируется {record ? `"${record.path}"` : ""}</span>;
    };

    const main = (
        <FormTab label={"Основное"} {...props}>
            <div style={{ width: "100%" }}>
                <Grid container>
                    <Grid
                        item
                        xs={8}
                        style={{
                            paddingRight: 24,
                            borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                        }}
                    >
                        <TextInput
                            source={"path"}
                            label={"Слаг страницы"}
                            style={{ width: "100%" }}
                            validate={[required()]}
                            resettable
                        />
                    </Grid>
                </Grid>
            </div>
        </FormTab>
    );
    const seo = <SEO />;

    return (
        loaded && (
            <Edit
                title={<PostTitle />}
                actions={<EditActions />}
                transform={(data) => transformDataByRole(role, data)}
                {...props}
            >
                <TabbedForm toolbar={<BottomToolbar role={role} {...props} />}>
                    {tabListByRole({
                        seo,
                        admin: [main, seo],
                    }).get(role)}
                </TabbedForm>
            </Edit>
        )
    );
}

const EditView = withPermissions(UnmappedEditView, "page_settings");

export { EditView };
