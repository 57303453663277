import React from "react";
import {
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
    DateField,
    ArrayField,
    FunctionField,
    Datagrid,
    ImageField,
} from "react-admin";
import {
    ReferenceManyField,
    SingleFieldList,
    ReferenceField,
    ChipField,
    Button,
} from "react-admin";
import { get } from "lodash";
import * as Showdown from "showdown";
import { Link } from "react-router-dom";

import AddIcon from "@material-ui/icons/Add";

import { SEO } from "../../../Layout/Tabs/Show";

const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
});

export function ShowView(props) {
    const PostTitle = ({ record }) => {
        return (
            <span>
                Просмотр {record ? `"${get(record, "data.title", "")}"` : ""}
            </span>
        );
    };

    const AddButton = ({ redirect_to, record, table, label }) => (
        <Button
            variant="raised"
            component={Link}
            to={{
                pathname: `/${table}/create`,
                state: {
                    record: { crowdfunding_project_id: record.id },
                    redirect_to,
                },
            }}
            label={label}
            title={label}
        >
            <AddIcon />
        </Button>
    );

    const AddCrowdfundingProjectButton = AddButton;

    return (
        <Show title={<PostTitle />} {...props}>
            <TabbedShowLayout>
                <Tab label="Основное" {...props}>
                    <TextField
                        source={"kind"}
                        label={"Тип"}
                        style={{ width: "100%" }}
                    />
                    <TextField
                        source={"payment_receiver_id"}
                        label={"Получатель"}
                        style={{ width: "100%" }}
                    />
                    <TextField
                        source={"slug"}
                        label={"Слаг"}
                        style={{ width: "100%" }}
                    />
                    <TextField source="data.title" label={"Наименование"} />
                    <ImageField
                        source="main_image.src"
                        label="Главное изображение"
                    />
                    <TextField
                        source="data.description"
                        label={"Краткое описание"}
                    />
                    <TextField source="data.place" label={"Расположение"} />
                    <TextField source="data.tags" label={"Категории"} />
                    <DateField source="started_at" label={"Дата начала"} />
                    <DateField source="ended_at" label={"Дата завершения"} />
                    <TextField
                        source="data.required_amount"
                        label={"Необходимая сумма сбора"}
                    />
                    <TextField
                        source={"data.visible_in_site"}
                        label={"Видимость в общем списке"}
                    />
                    {props.children}
                </Tab>
                <Tab label="Связи">
                    <ReferenceManyField
                        label="Города"
                        reference="cities_crowdfunding_projects"
                        target="crowdfunding_project_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="city_id"
                                reference="v_portal_admin_cities"
                                link={false}
                            >
                                <ChipField source="title_full" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddCrowdfundingProjectButton
                        redirect_to={props.location.pathname}
                        table={"cities_crowdfunding_projects"}
                        label={"Прикрепить к городу"}
                    />
                    <ReferenceManyField
                        label="Монастыри"
                        reference="crowdfunding_projects_monasteries"
                        target="crowdfunding_project_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="monastery_id"
                                reference="monasteries"
                                link={false}
                            >
                                <ChipField source="title_short" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddCrowdfundingProjectButton
                        redirect_to={props.location.pathname}
                        table={"crowdfunding_projects_monasteries"}
                        label={"Прикрепить к монастырю"}
                    />
                    <ReferenceManyField
                        label="Соборы"
                        reference="cathedrals_crowdfunding_projects"
                        target="crowdfunding_project_id"
                        sort={{ field: "id", order: "ASC" }}
                    >
                        <SingleFieldList>
                            <ReferenceField
                                source="cathedral_id"
                                reference="cathedrals"
                                link={false}
                            >
                                <ChipField source="title_short" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <AddCrowdfundingProjectButton
                        redirect_to={props.location.pathname}
                        table={"cathedrals_crowdfunding_projects"}
                        label={"Прикрепить к собору"}
                    />
                </Tab>
                <SEO />
                <Tab label="Контент" {...props}>
                    <ArrayField
                        source="data.content_blocks"
                        label={"Блоки контента"}
                        style={{ width: "100%" }}
                    >
                        <Datagrid>
                            <FunctionField
                                label="Содержимое"
                                render={(record) => (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: converter.makeHtml(
                                                record.body
                                            ),
                                        }}
                                        style={{ marginBottom: "1rem" }}
                                    />
                                )}
                            />
                            <ArrayField source="media.items" label={"Медиа"}>
                                <Datagrid>
                                    <ImageField
                                        source="src"
                                        title="alt"
                                        label={"Изображения"}
                                    />
                                </Datagrid>
                            </ArrayField>
                        </Datagrid>
                    </ArrayField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
}
