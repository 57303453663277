import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import Highlighter from "react-highlight-words";
import Button from "@material-ui/core/Button";
import { IconButton } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";

export function ModalBody({ toggle }) {
    const [body, setBody] = useState("");
    const { enqueueSnackbar } = useSnackbar();
    const [fetchSize, setFetchSize] = useState(0);

    function downloadLogs() {
        const request = new Request(
            `${process.env.REACT_APP_BASE_API}/rebuilds/log`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    range: `bytes=${fetchSize}-`,
                    "Access-Control-Expose-Headers": "Content-Length",
                },
            }
        );

        fetch(request)
            .then((response) => {
                setFetchSize(
                    fetchSize + parseInt(response.headers.get("content-length"))
                );
                return response;
            })
            .then((response) => response.text())
            .then((json) => setBody((prevBody) => prevBody + json))
            .catch((error) => {
                enqueueSnackbar("Возникла ошибка при загрузке логов!");
                console.error("logs error", { error });
            });
    }

    useEffect(() => {
        downloadLogs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={"log-modal__body"}>
            <IconButton
                aria-label="delete"
                color="black"
                onClick={() => toggle(false)}
                style={{
                    position: "absolute",
                    right: "63px",
                }}
            >
                <CancelIcon />
            </IconButton>
            <h2
                id="simple-modal-title"
                style={{
                    width: "max-content",
                    backgroundColor: "yellow",
                }}
            >
                Errors:
                {body.match(/error/gi) !== null
                    ? body.match(/error/gi).length
                    : 0}
            </h2>
            <pre id="simple-modal-description">
                <Highlighter searchWords={["error"]} textToHighlight={body} />
            </pre>
            <div style={{ textAlign: "right" }}>
                <Button
                    onClick={() => downloadLogs()}
                    color="secondary"
                    className={"log-modal__body-button"}
                >
                    Ещё
                </Button>
            </div>
        </div>
    );
}

ModalBody.propTypes = {
    toggle: PropTypes.func,
};

ModalBody.defaultProps = {
    toggle: () => Function,
};
