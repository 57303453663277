import React from "react";
import { TextField, Tab, ImageField, DateField } from "react-admin";

export function Main(props) {
    return (
        <Tab label="Основное" {...props}>
            {typeof props.record?.published_at !== "undefined" && (<DateField
                source={"published_at"}
                label={"Опубликовано"}
            />)}
            <TextField
                source={"slug"}
                label={"Слаг"}
                style={{ width: "100%" }}
            />
            <ImageField source="main_image.src" label="Главное изображение" />
            <ImageField
                source="main_image_preview.src"
                label="Превью изображение"
            />
            <TextField source="title_full" label={"Наименование"} />
            <TextField source="title_short" label={"Краткое наименование"} />
            <TextField
                source="page_title_full"
                label={"Наименование страницы"}
            />
            <TextField
                source="page_title_short"
                label={"Краткое наименование страницы"}
            />
            {props.children}
        </Tab>
    );
}
