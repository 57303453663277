import React, { Component } from "react";

class InputDraft extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: "",
            tab: "write"
        };
    }

    render() {
        const { source, record } = this.props;
        const data = record[source];
        /* const image = get(data, "data.main_image", false); */
        // const comment = get(data, "comment", false);
        /* const content_blocks = get(data, "data.content_blocks", []); */

        return (
            <div style={{ marginTop: 24 }}>
                {/* <div style={{ marginBottom: 24 }}>
                    {image && (
                        <img src={image.src} alt="" style={{ maxWidth: 400 }} />
                    )}
                </div>
                <div>
                    {content_blocks.map(({ body, title, media }, idx) => {
                        return (
                            <div key={`content-blocks-item-${idx}`}>
                                <div>{title}</div>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: converter.makeHtml(body)
                                    }}
                                />
                                {get(media, "items", []).map(({ src }, idx) => (
                                    <img
                                        key={`media-item-${idx}`}
                                        src={src}
                                        alt={title}
                                        style={{ maxWidth: 400 }}
                                    />
                                ))}
                            </div>
                        );
                    })}
                </div> */}
                <div>
                    <h4>Комментарии:</h4>
                    <p>
                        {data.map(
                            ({ comment }) =>
                                comment && (
                                    <div
                                        style={{
                                            marginBottom: 24,
                                            borderBottom: "1px solid"
                                        }}
                                    >
                                        {comment}
                                    </div>
                                )
                        )}
                    </p>
                </div>
            </div>
        );
    }
}

export default InputDraft;
