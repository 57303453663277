import React from "react";
import {
    TabbedForm,
    Create,
    ReferenceInput,
    SelectInput,
    required,
} from "react-admin";

import {
    SEO,
    Content,
    Main,
    Location,
    BuildingAttributes,
} from "../../../Layout/Tabs/Edit";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";

import { TextInput } from "react-admin";
import InputS3Uploader from "../../../Form/InputS3Uploader";
import RichText from "../../../Form/RichText";

export const appeal_to_visitors = (
    <>
        <TextInput
            source={"appeal_to_visitors.title"}
            label="Заголовок блока"
            style={{ width: "100%" }}
        />
        <RichText
            source={"appeal_to_visitors.body"}
            label={"Содержимое блока"}
            style={{ width: "100%" }}
        />
        <Grid item xs={4}>
            <InputS3Uploader
                source={"appeal_to_visitors.image.src"}
                style={{ width: "100%" }}
            />
            <TextInput
                source={"appeal_to_visitors.image.alt"}
                label={"Альтернативный текст"}
            />
        </Grid>
    </>
);

export function CreateView(props) {
    return (
        <Create {...props}>
            <TabbedForm>
                <Main ShowThreeDModelField={true} showPublishedAtField={true}>
                    <TextInput
                        source={"address"}
                        label={"Адрес"}
                        style={{ width: "100%" }}
                        resettable
                    />
                    <TextInput
                        source={"telephone"}
                        label={"Телефон"}
                        style={{ width: "100%" }}
                        resettable
                    />
                    <TextInput
                        source={"openning_hours"}
                        label={"Часы работы"}
                        style={{ width: "50%" }}
                        resettable
                    />
                    <Tooltip
                        disableFocusListener
                        placement={"top"}
                        title="Дни указываются с использованием следующих двухбуквенных
                        комбинаций: Mo, Tu, We, Th, Fr, Sa, Su. Время указывается с
                        помощью 24:00 часового фромата. Пример: Tu,Th
                        16:00-20:00. Если строение открыто 7 дней в неделю, то
                        Часы работы может быть определено как: Mo-Su"
                    >
                        <Button>?</Button>
                    </Tooltip>
                    <Grid xs={6}>
                        <ReferenceInput
                            source={"region_id"}
                            reference={"regions"}
                            label={"Регион"}
                            validate={[required()]}
                            style={{
                                width: "100%",
                            }}
                        >
                            <SelectInput optionText="title_full" />
                        </ReferenceInput>
                        <ReferenceInput
                            source={"city_id"}
                            reference={"v_portal_admin_cities"}
                            allowEmpty
                            label={"Город"}
                            style={{
                                width: "100%",
                            }}
                        >
                            <SelectInput
                                optionText="title_full"
                                emptyValue={null}
                            />
                        </ReferenceInput>
                    </Grid>
                </Main>
                <Location />
                <SEO />
                <Content before={appeal_to_visitors} />
                <BuildingAttributes />
            </TabbedForm>
        </Create>
    );
}
