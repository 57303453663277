import React, { useState, useEffect } from "react";
import { addField } from "ra-core";
import TextField from "@material-ui/core/TextField";
import InstagramEmbed from "react-instagram-embed";

function InstagramPostInput({ input: { onChange, value } }) {
    const [url, setUrl] = useState(value);

    function handleChange(event) {
        setUrl(event.target.value);
    }

    useEffect(() => {
        onChange(url);
    }, [onChange, url]);

    return (
        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
            <div style={{ width: "100%", display: "flex" }}>
                <div style={{ flex: "1" }}>
                    <TextField
                        id="filled-basic"
                        label="Ссылка на пост из Instagram"
                        variant="filled"
                        name="Instagram"
                        onChange={handleChange}
                        style={{ width: "96%" }}
                        value={value}
                    />
                </div>
                <div style={{ flex: "1" }}>
                    {url && (
                        <InstagramEmbed
                            url={url}
                            clientAccessToken={process.env.REACT_APP_INSTAGRAM_API_KEY}
                            maxWidth={600}
                            hideCaption={false}
                            containerTagName="div"
                            protocol=""
                            injectScript
                            onLoading={() => {}}
                            onSuccess={() => {}}
                            onAfterRender={() => {}}
                            onFailure={() => {}}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default addField(InstagramPostInput);
