import React from "react";
import {
    TabbedForm,
    Create,
    TextInput,
    required,
    NumberInput,
    FormTab,
    ReferenceInput,
    SelectInput,
} from "react-admin";
import Grid from "@material-ui/core/Grid";

import { SEO, Location, Content } from "../../../Layout/Tabs/Edit";
import InputS3Uploader from "../../../Form/InputS3Uploader";

export function CreateView(props) {
    return (
        <Create {...props}>
            <TabbedForm>
                <FormTab label={"Основное"} {...props}>
                    <div style={{ width: "100%" }}>
                        <Grid container>
                            <Grid
                                item
                                xs={8}
                                style={{
                                    paddingRight: 24,
                                    borderRight:
                                        "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                            >
                                <TextInput
                                    source={"title_full"}
                                    label={"Наименование"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <TextInput
                                    source={"title_short"}
                                    label={"Краткое наименование"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <TextInput
                                    source={"page_title_full"}
                                    label={"Наименование страницы"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <TextInput
                                    source={"page_title_short"}
                                    label={"Краткое наименование страницы"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                style={{ paddingLeft: 24, marginBottom: 24 }}
                            >
                                <TextInput
                                    source={"slug"}
                                    label={"Слаг"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <NumberInput
                                    source={"sort_order"}
                                    label={"Сортировка"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <InputS3Uploader
                                    source={"main_image"}
                                    label={"Главное изображение"}
                                    validate={[required()]}
                                />
                                <InputS3Uploader
                                    source={"main_image_mobile"}
                                    label={
                                        "Главное изображение для мобильной версии"
                                    }
                                />
                                <InputS3Uploader
                                    source={"main_image_preview"}
                                    label={"Превью изображение"}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                            >
                                <Grid xs={2}>
                                    <ReferenceInput
                                        source={"region_id"}
                                        reference={"regions"}
                                        label={"Регион"}
                                        validate={[required()]}
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        <SelectInput optionText="title_full" />
                                    </ReferenceInput>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                            >
                                {props.children}
                            </Grid>
                        </Grid>
                    </div>
                </FormTab>
                <FormTab label={"Настройки"} {...props}>
                    <div style={{ width: "100%" }}>
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    paddingRight: 24,
                                    borderRight:
                                        "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                            >
                                <TextInput
                                    source={"settings.params.src"}
                                    label={"Источник"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <TextInput
                                    source={"settings.params.base_path"}
                                    label={"Основной урл"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <TextInput
                                    source={"settings.params.init_hotspot"}
                                    label={"Начальное состояние"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <TextInput
                                    source={"settings.component"}
                                    label={"Компонент"}
                                    style={{ width: "100%" }}
                                    defaultValue={"TourBlock"}
                                    resettable
                                />
                            </Grid>
                        </Grid>
                    </div>
                </FormTab>
                <Location />
                <SEO />
                <Content />
            </TabbedForm>
        </Create>
    );
}
