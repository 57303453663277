import React from "react";
import { Show, TextField } from "react-admin";

export function ShowView(props) {
    const PostTitle = ({ record }) => {
        return <span>Просмотр {record ? `"${record.name}"` : ""}</span>;
    };

    return (
        <Show title={<PostTitle />} {...props}>
            <TextField source="text" label={"Слово"} />
        </Show>
    );
}
