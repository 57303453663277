import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import cyrillicToTranslit from "cyrillic-to-translit-js";
import { TextInput, FormTab, NumberInput, DateInput, required } from "react-admin";
import PropTypes from "prop-types";
import { get } from "lodash";

import InputS3Uploader from "../../../../Form/InputS3Uploader";
import InputTranslit from "../../../../Form/InputTranslit";

export function Main(props) {
    const [slug, setSlug] = useState("");

    function translit(string = "") {
        return cyrillicToTranslit({ preset: "ru" }).transform(
            string.toLowerCase(),
            "-"
        );
    }

    const { edit: { main } = { main: true }, edit } = get(
        props,
        "permissions",
        {}
    );

    const showMain = (typeof edit === "boolean" && edit) || main;

    return showMain ? (
        <FormTab label={"Основное"} {...props}>
            <div style={{ width: "100%" }}>
                <Grid container>
                    <Grid
                        item
                        xs={8}
                        style={{
                            paddingRight: 24,
                            borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                        }}
                    >
                        <TextInput
                            source={"title_full"}
                            label={"Наименование"}
                            style={{ width: "100%" }}
                            validate={[required()]}
                            resettable
                            onChange={(event) =>
                                setSlug(translit(event.currentTarget.value))
                            }
                        />
                        <TextInput
                            source={"title_short"}
                            label={"Краткое наименование"}
                            style={{ width: "100%" }}
                            resettable
                        />
                        <TextInput
                            source={"page_title_full"}
                            label={"Наименование страницы"}
                            style={{ width: "100%" }}
                            validate={[required()]}
                            resettable
                        />
                        <TextInput
                            source={"page_title_short"}
                            label={"Краткое наименование страницы"}
                            style={{ width: "100%" }}
                            resettable
                        />
                        {props.children}
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        style={{ paddingLeft: 24, marginBottom: 24 }}
                    >
                        {props.showPublishedAtField && (
                            <DateInput
                                label={"Опубликовано"}
                                source={"published_at"}
                            />
                        )}
                        <InputTranslit
                            label={"Слаг"}
                            source={"slug"}
                            validate={[required()]}
                            resettable
                            translitValue={slug}
                        />
                        {/* <TextInput
                            source={"slug"}
                            label={"Слаг"}
                            style={{ width: "100%" }}
                            validate={[required()]}
                            resettable
                        /> */}
                        {props.showSortOrder && (
                            <NumberInput
                                source={"sort_order"}
                                label={"Сортировка"}
                                style={{ width: "100%" }}
                                validate={[required()]}
                                resettable
                            />
                        )}
                        <InputS3Uploader
                            source={"main_image"}
                            label={"Главное изображение"}
                            validate={[required()]}
                            options={
                                {
                                    // TODO: generate category path s3path: `uploads-test/${category}`,
                                }
                            }
                        />
                        <InputS3Uploader
                            source={"main_image_mobile"}
                            label={"Главное изображение для мобильной версии"}
                        />
                        <InputS3Uploader
                            source={"main_image_preview"}
                            label={"Превью изображение"}
                            options={
                                {
                                    // TODO: generate category path s3path: `uploads-test/${category}`,
                                }
                            }
                        />
                        {props.ShowThreeDModelField && (
                            <InputS3Uploader
                                source={"settings.3dModel"}
                                label={"3D модель"}
                                imgStyle={{
                                    width: "30%",
                                    margin: "0 auto",
                                }}
                            />
                        )}
                    </Grid>
                    {/*<Grid item xs={12} style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}>*/}
                    {/*	{props.children}*/}
                    {/*</Grid>*/}
                </Grid>
            </div>
        </FormTab>
    ) : null;
}

Main.propTypes = {
    showSortOrder: PropTypes.bool,
    ShowThreeDModelField: PropTypes.bool,
    showPublishedAtField: PropTypes.bool,
};

Main.defaultProps = {
    showSortOrder: true,
    ShowThreeDModelField: false,
    showPublishedAtField: false,
};
