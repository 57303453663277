import React from "react";
import { addField } from "ra-core";

function InputLocationPoint(props) {
    const { input, point } = props;
    input.onChange(point);

    return (
        <>
            <input
                value={point || input.value}
                onChange={(value) => {
                    input.onChange(value);
                }}
                style={{ display: "none" }}
            />
        </>
    );
}

export default addField(InputLocationPoint);
