import React from "react";
import {
    TabbedForm,
    Create,
    TextInput,
    FormTab,
    NumberInput,
    ReferenceInput,
    SelectInput,
    required,
    ArrayInput,
    SimpleFormIterator,
} from "react-admin";
import Grid from "@material-ui/core/Grid";

import { SEO, Content, Location } from "../../../Layout/Tabs/Edit";
import InputS3Uploader from "../../../../components/Form/InputS3Uploader";

export function CreateView(props) {
    const transform = (data) => ({
        ...data,
        settings: {
            project: "portal",
        },
    });

    return (
        <Create {...props} transform={transform}>
            <TabbedForm>
                <FormTab label={"Основное"} {...props}>
                    <div style={{ width: "100%" }}>
                        <Grid container>
                            <Grid
                                item
                                xs={8}
                                style={{
                                    paddingRight: 24,
                                    borderRight:
                                        "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                            >
                                <TextInput
                                    source={"title_full"}
                                    label={"Наименование"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <TextInput
                                    source={"page_title_full"}
                                    label={"Наименование страницы"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <TextInput
                                    source={"page_title_short"}
                                    label={"Краткое наименование страницы"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                style={{ paddingLeft: 24, marginBottom: 24 }}
                            >
                                <TextInput
                                    source={"slug"}
                                    label={"Слаг"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <NumberInput
                                    source={"sort_order"}
                                    label={"Сортировка"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <InputS3Uploader
                                    source={"main_image"}
                                    label={"Главное изображение"}
                                    validate={[required()]}
                                    options={
                                        {
                                            // TODO: generate category path s3path: `uploads-test/${category}`,
                                        }
                                    }
                                />
                                <InputS3Uploader
                                    source={"main_image_mobile"}
                                    label={
                                        "Главное изображение для мобильной версии"
                                    }
                                />
                                <InputS3Uploader
                                    source={"main_image_preview"}
                                    label={"Превью изображение"}
                                    options={
                                        {
                                            // TODO: generate category path s3path: `uploads-test/${category}`,
                                        }
                                    }
                                />
                                <InputS3Uploader
                                    source={"gerb_image"}
                                    label={"Герб города"}
                                    options={
                                        {
                                            // TODO: generate category path s3path: `uploads-test/${category}`,
                                        }
                                    }
                                />
                                <InputS3Uploader
                                    source={"settings.3dModel"}
                                    label={"3D модель"}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                            >
                                <Grid xs={2}>
                                    <ReferenceInput
                                        source={"region_id"}
                                        reference={"regions"}
                                        label={"Регион"}
                                        validate={[required()]}
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        <SelectInput optionText="title_full" />
                                    </ReferenceInput>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </FormTab>
                <Location />
                <SEO />
                <Content />
                <FormTab label={"Атрибуты"} {...props}>
                    <div style={{ width: "100%" }}>
                        <Grid container>
                            <Grid
                                item
                                xs={8}
                                style={{
                                    paddingRight: 24,
                                    borderRight:
                                        "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                            >
                                <TextInput
                                    source={"founded_date"}
                                    label={"Дата основания"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <NumberInput
                                    source={"first_mention"}
                                    label={"Первое упоминание"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <ArrayInput
                                    source="prev_names"
                                    label="Прежние названия"
                                >
                                    <SimpleFormIterator>
                                        <TextInput
                                            source={"name"}
                                            label={"Заголовок"}
                                            style={{ width: "100%" }}
                                        />
                                    </SimpleFormIterator>
                                </ArrayInput>
                                <NumberInput
                                    source={"area"}
                                    label={"Площадь"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <NumberInput
                                    source={"population"}
                                    label={"Население"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                            </Grid>
                        </Grid>
                    </div>
                </FormTab>
            </TabbedForm>
        </Create>
    );
}
