import React from "react";
import { TextField, ReferenceField } from "react-admin";
import { Create, ReferenceInput, TabbedShowLayout, Tab } from "react-admin";
import { Show, Edit } from "react-admin";

import { SimpleForm, required } from "react-admin";

import { get } from "lodash";

import AutocompleteField from "../../../Form/Autocomplete";

export const CreateView = (props) => {
    const redirect = get(props, "location.state.redirect_to", "") || false;

    return (
        <Create {...props}>
            <SimpleForm redirect={redirect}>
                <ReferenceInput
                    label={"Категория"}
                    source="media_video_category_id"
                    reference="media_video_categories"
                    validate={required()}
                >
                    <AutocompleteField title="Категория" />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};

export const EditView = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <ReferenceInput
                label={"Категория"}
                source="media_video_category_id"
                reference="media_video_categories"
                validate={required()}
            >
                <AutocompleteField title="Категория" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

export const ShowView = (props) => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label={"Основное"} {...props}>
                <ReferenceField
                    label="ТВ образ"
                    source="media_video_category_id"
                    reference="media_video_categories"
                >
                    <TextField source="title_full" />
                </ReferenceField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
