import React from "react";
import { TabbedForm, Create } from "react-admin";

import { SEO, Content, Main, Location } from "../../../Layout/Tabs/Edit";

export function CreateView(props) {
	return (
		<Create {...props}>
			<TabbedForm>
				<Main />
				<Location />
				<SEO />
				<Content />
			</TabbedForm>
		</Create>
	);
}
