import React from "react";
import { Layout } from "react-admin";
import AppBar from "../AppBar";
import {SnackbarProvider} from "notistack";

export default function CustomLayout(props) {
    return (
        <SnackbarProvider>
            <Layout {...props} appBar={AppBar} />
        </SnackbarProvider>
    );
}
