import React from "react";
import {
    TabbedForm,
    Create,
    FormTab,
    TextInput,
    required,
    NumberInput,
    SelectInput,
    ReferenceInput,
} from "react-admin";
import Grid from "@material-ui/core/Grid";

import { SEO, Content } from "../../../Layout/Tabs/Edit";
import InputS3Uploader from "../../../../components/Form/InputS3Uploader";

export function CreateView(props) {
    return (
        <Create {...props}>
            <TabbedForm>
                <FormTab label={"Основное"} {...props}>
                    <div style={{ width: "100%" }}>
                        <Grid container>
                            <Grid
                                item
                                xs={8}
                                style={{ paddingLeft: 24, marginBottom: 24 }}
                            >
                                <TextInput
                                    source={"title_full"}
                                    label={"Наименование"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <TextInput
                                    source={"description"}
                                    label={"Краткое описание"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                    multiline
                                />
                                <SelectInput
                                    source={"kind"}
                                    label={"Тип источника"}
                                    defaultValue={"Link"}
                                    style={{ width: "100%" }}
                                    choices={[
                                        {
                                            id: "KindLink",
                                            name: "Cсылка на источник",
                                        },
                                        {
                                            id: "KindIframe",
                                            name: "Ссылка на iframe",
                                        },
                                    ]}
                                />
                                <TextInput
                                    source={"source"}
                                    label={"Источник"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                    multiline
                                />
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                style={{ paddingLeft: 24, marginBottom: 24 }}
                            >
                                <TextInput
                                    source={"slug"}
                                    label={"Слаг"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <NumberInput
                                    source={"sort_order"}
                                    label={"Сортировка"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <InputS3Uploader
                                    source={"main_image"}
                                    label={"Главное изображение"}
                                    validate={[required()]}
                                    options={
                                        {
                                            // TODO: generate category path s3path: `uploads-test/${category}`,
                                        }
                                    }
                                />
                                <InputS3Uploader
                                    source={"main_image_preview"}
                                    label={"Превью изображение"}
                                    options={
                                        {
                                            // TODO: generate category path s3path: `uploads-test/${category}`,
                                        }
                                    }
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                            />
                        </Grid>
                    </div>
                </FormTab>
                <SEO />
                <Content />
                <FormTab label={"Атрибуты"} {...props}>
                    <div style={{ width: "100%" }}>
                        <Grid container>
                            <Grid
                                item
                                xs={8}
                                style={{
                                    paddingRight: 24,
                                    borderRight:
                                        "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                            >
                                <ReferenceInput
                                    source={"cities_id"}
                                    reference={"v_portal_admin_cities"}
                                    label={"Город"}
                                    allowEmpty
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <SelectInput
                                        optionText="title_full"
                                        emptyValue={null}
                                    />
                                </ReferenceInput>
                                <TextInput
                                    source={"slogan"}
                                    label={"Слоган"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <NumberInput
                                    source={"frequency"}
                                    label={"Частота"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <ReferenceInput
                                    source={"regions_id"}
                                    reference={"regions"}
                                    label={"Зона вещания"}
                                    allowEmpty
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <SelectInput
                                        optionText="title_full"
                                        emptyValue={null}
                                    />
                                </ReferenceInput>
                                <TextInput
                                    source={"broadcast_start"}
                                    label={"Дата начала вещания"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                                <SelectInput
                                    source={"broadcast_period"}
                                    label={"Время вещания"}
                                    style={{ width: "100%" }}
                                    allowEmpty
                                    emptyValue={null}
                                    choices={[
                                        {
                                            id: "dayandnight",
                                            name: "Круглосуточно",
                                        },
                                        {
                                            id: "scedule",
                                            name: "По расписанию",
                                        },
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </FormTab>
            </TabbedForm>
        </Create>
    );
}
