import React from "react";
import { TabbedForm, Edit, TextInput, required, FormTab } from "react-admin";
import Grid from "@material-ui/core/Grid";

export function EditView(props) {
    const PostTitle = ({ record }) => {
        return <span>Редактируется {record ? `"${record.name}"` : ""}</span>;
    };

    return (
        <Edit title={<PostTitle />} {...props}>
            <TabbedForm>
                <FormTab label={"Основное"} {...props}>
                    <div style={{ width: "100%" }}>
                        <Grid container>
                            <Grid
                                item
                                xs={8}
                                style={{
                                    paddingRight: 24,
                                    borderRight:
                                        "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                            >
                                <TextInput
                                    source={"name"}
                                    label={"Имя"}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                    resettable
                                />
                                <TextInput
                                    source={"slug"}
                                    label={"Слаг"}
                                    style={{ width: "100%" }}
                                    resettable
                                />
                            </Grid>
                        </Grid>
                    </div>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
}
