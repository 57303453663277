import React from "react";

import { TextInput, FormTab } from "react-admin";

export function SEO(props) {

	return (
		<FormTab label={"seo"} {...props}>
			<TextInput source='seo.html.title' label='html_title' style={{ width: "100%" }}/>
			<TextInput source='seo.html.description' label='html_description' style={{ width: "100%" }}/>
			<TextInput source='seo.html.viewport' label='html_viewport' style={{ width: "100%" }}/>
			<TextInput source='seo.html.keywords' label='html_keywords' style={{ width: "100%" }}/>
			<TextInput source='seo.html.noindex' label='html_noindex' style={{ width: "100%" }}/>
			<TextInput source='seo.og.title' label='og_title' style={{ width: "100%" }}/>
			<TextInput source='seo.og.site_name' label='og_site_name' style={{ width: "100%" }}/>
			<TextInput source='seo.og.description' label='og_description' style={{ width: "100%" }}/>
			<TextInput source='seo.og.image' label='og_image' style={{ width: "100%" }}/>
			{props.children}
		</FormTab>
	)
}
